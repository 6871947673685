import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withSnackbar } from 'notistack';
import { Bars } from 'react-loader-spinner';
import { List, Toolbar, ListItem } from '@mui/material';

import { style } from './style';
import { DialogBoxes } from './DialogBoxes';
import { hasPermission } from '../../constants/ability';
import { ProjectDetailView } from './ProjectDetailView';
import { ActionsCreator } from '../../Redux/Actions/index';
import { EmptyContainer } from '../Utility/EmptyContainer';
import { getLuminance } from '../../Selectors';
import * as ReactGA from 'react-ga';
import { getResourceRatesForProjects } from '../../Selectors/index';
import { RatesTableView } from '../Utility/RatesTableView';
import { default_role_type } from '../../constants/utility';
const PROJECT_ERRORS = [
  'Project with this name already exists',
  'Color is too bright',
  'Invalid Hexcode (correct format e.g. #ff00ee, #00AF23) ',
];

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      show_project: '',
      project_name: '',
      project_status: '',
      project_manager: '',
      project_color: '',
      project_errors: [],
      show_add_project: false,
      project_association: '',
      selected_project_status: '',
      selected_project_manager: '',
      selected_project_association: '',
      selected_project_receiver: '',
      show_add_associate_receiver: false,
      AssociatedReceivers: [],
      show_percentage_error: false,
      consider_prop: true,
      show_confirmation_popup: false,
      show_set_rate_view: false,
      show_delete_popup: false,
      logged_in_user: '',
      search_project: '',
      delete_doc: '',
      current_proj: {},
      clicked_project: {},
    };
  }

  static defaultProps = {
    loadingProjects: true,
    loadingResources: true,
  };

  componentDidMount() {
    this.loadProjectSectionData();
    this.fetchCurrentUser();
  }

  fetchCurrentUser = async () => {
    const { checkLoggedIn } = this.props;
    const user = await checkLoggedIn();
    this.setState({
      logged_in_user: user.email,
    });
  };
  loadProjectSectionData = async () => {
    const {
      stopLoader,
      resources,
      getProjects,
      getResources,
      receivers,
      getAllReceivers,
      getNewRates,
      getRates,
    } = this.props;

    const promises = [getNewRates(), getRates(), getResources('?embed=roles'), getProjects()];
    !receivers && promises.push(getAllReceivers());
    await Promise.all(promises);
    stopLoader();
  };

  handleProjectClick = (project, current_proj) => {
    if (this.state.edit || this.state.show_set_rate_view) {
      this.setState({
        clicked_project: project,
        show_project: current_proj, //save the current project value until decided which project to render while switching a project in edit mode
        project_name: project.name,
        show_confirmation_popup: true,
      });
    } else {
      this.setState({
        show_project: project,
        edit: false,
        selected_project_status: '',
        selected_project_manager: '',
        selected_project_association: '',
        selected_project_receiver: '',
        show_add_associate_receiver: false,
        current_proj: current_proj, //save value of previous project
      });
    }
  };

  handleProjectEditing = () => {
    const { clicked_project } = this.state;
    this.setState({
      show_project: clicked_project,
      edit: false,
      selected_project_status: '',
      selected_project_manager: '',
      selected_project_association: '',
      selected_project_receiver: '',
      show_add_associate_receiver: false,
    });
  };

  onContinue = () => {
    this.setState({ show_confirmation_popup: false });
  };

  onCancel = () => {
    this.setState({
      show_set_rate_view: false,
      show_confirmation_popup: false,
    });
    this.handleProjectEditing();
  };

  validateProjectInput = (project_id) => {
    const { project_errors } = this.state;
    const { enqueueSnackbar, projects } = this.props;

    if (projects) {
      // check for unique project ID
      if (projects.some((project) => project.id === project_id)) {
        enqueueSnackbar('Project ID must be unique, try again!', { variant: 'error' });
        return false;
      }
    }

    if (project_errors.length > 0) {
      enqueueSnackbar('Errors!', { variant: 'error' });
      return false;
    }
    return true;
  };

  handleAddProject = () => {
    let {
      project_name,
      // project_manager,
      // project_status,
      // project_association,
      project_color,
    } = this.state;
    const { addProject, projects, startLoader, stopLoader, enqueueSnackbar, getProjects } =
      this.props;

    let project_id = Math.floor(Math.random() * 1000000000);

    if (projects && this.validateProjectInput(project_id)) {
      startLoader('#d43a26')
        .then((response) => {
          if (response) {
            return addProject({
              id: project_id,
              name: project_name,
              color: project_color,
              archived: false,
            });
          }
        })
        .then((res) => {
          if (res) {
            enqueueSnackbar('Project Added!', { variant: 'success' });
            return getProjects();
          } else {
            enqueueSnackbar("Project wasn't added!", { variant: 'error' });
            stopLoader();
          }
        })
        .then((res) => {
          this.handleHideAddProject();
          stopLoader();
        });
    } else {
      enqueueSnackbar("Project wasn't added!", { variant: 'error' });
    }
  };

  handleProjectName = (e) => {
    const { project_errors } = this.state;
    const { projects } = this.props;

    let new_errors = project_errors;

    // check for unique project name
    if (projects.some((project) => project.name === e.target.value)) {
      if (!project_errors.includes(PROJECT_ERRORS[0])) {
        new_errors.push(PROJECT_ERRORS[0]);
      }
    } else {
      new_errors = project_errors.filter((error) => error != PROJECT_ERRORS[0]);
    }

    this.setState({
      project_name: e.target.value,
      project_errors: new_errors,
    });
  };

  handleProjectManager = (e) => {
    this.setState({
      project_manager: e.target.value,
    });
  };

  handleProjectStatus = (e) => {
    this.setState({
      project_status: e.target.value,
    });
  };

  handleProjectAssociation = (e) => {
    this.setState({
      project_association: e.target.value,
    });
  };

  handleProjectColor = (e) => {
    const { project_errors } = this.state;

    let new_errors = project_errors;

    // check for not bright enough color
    if (getLuminance(e.target.value) > 200) {
      if (!project_errors.includes(PROJECT_ERRORS[1])) {
        new_errors.push(PROJECT_ERRORS[1]);
      }
    } else {
      new_errors = new_errors.filter((error) => error != PROJECT_ERRORS[1]);
    }

    // check for valid hex value
    var pattern = new RegExp('^#[0-9a-f]{6}$');
    if (!pattern.test(e.target.value.toLowerCase())) {
      if (!project_errors.includes(PROJECT_ERRORS[2])) {
        new_errors.push(PROJECT_ERRORS[2]);
      }
    } else {
      new_errors = new_errors.filter((error) => error != PROJECT_ERRORS[2]);
    }

    this.setState({
      project_color: e.target.value,
      project_errors: new_errors,
    });
  };

  handleShowAddProject = () => {
    this.setState({
      show_add_project: true,
    });
  };

  handleHideAddProject = () => {
    this.setState({
      show_add_project: false,
      project_name: '',
      project_manager: '',
      project_status: '',
      project_association: '',
      project_color: '',
      project_errors: [],
    });
  };

  handleShowEditProjectView = () => {
    let { show_project } = this.state;
    let { no_data, projects } = this.props;

    let project_selected = {};
    if (Object.keys(show_project).length == 0 && !no_data && projects && projects.length > 0) {
      project_selected = projects[0];
    } else if (Object.keys(show_project).length > 0 && !no_data && projects) {
      project_selected = projects.filter((project) => project.name === show_project.name)[0];
    }
    this.setState({
      AssociatedReceivers: project_selected.affiliate_receiver
        ? project_selected.affiliate_receiver
        : '',
      edit: true,
    });
  };

  handleHideEditProjectView = () => {
    let { AssociatedReceivers, show_project } = this.state;
    let { projects } = this.props;
    let temp = AssociatedReceivers;
    let index = -1;

    if (temp !== '') {
      AssociatedReceivers.map((receiver, idx) => {
        if (receiver.name === 'dummy_value') {
          index = idx;
          return true; // to avoid warning
        }
        return true; // to avoid warning
      });
      if (index !== -1) {
        temp.splice(index, 1);
      }
    }

    const updated_project = projects.filter((project) => project.name === show_project.name)[0];
    this.setState({
      edit: false,
      selected_project_status: '',
      selected_project_manager: '',
      selected_project_association: '',
      AssociatedReceivers: temp,
      show_percentage_error: false,
      show_add_associate_receiver: false,
      selected_project_receiver: '',
      show_project: updated_project,
    });
  };

  handleHideAddAssociateReceiver = () => {
    this.setState({
      show_add_associate_receiver: false,
      AssociatedReceivers: [],
    });
  };

  handleChange = (e, type) => {
    if (type === 'project_manager') {
      this.setState({
        selected_project_manager: e, // whole array is coming not event obj in this case
      });
    } else if (type === 'project_status') {
      this.setState({
        selected_project_status: e.target.value === 'true',
      });
    } else if (type === 'project_association') {
      this.setState({
        selected_project_association: e.target.value === 'true',
      });
    } else if (type === 'project_receiver') {
      let { show_project } = this.state;
      let { no_data, projects, receivers } = this.props;

      let project_selected = {};
      if (Object.keys(show_project).length == 0 && !no_data && projects && projects.length > 0) {
        project_selected = projects[0];
      } else if (Object.keys(show_project).length > 0 && !no_data && projects) {
        project_selected = projects.filter((project) => project.name === show_project.name)[0];
      }

      this.setState({
        selected_project_receiver: e.target.value,
      });
      receivers.map((receiver, index) => {
        if (receiver.receiver_name === e.target.value && receiver.enable_affiliate) {
          this.setState({
            show_add_associate_receiver: true,
          });
        }
        if (receiver.receiver_name === e.target.value && !receiver.enable_affiliate) {
          this.setState({
            show_add_associate_receiver: false,
            consider_prop: false,
            AssociatedReceivers: project_selected.affiliate_receiver
              ? project_selected.affiliate_receiver
              : '',
          });
        }
        return true;
      });
    }
  };

  handleAssociateReceiver = (e, index, type) => {
    let { AssociatedReceivers } = this.state;
    let temp = AssociatedReceivers;
    if (type === 'name') {
      temp[index].name = e.target.value;
    } else if (type === 'percentage') {
      temp[index].percentage = e.target.value;
    }
    this.setState({
      AssociatedReceivers: temp,
    });
  };

  handleRemoveAssociateReceiver = (index) => {
    let { AssociatedReceivers } = this.state;
    let temp = AssociatedReceivers;
    temp.splice(index, 1);
    this.setState({
      AssociatedReceivers: temp,
    });
  };

  handleAddSubReceiverRow = () => {
    let { AssociatedReceivers } = this.state;
    let temp = [];
    if (AssociatedReceivers !== '') {
      temp = AssociatedReceivers;
    }
    temp.push({ type: 'affiliate', name: 'dummy_value', percentage: -1 });
    this.setState({
      AssociatedReceivers: temp,
    });
  };

  handleEditProject = (project) => {
    const {
      startLoader,
      stopLoader,
      getProjects,
      updateProjectConfig,
      enqueueSnackbar,
      receivers,
    } = this.props;
    let {
      selected_project_status,
      selected_project_manager,
      selected_project_association,
      AssociatedReceivers,
      selected_project_receiver,
    } = this.state;

    let total_percentage = AssociatedReceivers
      ? AssociatedReceivers.reduce((a, b) => +a + +b.percentage, 0)
      : 0;

    if (total_percentage > 100) {
      // total must not exceed hundred
      this.setState({
        show_percentage_error: true,
      });
    } else {
      let base_receiver = {};
      if (selected_project_receiver !== '') {
        base_receiver = {
          type: 'base',
          name: selected_project_receiver,
          percentage: (100 - total_percentage).toString(),
        };
      }
      let save_affilites = false;
      for (var i in receivers) {
        if (
          receivers[i].receiver_name === selected_project_receiver &&
          receivers[i].enable_affiliate
        ) {
          save_affilites = true;
        }
      }
      startLoader('#d43a26')
        .then((response) => {
          if (response) {
            if (AssociatedReceivers && save_affilites) {
              return updateProjectConfig({
                project: project.name,
                active: selected_project_status === '' ? project.active : selected_project_status,
                managers: selected_project_manager
                  ? [...selected_project_manager.map((item) => item.email)]
                  : project.project_managers
                  ? [...project.project_managers.map((item) => item.email)]
                  : [],
                billable:
                  selected_project_association === ''
                    ? project.billable
                    : selected_project_association,
                base_receiver: base_receiver,
                affiliate_receiver: AssociatedReceivers,
              });
            } else {
              return updateProjectConfig({
                project: project.name,
                active: selected_project_status === '' ? project.active : selected_project_status,
                managers: selected_project_manager
                  ? [...selected_project_manager.map((item) => item.email)]
                  : project.project_managers
                  ? [...project.project_managers.map((item) => item.email)]
                  : [],
                billable:
                  selected_project_association === ''
                    ? project.billable
                    : selected_project_association,
                base_receiver: base_receiver,
              });
            }
          }
        })
        .then((res) => {
          if (res) {
            enqueueSnackbar('Project Updated!', { variant: 'success' });
            this.setState({
              show_percentage_error: false,
              AssociatedReceivers: '',
              consider_prop: true,
            });
            return getProjects();
          } else {
            enqueueSnackbar("Project wasn't updated!", { variant: 'error' });
            stopLoader();
          }
          this.handleHideEditProjectView();
        })
        .then((res) => {
          stopLoader();
          this.handleHideEditProjectView();
        });
    }
  };
  handleShowSetResourceRate = () => {
    this.setState({
      show_set_rate_view: true,
    });
  };

  handleHideSetResourceRate = () => {
    this.setState({
      show_set_rate_view: false,
    });
  };

  handleAddRate = (project_id, resource_id, role, role_type, rate) => {
    let { setRate, enqueueSnackbar, startLoader, stopLoader, getNewRates, getRates } = this.props;
    if (resource_id && rate && rate >= 0) {
      startLoader('#d43a26')
        .then((response) => {
          if (response) {
            return setRate({
              resource_id: resource_id,
              project_id: project_id,
              rate: rate,
              role: role,
              role_type: role_type,
            });
          }
        })
        .then(async (response) => {
          if (response) {
            ReactGA.event({
              category: 'Resources',
              action: 'Added a Resource rate',
            });
            enqueueSnackbar('Rate set successfully!', { variant: 'success' });
            await Promise.all([getNewRates(), getRates(), this.handleHideSetResourceRate()]);
          } else {
            enqueueSnackbar('Unable to set rate!', { variant: 'error' });
          }
          stopLoader();
        });
    } else {
      enqueueSnackbar('Select Correct fields', { variant: 'error' });
    }
  };

  handleEdit = (role, rate, project_id, resource_id, role_type, docId) => {
    let { enqueueSnackbar, startLoader, stopLoader, getNewRates, getRates, editRate } = this.props;
    startLoader('#d43a26')
      .then((response) => {
        if (response) {
          return editRate(
            {
              resource_id: parseInt(resource_id),
              project_id: parseInt(project_id),
              rate: parseInt(rate),
              role: role,
              role_type: role_type,
            },
            docId,
          );
        }
      })
      .then(async (response) => {
        if (response) {
          ReactGA.event({
            category: 'Resources',
            action: 'Edited a Resource rate/role',
          });
          enqueueSnackbar('Rate/role changed successfully!', { variant: 'success' });
          await Promise.all([getNewRates(), getRates()]);
        } else {
          enqueueSnackbar('Unable to change rate!', { variant: 'error' });
        }
        stopLoader();
      });
  };

  toggleDeletePopupResources = (docId) => {
    this.setState({
      show_delete_popup: !this.state.show_delete_popup,
      delete_doc: docId,
    });
  };

  handleDelete = () => {
    let { enqueueSnackbar, startLoader, stopLoader, getNewRates, getRates, deleteRate } =
      this.props;
    let { delete_doc } = this.state;

    startLoader('#d43a26')
      .then((response) => {
        if (response) {
          return deleteRate(delete_doc);
        }
      })
      .then(async (response) => {
        if (response) {
          ReactGA.event({
            category: 'Resources',
            action: 'Deleted a Resource rate/role',
          });
          enqueueSnackbar('Rate/role deleted successfully!', { variant: 'success' });
          await Promise.all([getNewRates(), getRates(), this.toggleDeletePopupResources()]);
        } else {
          enqueueSnackbar('Unable to delete rate!', { variant: 'error' });
        }
        stopLoader();
      });
  };

  handleProjectSearch = (e) => {
    this.setState({
      search_project: e.target.value,
    });
  };
  render() {
    let {
      loadingProjects,
      projects,
      no_data,
      roles,
      loadingResources,
      no_resource_data,
      resources,
      resourcesMap,
      receivers,
      new_rates,
    } = this.props;
    let {
      show_project,
      show_add_project,
      project_name,
      project_manager,
      project_status,
      project_association,
      project_color,
      project_errors,
      edit,
      selected_project_association,
      selected_project_manager,
      selected_project_status,
      show_add_associate_receiver,
      selected_project_receiver,
      AssociatedReceivers,
      show_percentage_error,
      consider_prop,
      show_set_rate_view,
      show_delete_popup,
      logged_in_user,
      search_project,
    } = this.state;
    let project_selected = {};
    let billable_projects = [];
    let unbillable_projects = [];
    let show_edit_button = hasPermission(roles, 'project', 'can_edit');
    let can_set_rate = hasPermission(roles, 'project', 'can_set_rate');
    let can_delete = hasPermission(roles, 'project', 'can_delete_rate');
    let resources_rate_data = [];

    let filtered_projects =
      roles.includes('Project Manager') &&
      !(roles.includes('Super Admin') || roles.includes('View Admin'))
        ? projects &&
          projects.filter(
            (project) =>
              project.project_managers &&
              project.project_managers.some((manager) => manager.email === logged_in_user),
          )
        : projects;

    const active_projects =
      filtered_projects && filtered_projects.filter((project) => project.active);
    const inactive_projects =
      filtered_projects && filtered_projects.filter((project) => !project.active);
    if (active_projects && active_projects.length > 0) {
      active_projects.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
      );
    }
    if (inactive_projects && inactive_projects.length > 0) {
      inactive_projects.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
      );
    }
    if (filtered_projects) {
      filtered_projects.forEach((project) => {
        project.billable ? billable_projects.push(project) : unbillable_projects.push(project);
      });
    }
    if (show_project && Object.keys(show_project).length > 0) {
      project_selected = show_project;
    } else {
      if (!no_data && active_projects && active_projects.length > 0) {
        project_selected = active_projects[0];
      } else if (!no_data && inactive_projects && inactive_projects.length > 0) {
        project_selected = inactive_projects[0];
      }
    }
    if (new_rates && project_selected && Object.keys(project_selected).length !== 0) {
      resources_rate_data = getResourceRatesForProjects(project_selected, new_rates);
    }
    resourcesMap &&
      resources_rate_data.map((resourceRate) => {
        const currentResource = resourcesMap.get(resourceRate.resource_id);
        // handle orphan records
        if (currentResource) {
          resourceRate.resource_active = currentResource.active;
          resourceRate.resource_email = currentResource.email;
        }
      });

    if (
      project_selected &&
      Object.keys(project_selected).length !== 0 &&
      selected_project_status === ''
    ) {
      selected_project_status = project_selected.active;
    }

    if (
      project_selected &&
      Object.keys(project_selected).length !== 0 &&
      project_selected.project_managers !== '' &&
      selected_project_manager === ''
    ) {
      selected_project_manager = project_selected.project_managers;
    }

    if (
      project_selected &&
      Object.keys(project_selected).length !== 0 &&
      selected_project_association === ''
    ) {
      selected_project_association = project_selected.billable;
    }

    if (
      roles.includes('Project Manager') &&
      roles.includes('View Admin') &&
      !roles.includes('Super Admin')
    ) {
      //show set rate button for the projects user is PM for incase when user is PM + View Admin
      can_set_rate =
        hasPermission(roles, 'project', 'can_set_rate') &&
        project_selected &&
        Object.keys(project_selected).length !== 0 &&
        project_selected.project_managers &&
        project_selected.project_managers.some((manager) => manager.email === logged_in_user);
    }
    const show_set_rate_button = can_set_rate && filtered_projects && filtered_projects.length > 0;
    return (
      <div style={style.ComponentMain}>
        <Toolbar style={style.MainHeading}>
          <p className="breadcrumb-link" style={style.HeaderText}>
            Projects
            <br />
            {filtered_projects && filtered_projects.length !== 0 ? (
              <span style={style.ActivePeriodText}>
                &#x2934; Billable: {billable_projects.length}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#x2935; UnBillable:{' '}
                {unbillable_projects.length}
              </span>
            ) : null}
          </p>
          {hasPermission(roles, 'project', 'can_create') ? (
            <button style={style.FilledButton} onClick={this.handleShowAddProject}>
              + Add Project
            </button>
          ) : null}
        </Toolbar>
        <div
          style={
            loadingProjects || no_data || loadingResources || no_resource_data
              ? style.LoadingContainer
              : style.MainContainer
          }>
          {loadingProjects || loadingResources ? (
            <Bars color="#d43a26" height={50} width={50} />
          ) : no_data || no_resource_data ? (
            <EmptyContainer />
          ) : (
            <>
              <List style={style.ListView}>
                {filtered_projects && filtered_projects.length > 0 ? (
                  <input
                    type="text"
                    style={{
                      backgroundColor: 'white',
                      padding: '10px',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderBottom: 'solid 2px var(--tin-red)',
                      margin: '5px',
                      color: 'var(--tin-grey)',
                      width: '97%',
                      outline: 'none',
                    }}
                    value={search_project}
                    onChange={this.handleProjectSearch}
                    placeholder="Search Project"></input>
                ) : null}

                {active_projects && active_projects.length > 0 ? (
                  <>
                    <h4 style={{ marginLeft: '10px' }}>Active Projects</h4>
                    {active_projects.map((project, index) => {
                      return (search_project !== '' &&
                        project.name.toLowerCase().includes(search_project.toLowerCase())) ||
                        search_project == '' ? (
                        <ListItem
                          button
                          key={index}
                          onClick={() => this.handleProjectClick(project, project_selected)}
                          style={
                            project_selected && project_selected.name === project.name
                              ? style.SelectedProjectItem
                              : style.UnselectedProjectItem
                          }>
                          <span
                            style={
                              project_selected && project_selected.name === project.name
                                ? style.SelectedProjectText
                                : style.UnselectedProjectText
                            }>
                            {project.name}
                          </span>
                        </ListItem>
                      ) : null;
                    })}
                  </>
                ) : null}
                {inactive_projects && inactive_projects.length > 0 ? (
                  <>
                    <h4 style={{ marginLeft: '10px' }}>Inactive Projects</h4>
                    {inactive_projects.map((project, index) => {
                      return (search_project !== '' &&
                        project.name.toLowerCase().includes(search_project)) ||
                        search_project == '' ? (
                        <ListItem
                          button
                          key={index}
                          onClick={() => this.handleProjectClick(project, project_selected)}
                          style={
                            project_selected && project_selected.name === project.name
                              ? style.SelectedProjectItem
                              : style.UnselectedProjectItem
                          }>
                          <span
                            style={
                              project_selected && project_selected.name === project.name
                                ? style.SelectedProjectText
                                : style.UnselectedProjectText
                            }>
                            {project.name}
                          </span>
                        </ListItem>
                      ) : null;
                    })}
                  </>
                ) : null}
              </List>
              <div style={style.ListViewContainer}>
                {project_selected && Object.keys(project_selected).length !== 0 ? (
                  <ProjectDetailView
                    edit={edit}
                    resources={resources}
                    project={project_selected}
                    handleChange={this.handleChange}
                    show_edit_button={show_edit_button}
                    handleEditProject={this.handleEditProject}
                    selected_project_status={selected_project_status}
                    selected_project_manager={
                      typeof selected_project_manager === 'string'
                        ? [selected_project_manager]
                        : selected_project_manager
                    } // After integrating api make pass only array & remove this condition
                    handleShowEditProjectView={this.handleShowEditProjectView}
                    handleHideEditProjectView={this.handleHideEditProjectView}
                    selected_project_association={selected_project_association}
                    receivers={receivers}
                    AssociatedReceivers={AssociatedReceivers}
                    handleAddSubReceiverRow={this.handleAddSubReceiverRow}
                    project_association={project_association}
                    show_add_associate_receiver={show_add_associate_receiver}
                    selected_project_receiver={selected_project_receiver}
                    handleAssociateReceiver={this.handleAssociateReceiver}
                    handleRemoveAssociateReceiver={this.handleRemoveAssociateReceiver}
                    show_percentage_error={show_percentage_error}
                    consider_prop={consider_prop}
                  />
                ) : (
                  <EmptyContainer />
                )}
                {(project_selected &&
                  Object.keys(project_selected).length !== 0 &&
                  show_set_rate_button) ||
                (new_rates && resources_rate_data.length > 0) ? (
                  <RatesTableView
                    resources_rate_data={resources_rate_data}
                    entity_type={'Resource'}
                    entity_parent_id={project_selected.id}
                    entity_parent_active={project_selected.active}
                    active_entities={
                      resources ? resources.filter((resource) => resource.active == true) : []
                    }
                    roles={roles}
                    edit_mode_active={edit}
                    can_delete={can_delete}
                    can_set_rate={can_set_rate}
                    show_set_rate_button={show_set_rate_button}
                    toggleDeletePopup={this.toggleDeletePopupResources.bind(this)}
                    handleApplyButton={this.handleAddRate}
                    handleEdit={this.handleEdit}
                    select_style={style.Select}
                    button_style={style.BorderedButton}
                    filled_button_style={style.FilledButton}
                  />
                ) : null}
              </div>
              <DialogBoxes
                resources={resources}
                project_name={project_name}
                project_color={project_color}
                project_status={project_status}
                project_manager={project_manager}
                project_errors={project_errors}
                show_add_project={show_add_project}
                handleAddProject={this.handleAddProject}
                handleAddSubReceiverRow={this.handleAddSubReceiverRow}
                project_association={project_association}
                handleProjectName={this.handleProjectName}
                handleProjectColor={this.handleProjectColor}
                handleProjectStatus={this.handleProjectStatus}
                handleHideAddProject={this.handleHideAddProject}
                handleHideAddAssociateReceiver={this.handleHideAddAssociateReceiver}
                handleProjectManager={this.handleProjectManager}
                handleProjectAssociation={this.handleProjectAssociation}
                receivers={receivers}
                handleAddSubReceivers={this.handleAddSubReceivers}
                show_confirmation_popup={this.state.show_confirmation_popup}
                onContinue={this.onContinue}
                onCancel={this.onCancel}
                show_delete_popup={show_delete_popup}
                handleDelete={this.handleDelete}
                toggleDeletePopupResources={this.toggleDeletePopupResources.bind(this)}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.LoginReducer.roles,
    no_data: state.ProjectReducer.no_data,
    projects: state.ProjectReducer.projects,
    no_resource_data: state.ResourceReducer.no_data,
    resources: state.ResourceReducer.resources,
    resourcesMap: state.ResourceReducer.resourcesMap,
    loadingProjects: state.ProjectReducer.loadingProjects,
    loadingResources: state.ResourceReducer.loadingResources,
    receivers: state.ReceiverReducer.receivers,
    no_receivers_data: state.ReceiverReducer.no_receivers_data,
    new_rates: state.ResourceReducer.new_rates,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addProject: (project) => dispatch(ActionsCreator.addProject(project)),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  getResources: (query) => dispatch(ActionsCreator.getResources(query)),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  updateProjectConfig: (data) => dispatch(ActionsCreator.updateProjectConfig(data)),
  getAllReceivers: () => dispatch(ActionsCreator.getAllReceivers()),
  setRate: (data) => dispatch(ActionsCreator.setRate(data)),
  editRate: (data, docId) => dispatch(ActionsCreator.editRate(data, docId)),
  getRates: () => dispatch(ActionsCreator.getRates()),
  getNewRates: () => dispatch(ActionsCreator.getNewRates()),
  deleteRate: (docId) => dispatch(ActionsCreator.deleteRate(docId)),
  checkLoggedIn: () => dispatch(ActionsCreator.checkLoggedIn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Project));

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withSnackbar } from 'notistack';
import Toolbar from '@mui/material/Toolbar';

import './Dashboard.css';
import Moment from 'moment';
import { SelectProject } from './SelectProject';
import '../HoursManagement/HoursManagement.css';
import { Ability } from '../../constants/ability';
import { ActionsCreator } from '../../Redux/Actions/index';
import { InvoiceState } from '../../constants/invoicestate';
import ProjectManagerDashboard from './ProjectManagerDashboard';
import { AuthenticatedDashboard } from './AuthenticatedDashboard';
import {
  getProjectOfResource,
  getProjectInvoiceDetails,
  getActiveParsedDataDates,
} from '../../Selectors/index';

let months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

let present_month = Moment().month() + 1;
if (present_month.toString().length === 1) present_month = '0' + present_month;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csv_data: null,
      parsed_csv_data: null,
      show_invoicing: false,
      hours: 0,
      open_upload_csv: false,
      selected_month: '',
      selected_month_number: Moment().year() + '-' + present_month,
    };
  }

  static defaultProps = {
    parsed_csv_data: null,
  };

  componentDidMount() {
    this.setState({ selected_month: months[Moment().month()] });
    this.props.getAllReceivers();
  }

  handleFinalizeInvoicing = () => {
    let { rates, parsed_csv_data, project, saveInvoice, enqueueSnackbar, projects } = this.props;
    let details = getProjectInvoiceDetails(rates, parsed_csv_data, project, projects);
    let data = {
      project_name: project,
      project_manager: details.project_manager,
      status: InvoiceState.DRAFT.name,
      invoice_data: JSON.stringify(details.resource_details),
      type: 'Postpaid',
    };

    saveInvoice(data).then((data_rec) => {
      if (data_rec.saved) {
        enqueueSnackbar(data_rec.message, { variant: 'success' });
      } else {
        enqueueSnackbar(data_rec.message, { variant: 'error' });
      }
    });
  };

  handleProjectSelection = (e) => {
    this.props.setDashboardProject(e.target.value);
  };

  handleMonthSelection = (e) => {
    let selected_month = e.target.value;
    let selected_month_index = months.indexOf(selected_month) + 1;
    if (selected_month_index.toString().length === 1)
      selected_month_index = '0' + selected_month_index;
    let selected_date = Moment().year() + '-' + selected_month_index;
    this.props.startLoader('#01b9fe').then((response) => {
      if (response)
        this.setState({ selected_month_number: selected_date, selected_month: selected_month });
    });
  };

  render() {
    const { roles, projects, email, rates, parsed_csv_data, dashboard_project } = this.props;
    const { selected_month, selected_month_number } = this.state;

    let current_month = Moment().month();
    let current_month_name = months[current_month];

    let pending_dates = 'N/A';
    let active_dates = 'N/A';
    let active_month = 'N/A';

    const dashboard_details = Ability[roles[0]].dashboard;
    let filtered_projects = [];
    let active_period_dates;

    if (projects && rates) {
      filtered_projects = projects.filter((project) => project.billable);
      let resource_projects = getProjectOfResource(email, projects);
      filtered_projects = filtered_projects.filter((project) =>
        resource_projects.includes(project.name),
      );
    }

    if (parsed_csv_data) {
      active_period_dates = getActiveParsedDataDates(parsed_csv_data);
    }

    if (current_month_name === selected_month) active_month = current_month_name;
    if (current_month_name === selected_month && parsed_csv_data) {
      const month_end = Moment().endOf('month').format('D MMM YYYY');
      let active_days = 0,
        pending_days = 0;
      for (let i = 1; i < 8; i++) {
        active_days = i;
        if (Moment().startOf('week').add(i, 'days').isAfter(Moment(month_end))) {
          active_days = i - 1;
          break;
        }
      }
      if (active_days !== 1)
        active_dates =
          Moment().startOf('week').add(1, 'days').format('D MMM YYYY') +
          ' to ' +
          Moment().startOf('week').add(active_days, 'd').format('D MMM YYYY');

      for (let i = 1; i < Moment().daysInMonth() + 1; i++) {
        pending_days = i;
        if (
          Moment()
            .startOf('week')
            .add(active_days + i, 'days')
            .isAfter(Moment(month_end))
        )
          break;
      }
      if (pending_days !== 1)
        pending_dates =
          Moment()
            .startOf('week')
            .add(active_days + 1, 'days')
            .format('D MMM YYYY') +
          ' to ' +
          Moment()
            .startOf('week')
            .add(active_days + pending_days - 1, 'd')
            .format('D MMM YYYY');
    }

    let display_project =
      dashboard_project === undefined
        ? filtered_projects && filtered_projects.length > 0
          ? filtered_projects[0].name
          : dashboard_project
        : dashboard_project;

    return (
      <div className="hours-management-main">
        <Toolbar className="hours-management-main-heading">
          {/* {roles[0] === "Hour Logger" ? ( */}
          <>
            <div className="breadcrumb-link" style={style.HeaderText}>
              Dashboard
            </div>
          </>
          {/* ) : (
            <>
              <div className="breadcrumb-link" style={style.HeaderText}>
                Dashboard
                <br />
                <p style={style.ActiveMonth}>Active Month : {active_month}</p>
                <select
                  name="Month"
                  style={style.Select}
                  onChange={this.handleMonthSelection}
                  defaultValue={current_month_name}
                >
                  {months.map((month, index) => {
                    return <option key={index}>{month}</option>;
                  })}
                </select>
              </div>
              {parsed_csv_data &&
                Moment(active_period_dates.start_date, 'D MMM YYYY').isValid() &&
                Moment(active_period_dates.end_date, 'D MMM YYYY').isValid() ? (
                <div style={style.PeriodsRow}>
                  <div style={style.PeriodValueCol}>Locked   : {active_period_dates.start_date} to {active_period_dates.end_date}</div>
                  <div style={style.PeriodValueCol}>Active    : {active_dates}</div>
                  <div style={style.PeriodValueCol}>Pending : {pending_dates}</div>
                </div>
              ) : null}
              {projects &&
                dashboard_details.present &&
                dashboard_details.name === ProjectManagerDashboard ? (
                <SelectProject
                  projects={filtered_projects}
                  project={display_project}
                  handleProjectSelection={this.handleProjectSelection}
                />
              ) : null}
            </>
          )} */}
        </Toolbar>
        <div className="hoursmanagement-container">
          <AuthenticatedDashboard
            role={roles[0]}
            project={display_project}
            selected_month={selected_month_number}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.LoginReducer.name,
    email: state.LoginReducer.email,
    roles: state.LoginReducer.roles,
    rates: state.ResourceReducer.rates,
    projects: state.ProjectReducer.projects,
    parsed_csv_data: state.HoursManagementReducer.parsed_csv_data,
    dashboard_project: state.InvoiceReducer.dashboard_project,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getParsedData: (data) => dispatch(ActionsCreator.getParsedData(data)),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getAllReceivers: () => dispatch(ActionsCreator.getAllReceivers()),
  saveInvoice: (data) => dispatch(ActionsCreator.saveInvoice(data)),
  setDashboardProject: (data) => dispatch(ActionsCreator.setDashboardProject(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Dashboard));

export const style = {
  LoadingContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  MainContainer: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: 'var(--tin-lightgrey)',
  },
  ActivePeriodText: {
    margin: '0px',
    fontSize: '11px',
    lineHeight: 'normal',
  },
  HeaderText: {
    flex: 0.4,
    lineHeight: '20px',
    display: 'block',
  },
  PeriodsRow: {
    whiteSpace: 'pre',
    margin: '0px',
    display: 'flex',
    flex: 4,
    flexDirection: 'column',
    paddingLeft: '30px',
    fontSize: '10px',
    lineHeight: 'normal',
    fontWeight: 'bold',
  },
  PeriodValueCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '5px',
  },
  ActiveMonth: {
    fontSize: '11px',
    lineHeight: 'normal',
  },
  Select: {
    cursor: 'pointer',
    fontSize: '11px',
    fontWeight: 'bold',
    height: '20px',
    backgroundColor: 'var(--tin-blue)',
    border: 'none',
    color: 'white',
    width: '60%',
    ':focus': {
      outline: 'none',
    },
  },
};

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Moment from 'moment';
import { Bars } from 'react-loader-spinner';

import { style } from '../style';
import { InvoicesPieChart } from './InvoicesPieChart';
import { ActionsCreator } from '../../../Redux/Actions/index';
import { EmptyContainer } from '../../Utility/EmptyContainer';
import { ProjectComparisonChart } from './ProjectComparisonChart';
import {
  generateDataforInvoiceTimeline,
  generateDataforInvoiceHistoryChart,
  generateInvoicesPieChartDataForReporting,
} from '../../../Selectors';
import { withSnackbar } from 'notistack';

class InvoiceReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_project: '',
      selected_start_date: '',
      selected_end_date: '',
    };
  }

  static defaultProps = {
    loading: true,
  };

  componentDidMount() {
    this.loadReportingData();
  }

  loadReportingData = async () => {
    let {
      rates,
      range,
      projects,
      invoices,
      getRates,
      stopLoader,
      getProjects,
      getInvoices,
      getHoursRange,
      getProjectHoursRecords,
      getParsedData,
      startLoader,
    } = this.props;

    startLoader('var(--tin-pink)');

    let filtered_projects = [];

    !projects && (projects = await getProjects());
    !rates && (await getRates());
    !range && (await getHoursRange());
    !invoices && (await getInvoices());

    await getParsedData();

    filtered_projects = projects.filter((project) => project.billable);

    if (filtered_projects.length > 0) {
      getProjectHoursRecords(filtered_projects[0].name).then((response) => {
        if (response) {
          stopLoader();
        }
        if (!response) {
          stopLoader();
        }
      });
    }
  };

  handleProjectSelection = (e) => {
    let { startLoader, stopLoader, getProjectHoursRecords } = this.props;

    this.setState(
      {
        selected_project: e.target.value,
      },
      () => {
        startLoader('#d0175d')
          .then((response) => {
            if (response) {
              return getProjectHoursRecords(this.state.selected_project);
            }
          })
          .then((response) => {
            stopLoader();
          });
      },
    );
  };

  handleChangeStartDate = (e) => {
    let { enqueueSnackbar } = this.props;
    let { selected_end_date } = this.state;
    let new_date = Moment(e.target.value).format('YYYY-MM-DD');

    if (
      Moment(selected_end_date).isValid() &&
      Moment(new_date).isAfter(Moment(selected_end_date))
    ) {
      enqueueSnackbar('Start Date should be less than End Date!', { variant: 'error' });
    } else {
      this.setState({
        selected_start_date: new_date,
      });
    }
  };

  handleChangeEndDate = (e) => {
    let { enqueueSnackbar } = this.props;
    let { selected_start_date } = this.state;
    let new_date = Moment(e.target.value).format('YYYY-MM-DD');

    if (
      Moment(selected_start_date).isValid() &&
      Moment(selected_start_date).isAfter(Moment(new_date))
    ) {
      enqueueSnackbar("End Date shouldn't be less than Start Date!", { variant: 'error' });
    } else {
      this.setState({
        selected_end_date: new_date,
      });
    }
  };

  render() {
    const {
      rates,
      range,
      loading,
      projects,
      invoices,
      project_records,
      no_projects_data,
      no_active_period,
      no_project_records,
      goBack,
    } = this.props;
    let { selected_project, selected_end_date, selected_start_date } = this.state;
    let invoices_chart_data = [];
    let invoices_pie_chart_data = [];
    let filtered_projects = [];
    let filtered_invoices = [];
    let filtered_pie_chart_invoices = [];
    let invoices_timeline_chart_data = [];

    if (projects && projects.length > 0) {
      filtered_projects = projects.filter((project) => project.billable);
      selected_project === '' && (selected_project = filtered_projects[0].name);
    }

    if (invoices && !no_project_records && project_records && rates) {
      filtered_invoices = invoices.filter((invoice) => invoice.project_name === selected_project);
      invoices_chart_data = generateDataforInvoiceHistoryChart(
        filtered_invoices,
        project_records,
        rates,
        projects,
      );
      invoices_timeline_chart_data = generateDataforInvoiceTimeline(filtered_invoices);
    }

    if (invoices) {
      filtered_pie_chart_invoices = JSON.parse(JSON.stringify(invoices));
      if (selected_start_date !== '') {
        filtered_pie_chart_invoices = filtered_pie_chart_invoices.filter(
          (invoice) =>
            Moment(invoice.start_date, 'YYYY-MM-DD') >= Moment(selected_start_date, 'YYYY-MM-DD'),
        );
      }
      if (selected_end_date !== '') {
        filtered_pie_chart_invoices = filtered_pie_chart_invoices.filter(
          (invoice) =>
            Moment(invoice.end_date, 'YYYY-MM-DD') <= Moment(selected_end_date, 'YYYY-MM-DD'),
        );
      }

      invoices_pie_chart_data = generateInvoicesPieChartDataForReporting(
        filtered_pie_chart_invoices,
      );
    }

    if (
      filtered_pie_chart_invoices &&
      filtered_pie_chart_invoices.length > 0 &&
      selected_start_date === ''
    ) {
      selected_start_date = filtered_pie_chart_invoices.sort(
        (a, b) => Moment(a.start_date) - Moment(b.start_date),
      )[0].start_date;
    }

    if (
      filtered_pie_chart_invoices &&
      filtered_pie_chart_invoices.length > 0 &&
      selected_end_date === ''
    ) {
      selected_end_date = filtered_pie_chart_invoices.sort(
        (a, b) => Moment(b.end_date) - Moment(a.end_date),
      )[0].end_date;
    }
    return (
      <div style={loading || !no_projects_data ? style.LoadingContainer : style.MainContainer}>
        {loading ? (
          <Bars color="#d43a26" height={50} width={50} />
        ) : no_projects_data ? (
          <EmptyContainer />
        ) : (
          <div style={style.FlexCol}>
            <ProjectComparisonChart
              project={selected_project}
              projects={filtered_projects}
              invoices_chart_data={invoices_chart_data}
              handleProjectSelection={this.handleProjectSelection}
              invoices_timeline_chart_data={invoices_timeline_chart_data}
              goBack={goBack}
            />
            <InvoicesPieChart
              selected_end_date={selected_end_date}
              selected_start_date={selected_start_date}
              handleChangeEndDate={this.handleChangeEndDate}
              invoices_pie_chart_data={invoices_pie_chart_data}
              handleChangeStartDate={this.handleChangeStartDate}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rates: state.ResourceReducer.rates,
    loading: state.LoaderReducer.loading,
    invoices: state.InvoiceReducer.invoices,
    projects: state.ProjectReducer.projects,
    range: state.HoursManagementReducer.range,
    no_projects_data: state.ProjectReducer.no_data,
    project_records: state.ProjectReducer.project_records,
    no_project_records: state.ProjectReducer.no_project_records,
    parsed_csv_data: state.HoursManagementReducer.parsed_csv_data,
    no_active_period: state.HoursManagementReducer.no_active_period,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRates: () => dispatch(ActionsCreator.getRates()),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getInvoices: () => dispatch(ActionsCreator.getInvoices()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  getHoursRange: () => dispatch(ActionsCreator.getHoursRange()),
  getParsedData: () => dispatch(ActionsCreator.getParsedData()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  getProjectHoursRecords: (data) => dispatch(ActionsCreator.getProjectHoursRecords(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(InvoiceReport));

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Moment from 'moment';
import { withSnackbar } from 'notistack';

import { DialogBoxes } from './DialogBoxes';
import { style } from '../StreamlineView/style';
import { Ability, hasPermission } from '../../../constants/ability';
import { FixedProjectHeader } from './FixedProjectHeader';
import { InvoiceState } from '../../../constants/invoicestate';
import { ActionsCreator } from '../../../Redux/Actions/index';
import { InvoiceDetailsTableView } from './InvoiceDetailsTableView';
import { ExpensesTable } from './ExpensesTable';
import { DiscountTable } from './DiscountTable';
import { CreateDialogBox } from './CreateDialogBox';

import { Table, TableBody } from '@mui/material';

import {
  getTotalFromInvoiceData,
  getTotalHoursFromInvoiceData,
  formatFloat,
  calculateReconciledPrice,
  updateEmployeeActualHours,
  getEmployeesHoursPerProject,
  getEmployeeHoursPerProjectInvoice,
  calculatePreviousInvoiceDates,
  calculatePreviousInvoice,
  containsValue,
  getAllClientsInfo,
  prepareInvoiceDataForAffiliate,
} from '../../../Selectors/index';
import * as ReactGA from 'react-ga';
import { ActivityLog } from './ActivityLog';

class ProjectDetailInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      selected_status: '',
      selected_resource: '',
      selected_receiver: '',
      selected_template: '',
      selected_invoice_id: '',
      selected_issued_date: '',
      selected_received_date: '',
      selected_submitted_date: '',
      show_add_resource_view: false,
      show_add_discount_view: false,
      show_add_expense_view: false,
      show_edit_invoice_dates_view: false,
      show_invoice_status_change_view: false,
      show_invoice_template_select_view: false,
      selected_discount:
        this.props.showInvoiceData && this.props.showInvoiceData.discount
          ? this.props.showInvoiceData.discount
          : 0,
      selected_discount_display_string:
        this.props.showInvoiceData && this.props.showInvoiceData.discount
          ? this.props.showInvoiceData.discount
          : '',
      discount_radio: this.props.showInvoiceData.discount_type
        ? this.props.showInvoiceData.discount_type
        : 'Percentage',
      show_discount_data: false,
      show_expense_data: false,
      expenses:
        this.props.showInvoiceData && this.props.showInvoiceData.expenses
          ? this.props.showInvoiceData.expenses.map((expense) => expense)
          : [],
      selected_expense: 0,
      selected_expense_display_string: '',
      selected_expense_label: '',
      total_expenses:
        this.props.showInvoiceData && this.props.showInvoiceData.expenses
          ? this.props.showInvoiceData.expenses.reduce((acc, expense) => {
              return acc + expense.expense_amount;
            }, 0)
          : 0,
      show_reconciliation_data: false,
      extra_hours: this.props.showInvoiceData ? this.props.showInvoiceData.missed_hours : 0,
      extra_invoice: this.props.showInvoiceData
        ? this.props.showInvoiceData.reconciliated_price
        : 0,
      extra_hours_resource_details: [],
      employee_actual_total_hours: [],
      show_delete_popup: false,
      update_action_performed: '',
      show_activity_log: false,
      checkedProjectWithNoClientInfo: [],
      show_add_client: false,
      client_name: [],
      client_address: [],
      client_project_pdf_name: [],
      project_id: [],
      client_per_project: [],
      allClientsInfo: [],
      show_clients_confirmation_dialog: false,
      AssociatedReceivers: [],
      show_percentage_error: false,
      show_add_associate_receiver: false,
      affiliate_receivers_ratio_type: 'percentage',
      show_create_affiliate_invoice_button:
        this.props.showInvoiceData && this.props.showInvoiceData.affiliate_receiver ? true : false,
      show_affiliate_invoice_create_view: false,
      is_current_pm: false,
      discount_added: false,
      expense_added: false,
      delete_discount: false,
      delete_expense: false,
      show_edit_warning_popup: false,
    };
  }

  componentDidMount = async () => {
    let {
      invoice_id,
      rates,
      projects,
      project,
      getParsedDataBetweenDates,
      end_date,
      invoices,
      getRates,
      getProjects,
      parsed_csv_data,
      getParsedData,
      showInvoiceData,
      filtered_projects,
    } = this.props;

    !projects && (await getProjects());
    const isProject = filtered_projects.filter(
      (filtered_project) => filtered_project.name === project,
    );
    this.setState({
      is_current_pm: isProject[0] ? true : false,
    });

    !rates && (await getRates());
    !parsed_csv_data && (await getParsedData());

    if (project) {
      let last_invoice_dates = calculatePreviousInvoiceDates(invoices, project, end_date);
      await getParsedDataBetweenDates(last_invoice_dates.start_date, last_invoice_dates.end_date);
    }
    this.setState({
      selected_invoice_id: invoice_id,
      extra_hours: showInvoiceData ? showInvoiceData.missed_hours : 0,
      extra_invoice: showInvoiceData ? showInvoiceData.reconciliated_price : 0,
    });
  };

  // isCurrentPM[0]?true:

  toggleDeletePopup() {
    this.setState({
      show_delete_popup: !this.state.show_delete_popup,
    });
  }

  handleOpenEditView = () => {
    if (Math.abs(this.props.showInvoiceData.reconciliated_price) > 0.0) {
      this.setState({
        show_edit_warning_popup: true,
      });
    } else {
      this.confirmOpenEditView();
    }
  };

  confirmOpenEditView = () => {
    this.setState({
      edit: true,
      show_edit_warning_popup: false,
    });
  };

  handleCancelEditView = () => {
    this.handleClearEdit(false);
  };

  handleClearEdit = (edit_mode = true) => {
    const { docId, invoices, resetShowInvoiceData } = this.props;
    const { employee_actual_total_hours } = this.props.showInvoiceData;

    const { show_reconciliation_data } = this.state;
    let invoice = invoices.filter((inv) => inv.docId === docId)[0];
    resetShowInvoiceData(invoice);

    const { showInvoiceData } = this.props;

    this.setState({
      edit: edit_mode,
      selected_invoice_id: invoice.invoice_id,
      show_discount_data: false,
      show_expense_data: false,
      extra_hours: showInvoiceData ? showInvoiceData.missed_hours : 0,
      extra_invoice: showInvoiceData ? showInvoiceData.reconciliated_price : 0,
      show_reconciliation_data: edit_mode && show_reconciliation_data,
      extra_hours_resource_details: [],
      selected_discount: showInvoiceData ? showInvoiceData.discount : 0,
      selected_discount_display_string:
        showInvoiceData && showInvoiceData.discount ? String(showInvoiceData.discount) : '',
      delete_discount: false,
      discount_added: false,
      expense_added: false,
      selected_expense: 0,
      selected_expense_display_string: '',
      total_expenses:
        showInvoiceData && showInvoiceData.expenses
          ? showInvoiceData.expenses.reduce((acc, expense) => {
              return acc + expense.expense_amount;
            }, 0)
          : 0,
      expenses: showInvoiceData.expenses,
      delete_expense: false,
      employee_actual_total_hours:
        edit_mode && show_reconciliation_data && employee_actual_total_hours
          ? employee_actual_total_hours
          : [],
    });
  };

  updateRateForReconciliationData = (new_rate, resource_name) => {
    let temp_state = [...this.state.employee_actual_total_hours];
    temp_state.forEach((resource) => {
      if (resource.employee === resource_name) {
        resource.rate = new_rate;
      }
    });
    let price_hour = calculateReconciledPrice(temp_state);
    this.setState({
      employee_actual_total_hours: temp_state,
      extra_hours: price_hour.hours, // missed hours
      extra_invoice: price_hour.price, // extra price
    });
  };

  handleChange = (e, value, resource, index) => {
    const new_value = Math.max(Number(0), Number(e.target.value));
    const new_value_display_string = e.target.value;
    let { showInvoiceData, changeInvoiceData, setInvoiceDetailData, resource_details } = this.props;
    let { expenses } = this.state;
    let invoice_data = JSON.parse(JSON.stringify(showInvoiceData));
    invoice_data.project_invoice_data = JSON.parse(invoice_data.project_invoice_data);
    if (value === 'expense') {
      expenses[index].expense_amount = new_value;
      expenses[index].expense_amount_display_string = new_value_display_string;
      this.setState({
        expenses,
        update_action_performed: 'Expense updated',
        expense_added: true,
      });
    } else if (value === 'discount') {
      // invoice_data.discount = new_value;
      this.setState({
        selected_discount: new_value,
        selected_discount_display_string: new_value_display_string,
        update_action_performed: 'Discount updated',
        discount_added: true,
        //show_discount_data : false
      });
    } else if (value === 'rate') {
      invoice_data.project_invoice_data[index].rate = new_value;
      invoice_data.project_invoice_data[index].rate_display_string = new_value_display_string;
      resource_details[index].rate = new_value;
      setInvoiceDetailData({
        resource_details: resource_details,
      });
      this.setState(
        {
          update_action_performed: 'Rate updated',
        },
        () => {
          if (this.state.show_reconciliation_data) {
            this.updateRateForReconciliationData(new_value, resource);
          }
        },
      );
    } else if (value === 'hours') {
      invoice_data.project_invoice_data.forEach((data) => {
        if (data.resource === resource) {
          data.weeks[index].total_week_hours = new_value;
          data.weeks[index].total_week_hours_display_string = new_value_display_string;
          data.total_hours = data.weeks.reduce(
            (a, b) => a + (parseFloat(b.total_week_hours) ? b.total_week_hours : 0),
            0,
          );
        }
      });
      this.setState({
        update_action_performed: 'Hours updated',
      });
    }
    invoice_data.project_invoice_data = JSON.stringify(invoice_data.project_invoice_data);
    changeInvoiceData(invoice_data);
  };

  handleDiscountRadioChange = async (e) => {
    await this.setState({
      discount_radio: e.target.value,
    });
    const event = { target: { value: this.state.selected_discount } };
    this.handleDiscountChange(event);
  };

  handleExpenseLabelChange = async (e) => {
    await this.setState({
      selected_expense_label: e.target.value,
    });
  };

  handleExpenseChange = async (e) => {
    let expense = e.target.value;
    expense = Math.max(Number(0), Number(expense));
    this.setState({ selected_expense: expense, selected_expense_display_string: e.target.value });
  };

  handleToggleAddDiscountView = () => {
    this.setState({
      show_add_discount_view: !this.state.show_add_discount_view,
      selected_discount: 0,
      selected_discount_display_string: '',
    });
  };

  handleToggleActivityLogView = () => {
    this.setState({
      show_activity_log: !this.state.show_activity_log,
    });
  };

  handleToggleAddExpenseView = () => {
    this.setState({
      show_add_expense_view: !this.state.show_add_expense_view,
      selected_expense: '',
      selected_expense_label: '',
    });
  };
  handleMissedHoursChange = (e, resource_name) => {
    let temp_state = [...this.state.employee_actual_total_hours];
    temp_state.forEach((resource) => {
      if (resource.employee === resource_name) {
        resource.missed_hours = parseFloat(e.target.value ? e.target.value : 0);
        resource.missed_hours_display_string = e.target.value;
      }
    });
    this.setState({
      employee_actual_total_hours: temp_state,
    });
    let price_hour = calculateReconciledPrice(temp_state);
    this.setState({
      extra_hours: price_hour.hours, // missed hours
      extra_invoice: price_hour.price, // extra price
    });
  };

  handleActualHoursChange = (e, resource) => {
    let { employee_actual_total_hours } = this.state;
    let { parsed_csv_data } = this.props;
    const value = e.target.value ? Math.max(Number(0), Number(e.target.value)) : '';
    let updated_hours_data = updateEmployeeActualHours(
      value,
      employee_actual_total_hours,
      resource,
      parsed_csv_data,
    );
    let price_hour = calculateReconciledPrice(updated_hours_data);

    // missed_hours = price_hour.hours;
    //reconciliated_price = price_hour.price;
    this.setState({
      extra_hours: price_hour.hours, // missed hours
      extra_invoice: price_hour.price, // extra price
      //show_reconciliation_data: true,
      employee_actual_total_hours: updated_hours_data,
    });
  };

  handleDeleteReconciliation = () => {
    this.setState({
      extra_hours: this.props.showInvoiceData ? this.props.showInvoiceData.missed_hours : 0, // missed hours
      extra_invoice: this.props.showInvoiceData
        ? this.props.showInvoiceData.reconciliated_price
        : 0, // extra price
      show_reconciliation_data: false,
      employee_actual_total_hours: [],
      update_action_performed: '',
    });
  };

  handleReconciliation = async () => {
    let {
      invoices,
      startLoader,
      getHoursBetweenDates,
      getParsedDataBetweenDates,
      resource_details,
      stopLoader,
      enqueueSnackbar,
      project,
      end_date,
      showInvoiceData,
      changeInvoiceData,
    } = this.props;

    startLoader('#61cc40').then(async (response1) => {
      if (response1) {
        let last_invoice_dates = calculatePreviousInvoiceDates(invoices, project, end_date);
        let previous_invoice = calculatePreviousInvoice(invoices, project, end_date); // needed for special case resources who are not on team deck

        //console.log(JSON.parse(previous_invoice.project_invoice_data));
        if (last_invoice_dates.start_date !== '' && previous_invoice !== undefined) {
          await getHoursBetweenDates(
            last_invoice_dates.start_date,
            last_invoice_dates.end_date,
          ).then((response) => {
            if (response) {
              let hours_data = getEmployeeHoursPerProjectInvoice(
                resource_details,
                response,
                JSON.parse(previous_invoice.project_invoice_data),
              );

              let price_hour = calculateReconciledPrice(hours_data);
              this.setState(
                {
                  extra_hours: price_hour.hours, // missed hours
                  extra_invoice: price_hour.price, // extra price
                  show_reconciliation_data: true,
                  employee_actual_total_hours: hours_data,
                  update_action_performed: 'Added Reconciliation',
                },
                () => {
                  showInvoiceData.employee_actual_total_hours = hours_data;
                  changeInvoiceData(showInvoiceData);
                },
              );
              stopLoader();
            } else {
              // response is null, show error message
              enqueueSnackbar('No hours found to be reconciled!', { variant: 'warning' });
              stopLoader();
            }
          });
          // await getParsedDataBetweenDates(
          //   last_invoice_dates.start_date,
          //   last_invoice_dates.end_date,
          // ).then(response => {
          //   if (response) {
          //     console.log("rd ", resource_details);
          //     console.log("pi ", previous_invoice.project_invoice_data);
          //     let hours_data = getEmployeesHoursPerProject(
          //       resource_details,
          //       response,
          //       JSON.parse(previous_invoice.project_invoice_data),
          //     );
          //     console.log("hours_data ", hours_data);
          //     let price_hour = calculateReconciledPrice(hours_data);
          //     console.log("price_hour ", price_hour);

          //     //console.log(parsed_csv_data_between_dates);

          //     //missed_hours = price_hour.hours;
          //     //reconciliated_price = price_hour.price;
          //     this.setState({
          //       extra_hours: price_hour.hours, // missed hours
          //       extra_invoice: price_hour.price, // extra price
          //       show_reconciliation_data: true,
          //       employee_actual_total_hours: hours_data,
          //       update_action_performed: 'Added Reconciliation',
          //     });
          //     stopLoader();
          //   }
          // });
        } else {
          enqueueSnackbar('Unable to get previous invoice data!', { variant: 'error' });
          stopLoader();
        }
      }
    });
  };

  handleSaveDraftInvoiceChanges = () => {
    let {
      stopLoader,
      startLoader,
      updateInvoice,
      showInvoiceData,
      refreshInvoices,
      enqueueSnackbar,
      changeInvoiceData,
    } = this.props;
    let {
      selected_invoice_id,
      discount_radio,
      selected_discount,
      show_reconciliation_data,
      selected_expense,
      extra_hours,
      extra_invoice,
      expenses,
      update_action_performed,
      discount_added,
      expense_added,
      delete_discount,
      delete_expense,
    } = this.state;

    let all_expenses_temp = expenses;
    let data = showInvoiceData ? showInvoiceData.project_invoice_data : {};
    let missed_hours = 0;
    let reconciliated_price = 0;

    missed_hours = extra_hours;
    reconciliated_price = extra_invoice;

    if (delete_discount) {
      showInvoiceData.discount = 0;
      changeInvoiceData(showInvoiceData).then((res) => {
        if (res) {
          enqueueSnackbar('Discount Removed!', { variant: 'success' });
        } else {
          enqueueSnackbar("Discount wasn't removed!", { variant: 'error' });
        }
      });
    }

    if (delete_expense) {
      showInvoiceData.expenses = expenses;
      changeInvoiceData(showInvoiceData).then((res) => {
        if (res) {
          enqueueSnackbar('Discount Removed!', { variant: 'success' });
        } else {
          enqueueSnackbar("Discount wasn't removed!", { variant: 'error' });
        }
      });
    }
    if (showInvoiceData) {
      startLoader('#61cc40')
        .then((response) => {
          if (response) {
            return updateInvoice(showInvoiceData.docId, {
              invoice_data: data,
              project_manager: showInvoiceData.project_manager,
              status: InvoiceState.DRAFT.name,
              invoice_id: selected_invoice_id,
              discount: selected_discount,
              discount_type: discount_radio,
              all_expenses: selected_expense,
              extra_hours_resource_details: [],
              expenses: all_expenses_temp,
              missed_hours: missed_hours,
              reconciliated_price: reconciliated_price,
              action_performed: update_action_performed,
            });
          }
        })
        .then(async (res) => {
          if (res) {
            showInvoiceData.missed_hours = missed_hours;
            showInvoiceData.reconciliated_price = reconciliated_price;
            if (discount_added) {
              showInvoiceData.discount = selected_discount;
              showInvoiceData.discount_type = discount_radio;
              await changeInvoiceData(showInvoiceData);
            }
            if (expense_added) {
              showInvoiceData.expenses = expenses;
            }
            await changeInvoiceData(showInvoiceData);
            enqueueSnackbar('Invoice Updated!', { variant: 'success' });
            refreshInvoices();
          } else {
            enqueueSnackbar('Invoice Update Failed!', { variant: 'error' });
            stopLoader();
          }
        });
    } else {
      enqueueSnackbar('Invoice Update Failed!');
      stopLoader();
    }
  };

  handleSaveInvoiceChanges = () => {
    let {
      stopLoader,
      startLoader,
      updateInvoice,
      showInvoiceData,
      refreshInvoices,
      enqueueSnackbar,
      resetShowInvoice,
    } = this.props;
    let {
      selected_invoice_id,
      discount_radio,
      selected_discount,
      show_reconciliation_data,
      selected_expense,
      extra_hours,
      extra_invoice,
      expenses,
      update_action_performed,
    } = this.state;

    let all_expenses_temp = expenses;

    let data = showInvoiceData.project_invoice_data;
    let missed_hours = 0;
    let reconciliated_price = 0;

    missed_hours = extra_hours;
    reconciliated_price = extra_invoice;

    update_action_performed = 'Invoice Approved';

    startLoader('#61cc40')
      .then((response) => {
        if (response) {
          return updateInvoice(showInvoiceData.docId, {
            invoice_data: data,
            project_manager: showInvoiceData.project_manager,
            status: InvoiceState.READY.name,
            invoice_id: selected_invoice_id,
            discount: selected_discount,
            discount_type: discount_radio,
            all_expenses: selected_expense,
            extra_hours_resource_details: [],
            expenses: all_expenses_temp,
            missed_hours: missed_hours,
            reconciliated_price: reconciliated_price,
            action_performed: update_action_performed,
          });
        }
      })
      .then((res) => {
        if (res) {
          ReactGA.event({
            category: 'Invoices',
            action: 'Changed invoice state to ' + InvoiceState.READY.name,
          });
          enqueueSnackbar('Invoice Updated!', { variant: 'success' });
          this.setState({
            show_create_affiliate_invoice_button: showInvoiceData.affiliate_receiver ? true : false,
          });
          resetShowInvoice();
          refreshInvoices();
        } else {
          enqueueSnackbar('Invoice Update Failed!', { variant: 'error' });
          stopLoader();
        }
      });
  };

  handleDownloadInvoicePDF = () => {
    let {
      docId,
      project,
      end_date,
      start_date,
      stopLoader,
      startLoader,
      enqueueSnackbar,
      downloadInvoicePDF,
    } = this.props;
    let { selected_template } = this.state;
    let name = project + '_' + start_date + '_' + end_date;

    startLoader('#61cc40')
      .then((response) => {
        if (response) {
          return downloadInvoicePDF(docId, name, selected_template);
        }
      })
      .then((response) => {
        if (response) {
          this.handleCloseChangeTemplateView();
          enqueueSnackbar('Invoice PDF Downloading!', { variant: 'success' });
        } else {
          enqueueSnackbar('Invoice download Failed!, Client And Receiver info may be missing!', {
            variant: 'error',
          });
        }
        stopLoader();
      })
      .catch(function (e) {
        console.log(e.message);
      });
  };

  handleDownloadInvoiceCSV = () => {
    let { docId, project, start_date, end_date, downloadInvoiceCSV, enqueueSnackbar } = this.props;
    let name = project + '_' + start_date + '_' + end_date;
    downloadInvoiceCSV(docId, name).then((response) => {
      if (response) {
        enqueueSnackbar('Invoice CSV Downloading!', { variant: 'success' });
      } else {
        enqueueSnackbar('Invoice CSV download Failed!', { variant: 'error' });
      }
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////
  handleDownloadCSV = () => {
    //console.log(this.state.checkhandleDownloadInvoiceCSVedValues)
    let {
      docId,
      project,
      start_date,
      end_date,
      downloadInvoiceCSV,
      enqueueSnackbar,
      startLoader,
      stopLoader,
      clients,
      no_clients_data,
      showInvoiceData,
    } = this.props;
    let downloadReport = true;

    if (no_clients_data === false) {
      let tempFilter = clients.map((item) => {
        return item.project_name;
      });
      let projects_with_no_clientInfo = containsValue(project, tempFilter)
        ? []
        : [{ docId: docId, project_name: project, projectId: showInvoiceData.projectId }];
      downloadReport = projects_with_no_clientInfo.length !== 0 ? false : true;
      this.setState({
        checkedProjectWithNoClientInfo: projects_with_no_clientInfo,
      });
      if (!downloadReport) {
        this.handleShowAddClient();
      }
    }
    if (downloadReport) {
      let name = project + '_' + start_date + '_' + end_date;
      startLoader('#61cc40')
        .then((response) => {
          if (response) {
            return downloadInvoiceCSV(docId, name);
          }
        })
        .then((response) => {
          if (response) {
            enqueueSnackbar('Invoices CSV Downloading!', { variant: 'success' });
          } else {
            enqueueSnackbar('Invoices CSV download Failed!', { variant: 'error' });
          }
          stopLoader();
        });
    }
  };

  handleShowAddClient = () => {
    this.setState({
      show_add_client: true,
    });
  };

  handleHideAddClient = () => {
    this.setState({
      show_add_client: false,
    });
  };

  handleHideAddClientWithX = () => {
    this.setState({
      show_add_client: false,
    });
  };

  handleClientChangesFinal = (e, type, index) => {
    let temp_array = [];
    let { allClientsInfo } = this.state;

    if (type === 'name') {
      temp_array = allClientsInfo;
      temp_array[index].client_name = e.target.value;
      this.setState({
        allClientsInfo: temp_array,
      });
    } else if (type === 'address') {
      temp_array = allClientsInfo;
      temp_array[index].client_address = e.target.value;
      this.setState({
        allClientsInfo: temp_array,
      });
    } else if (type === 'project_name') {
      temp_array = allClientsInfo;
      temp_array[index].client_project_pdf_name = e.target.value;
      this.setState({
        allClientsInfo: temp_array,
      });
    }
  };

  handleAddClientChanges = (e, type, id = '', index) => {
    let temp_array = [];
    let { showInvoiceData } = this.props;
    let { client_name, client_address, project_id, client_project_pdf_name } = this.state;

    if (type === 'name') {
      temp_array = client_name;
      temp_array[index] = e.target.value;
      let temp2 = project_id;
      temp2[index] = id;
      this.setState({
        client_name: temp_array,
        project_id: showInvoiceData ? [showInvoiceData.projectId] : id,
      });
    } else if (type === 'address') {
      temp_array = client_address;
      temp_array[index] = JSON.stringify(e.target.value.split('\n'));
      this.setState({
        client_address: temp_array,
      });
    } else if (type === 'project_id') {
      this.setState({
        project_id: [...this.state.project_id, e.target.value],
      });
    } else if (type === 'project_name') {
      temp_array = client_project_pdf_name;
      temp_array[index] = e.target.value;
      this.setState({
        client_project_pdf_name: temp_array,
      });
    }
  };

  handleAddClient = () => {
    let { client_name, client_address, client_project_pdf_name, checkedProjectWithNoClientInfo } =
      this.state;
    let allClientsInfo = getAllClientsInfo(
      checkedProjectWithNoClientInfo,
      client_name,
      client_address,
      client_project_pdf_name,
    );

    this.setState({
      allClientsInfo: allClientsInfo,
      show_add_client: false,
      show_clients_confirmation_dialog: true,
      client_name: [],
      client_address: [],
      client_project_pdf_name: [],
      project_id: [],
      checkedProjectWithNoClientInfo: [],
    });
  };

  handleShowClientConfirmation = () => {
    this.setState({
      show_clients_confirmation_dialog: true,
    });
  };

  handleHideClientConfirmation = () => {
    this.setState({
      show_clients_confirmation_dialog: false,
    });
  };

  saveClientsAndGenerateReport = () => {
    let { allClientsInfo } = this.state;
    let {
      docId,
      project,
      start_date,
      end_date,
      downloadInvoiceCSV,
      saveClientMultiple,
      enqueueSnackbar,
      startLoader,
      stopLoader,
    } = this.props;

    startLoader('#61cc40')
      .then((res) => {
        if (res) {
          return saveClientMultiple(allClientsInfo);
        }
      })
      .then((res) => {
        if (res) {
          let name = project + '_' + start_date + '_' + end_date;
          enqueueSnackbar('Client Info Saved! You can modify them from clients tab', {
            variant: 'success',
          });
          return downloadInvoiceCSV(docId, name);
        } else {
          enqueueSnackbar("Client Info wasn't saved!", { variant: 'error' });
          this.setState({
            show_clients_confirmation_dialog: false,
          });
          stopLoader();
        }
      })
      .then((response) => {
        if (response) {
          enqueueSnackbar('Client Info Saved! You can modify them from clients tab', {
            variant: 'success',
          });
          enqueueSnackbar('Invoice CSV Downloading!', { variant: 'success' });
        } else {
          enqueueSnackbar("Client Info wasn't saved!", { variant: 'error' });
          enqueueSnackbar('Invoice CSV download Failed!', { variant: 'error' });
        }
        this.setState({
          show_clients_confirmation_dialog: false,
        });
        stopLoader();
      });
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////

  handleChangeStatus = () => {
    let {
      showInvoiceData,
      updateInvoice,
      resetShowInvoice,
      enqueueSnackbar,
      startLoader,
      stopLoader,
      refreshInvoices,
    } = this.props;
    let { selected_status } = this.state;
    let {
      selected_invoice_id,
      discount_radio,
      selected_discount,
      show_reconciliation_data,
      selected_expense,
      extra_hours,
      extra_invoice,
      expenses,
      update_action_performed,
    } = this.state;

    let all_expenses_temp = expenses;

    let missed_hours = 0;
    let reconciliated_price = 0;
    missed_hours = extra_hours;
    reconciliated_price = extra_invoice;

    update_action_performed = 'Status Changed';

    startLoader('#61cc40')
      .then((response) => {
        if (response) {
          return updateInvoice(showInvoiceData.docId, {
            invoice_data: showInvoiceData.project_invoice_data,
            project_manager: showInvoiceData.project_manager,
            status: selected_status,
            ledger_amount: getTotalFromInvoiceData(
              JSON.parse(showInvoiceData.project_invoice_data),
            ).toString(),
            invoice_id: selected_invoice_id,
            discount: selected_discount,
            discount_type: discount_radio,
            all_expenses: selected_expense,
            extra_hours_resource_details: [],
            expenses: all_expenses_temp,
            missed_hours: missed_hours,
            reconciliated_price: reconciliated_price,
            action_performed: update_action_performed,
          });
        }
      })
      .then((res) => {
        if (res) {
          ReactGA.event({
            category: 'Invoices',
            action: 'Changed invoice state to ' + selected_status,
          });
          enqueueSnackbar('Invoice Updated!', { variant: 'success' });
          resetShowInvoice();
          refreshInvoices();
        } else {
          enqueueSnackbar('Invoice Update Failed!', { variant: 'error' });
          stopLoader();
        }
      });
  };

  handleOpenChangeStatusView = () => {
    this.setState({
      show_invoice_status_change_view: true,
    });
  };

  handleOpenCreateAffiliateInvoiceView = () => {
    let { receivers, projects, showInvoiceData } = this.props;
    //let {showInvoiceData} = this.state;
    let project = projects.filter((proj) => proj.id === showInvoiceData.projectId)[0];

    let tempObj = '';
    if (
      showInvoiceData &&
      !showInvoiceData.affiliate_receiver &&
      project &&
      project.affiliate_receiver
    ) {
      tempObj = project.affiliate_receiver;
      // affiliate info stored from project does not contain 'amount_type' attribute. but it is needed here
      for (var i in tempObj) {
        tempObj[i].amount_type = 'percentage';
      }
    }
    if (showInvoiceData && showInvoiceData.affiliate_receiver) {
      tempObj = showInvoiceData.affiliate_receiver;
    }

    let receiver = {};

    if (!showInvoiceData.receiver) {
      if (project && project.base_receiver && project.base_receiver.type === 'base')
        receiver = receivers.filter((rec) => rec.receiver_name === project.base_receiver.name)[0];
    } else {
      receiver = receivers.filter((rec) => rec.receiver_name === showInvoiceData.receiver)[0];
    }
    this.setState({
      show_affiliate_invoice_create_view: true,
      AssociatedReceivers: tempObj,
      show_add_associate_receiver: receiver ? receiver.enable_affiliate : false,
    });
  };

  handleHideCreateAffiliateInvoiceView = () => {
    let { projects, showInvoiceData } = this.props;
    let project = projects.filter((proj) => proj.id === showInvoiceData.projectId)[0];

    let tempObj = '';
    if (
      showInvoiceData &&
      !showInvoiceData.affiliate_receiver &&
      project &&
      project.affiliate_receiver
    ) {
      tempObj = project.affiliate_receiver;
      // affiliate info stored from project does not contain 'amount_type' attribute. but it is needed here
      for (var i in tempObj) {
        tempObj[i].amount_type = 'percentage';
      }
    }
    if (showInvoiceData && showInvoiceData.affiliate_receiver) {
      tempObj = showInvoiceData.affiliate_receiver;
    }
    this.setState({
      AssociatedReceivers: tempObj,
      show_affiliate_invoice_create_view: false,
    });
  };

  handleCloseChangeStatusView = () => {
    this.setState({
      show_invoice_status_change_view: false,
    });
  };

  handleChangeSelectedStatus = (e) => {
    this.setState({
      selected_status: e.target.value,
    });
  };

  // getStatuses = () => {
  //   let { roles, status } = this.props;
  //   let invoices_ability = Ability[roles[0]].invoices;

  //   if (invoices_ability.can_edit && invoices_ability.type === 'all') {
  //     return Object.keys(InvoiceState);
  //   } else if (invoices_ability.can_edit && invoices_ability.type === 'status') {
  //     let keys = Object.keys(InvoiceState);
  //     let status_index = keys.indexOf(status.name);
  //     return keys.splice(status_index + 1);
  //   } else {
  //     return [];
  //   }
  // };
  getStatuses = () => {
    let { roles, status } = this.props;

    if (
      hasPermission(roles, 'invoices', 'can_edit') &&
      roles.filter((role) => Ability[role].invoices.type === 'all').length
    ) {
      return Object.keys(InvoiceState);
    } else if (
      hasPermission(roles, 'invoices', 'can_edit') &&
      roles.filter((role) => Ability[role].invoices.type === 'status').length
    ) {
      let keys = Object.keys(InvoiceState);
      let status_index = keys.indexOf(status.name);
      return keys.splice(status_index + 1);
    } else {
      return [];
    }
  };
  handleShowAddResourceView = () => {
    this.setState({
      show_add_resource_view: true,
    });
  };

  handleHideAddResourceView = () => {
    this.setState({
      show_add_resource_view: false,
      selected_resource: '',
    });
  };

  handleChangeSelectedResource = (e) => {
    this.setState({
      selected_resource: e.target.value,
    });
  };

  handleAddResourceToInvoiceData = () => {
    let { showInvoiceData, changeInvoiceData, enqueueSnackbar, autoFillInvoice } = this.props;
    let data = JSON.parse(showInvoiceData.project_invoice_data);
    let newInvoiceData = this.addEmptyRowInInvoiceData(data);
    showInvoiceData.project_invoice_data = newInvoiceData;

    const { employee_actual_total_hours, selected_resource } = this.state;

    changeInvoiceData(showInvoiceData).then((res) => {
      if (res) {
        this.setState(
          {
            update_action_performed: 'Added Resource',
            employee_actual_total_hours: [
              ...employee_actual_total_hours,
              {
                employee: selected_resource,
                missed_hours_display_string: 0,
                missed_hours: 0,
                completed_hours: 0,
                rate: 0,
              },
            ],
          },
          () => {
            const temp_resource_details = JSON.parse(showInvoiceData.project_invoice_data);
            autoFillInvoice(temp_resource_details);
          },
        );
        enqueueSnackbar('Resource Added!', { variant: 'success' });
        this.handleHideAddResourceView();
      } else {
        enqueueSnackbar("Resource wasn't added!", { variant: 'error' });
      }
    });
  };

  handleAddDiscountToInvoiceData = () => {
    this.setState({
      show_discount_data: true,
      show_add_discount_view: false,
      update_action_performed: 'Added Discount',
      discount_added: true,
    });
  };

  handleAddExpenseToInvoiceData = () => {
    let { expenses, selected_expense, selected_expense_label } = this.state;
    let new_expenses = [];
    if (expenses) new_expenses = expenses;

    new_expenses.push({
      expense_label: selected_expense_label,
      expense_amount: selected_expense,
      expense_amount_display_string: String(selected_expense),
    });
    let total_expenses = new_expenses.reduce((acc, expense) => {
      return acc + expense.expense_amount;
    }, 0);
    this.setState({
      show_expense_data: true,
      show_add_expense_view: false,
      expenses: new_expenses,
      total_expenses: total_expenses,
      update_action_performed: 'Added Expense',
      expense_added: true,
    });
  };

  addEmptyRowInInvoiceData = (oldData) => {
    let newData = JSON.parse(JSON.stringify(oldData));
    let resourceData = JSON.parse(JSON.stringify(newData[0]));
    let { selected_resource } = this.state;

    resourceData.resource = selected_resource;
    resourceData.original_rate = 0;
    resourceData.total_hours = 0;
    resourceData.rate = 0;
    resourceData.rate_display_string = '0';
    resourceData.weeks = resourceData.weeks.map((week) => {
      week.original_total_week_hours = 0;
      week.total_week_hours = 0;
      week.total_week_hours_display_string = '0';
      return week;
    });
    newData.push(resourceData);

    newData = newData.sort(function (a, b) {
      if (a.resource < b.resource) {
        return -1;
      }
      if (a.resource > b.resource) {
        return 1;
      }
      return 0;
    });

    return JSON.stringify(newData);
  };

  handleDeleteRow = (index) => {
    let { showInvoiceData, changeInvoiceData, enqueueSnackbar } = this.props;

    if (JSON.parse(showInvoiceData.project_invoice_data).length === 1) {
      enqueueSnackbar("Can't remove all resources!", { variant: 'error' });
    } else {
      let newInvoiceData = this.removeIndexFromInvoiceData(
        JSON.parse(JSON.stringify(showInvoiceData.project_invoice_data)),
        index,
      );
      showInvoiceData.project_invoice_data = newInvoiceData;

      changeInvoiceData(showInvoiceData).then((res) => {
        if (res) {
          enqueueSnackbar('Resource Removed!', { variant: 'success' });
          this.setState({
            update_action_performed: 'Deleted Resource',
          });
        } else {
          enqueueSnackbar("Resource wasn't removed!", { variant: 'error' });
        }
      });
    }
  };

  handleDeleteDiscount = () => {
    // showInvoiceData.discount = 0;

    this.setState({
      selected_discount: 0,
      selected_discount_display_string: '',
      show_discount_data: true,
      update_action_performed: 'Deleted Discount',
      delete_discount: true,
      // show_discount_data: false,
    });

    // changeInvoiceData(showInvoiceData).then((res) => {
    //   if (res) {
    //     enqueueSnackbar('Discount Removed!', { variant: 'success' });
    //   } else {
    //     enqueueSnackbar("Discount wasn't removed!", { variant: 'error' });
    //   }
    // });
  };
  handleDeleteRowExpense = (index) => {
    let { showInvoiceData } = this.props;

    let newInvoiceData = this.removeIndexFromExpenses(showInvoiceData.expenses, index);
    let temp_total_expenses = newInvoiceData.reduce(
      (prev, current) => prev + current.expense_amount,
      0,
    );

    // showInvoiceData.expenses = newInvoiceData;
    this.setState({
      expenses: newInvoiceData,
      total_expenses: temp_total_expenses,
      update_action_performed: 'Deleted Expense',
      delete_expense: true,
    });

    //  enqueueSnackbar('Expense Removed!', { variant: 'success' });
    // changeInvoiceData(showInvoiceData).then((res) => {
    //   if (res) {
    //     enqueueSnackbar('Expense Removed!', { variant: 'success' });
    //   } else {
    //     enqueueSnackbar("Expense wasn't removed!", { variant: 'error' });
    //   }
    // });
  };

  removeIndexFromExpenses = (oldData, remove_index) => {
    let data = oldData;
    let newData = [];

    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (i !== remove_index) {
          newData.push(data[i]);
        }
      }
    }

    return newData;
  };

  removeIndexFromInvoiceData = (oldData, remove_index) => {
    let data = JSON.parse(oldData);
    let newData = [];

    for (let key in data) {
      if (parseInt(key) !== remove_index) {
        newData.push(data[key]);
      }
    }

    return JSON.stringify(newData);
  };

  handleShowEditInvoiceDatesView = () => {
    let { receivers, projects, showInvoiceData } = this.props;
    //let {showInvoiceData} = this.state;
    let project = projects.filter((proj) => proj.id === showInvoiceData.projectId)[0];

    let tempObj = '';
    if (
      showInvoiceData &&
      !showInvoiceData.affiliate_receiver &&
      project &&
      project.affiliate_receiver
    ) {
      tempObj = project.affiliate_receiver;
      // affiliate info stored from project does not contain 'amount_type' attribute. but it is needed here
      for (var i in tempObj) {
        tempObj[i].amount_type = 'percentage';
      }
    }
    if (showInvoiceData && showInvoiceData.affiliate_receiver) {
      tempObj = showInvoiceData.affiliate_receiver;
    }

    let receiver = {};

    if (!showInvoiceData.receiver) {
      if (project && project.base_receiver && project.base_receiver.type === 'base')
        receiver = receivers.filter((rec) => rec.receiver_name === project.base_receiver.name)[0];
    } else {
      receiver = receivers.filter((rec) => rec.receiver_name === showInvoiceData.receiver)[0];
    }
    this.setState({
      show_edit_invoice_dates_view: true,
      AssociatedReceivers: tempObj,
      show_add_associate_receiver: receiver ? receiver.enable_affiliate : false,
    });
  };

  handleHideEditInvoiceDatesView = () => {
    let { projects, showInvoiceData } = this.props;
    let project = projects.filter((proj) => proj.id === showInvoiceData.projectId)[0];

    let tempObj = '';
    if (
      showInvoiceData &&
      !showInvoiceData.affiliate_receiver &&
      project &&
      project.affiliate_receiver
    ) {
      tempObj = project.affiliate_receiver;
      // affiliate info stored from project does not contain 'amount_type' attribute. but it is needed here
      for (var i in tempObj) {
        tempObj[i].amount_type = 'percentage';
      }
    }
    if (showInvoiceData && showInvoiceData.affiliate_receiver) {
      tempObj = showInvoiceData.affiliate_receiver;
    }
    this.setState({
      show_edit_invoice_dates_view: false,
      AssociatedReceivers: tempObj,
      show_add_associate_receiver: false,
      affiliate_receivers_ratio_type: 'percentage',
    });
  };

  handleAssociateReceiver = (e, index, type) => {
    let { AssociatedReceivers, affiliate_receivers_ratio_type } = this.state;
    let temp = AssociatedReceivers;
    if (type === 'name') {
      temp[index].name = e.target.value;
      temp[index].amount_type = affiliate_receivers_ratio_type;
    } else if (type === 'percentage') {
      temp[index].percentage = e.target.value;
    }
    this.setState({
      AssociatedReceivers: temp,
    });
  };

  handleChangeRatioType = (e) => {
    let { AssociatedReceivers } = this.state;
    let temp = AssociatedReceivers;
    for (var i in temp) {
      temp[i].amount_type = e.target.value;
    }
    this.setState({
      AssociatedReceivers: temp,
      affiliate_receivers_ratio_type: e.target.value,
    });
  };

  handleRemoveAssociateReceiver = (index) => {
    let { AssociatedReceivers } = this.state;
    let temp = AssociatedReceivers;
    temp.splice(index, 1);
    this.setState({
      AssociatedReceivers: temp,
    });
  };

  handleAddSubReceiverRow = () => {
    let { AssociatedReceivers, affiliate_receivers_ratio_type } = this.state;
    let temp = [];
    if (AssociatedReceivers !== '') {
      temp = AssociatedReceivers;
    }
    temp.push({
      type: 'affiliate',
      name: 'dummy_value',
      percentage: -1,
      amount_type: affiliate_receivers_ratio_type,
    });
    this.setState({
      AssociatedReceivers: temp,
    });
  };

  handleChangeReciever = (e) => {
    let { receivers } = this.props;

    this.setState({
      selected_receiver: e.target.value,
    });
    let flag = false;
    for (var i in receivers) {
      if (receivers[i].receiver_name === e.target.value && receivers[i].enable_affiliate) {
        flag = true;
        this.setState({
          show_add_associate_receiver: true,
        });
      }
    }
    if (!flag) {
      this.setState({
        show_add_associate_receiver: false,
      });
    }
  };

  handleChangeSubmittedDate = (e) => {
    this.setState({
      selected_submitted_date: Moment(e.target.value).format('YYYY-MM-DD'),
    });
  };

  handleChangeIssuedDate = (e) => {
    this.setState({
      selected_issued_date: Moment(e.target.value).format('YYYY-MM-DD'),
    });
  };

  handleChangeReceivedDate = (e) => {
    this.setState({
      selected_received_date: Moment(e.target.value).format('YYYY-MM-DD'),
    });
  };

  handleChangeInvoiceId = (e) => {
    this.setState({
      selected_invoice_id: e.target.value,
    });
  };

  handleUpdateInvoiceDetails = () => {
    let {
      docId,
      receiver,
      stopLoader,
      invoice_id,
      startLoader,
      issued_date,
      received_date,
      submitted_date,
      enqueueSnackbar,
      refreshInvoices,
      resetShowInvoice,
      updateInvoiceDates,
    } = this.props;
    let {
      selected_receiver,
      selected_invoice_id,
      selected_issued_date,
      selected_received_date,
      selected_submitted_date,
      AssociatedReceivers,
      affiliate_receivers_ratio_type,
      discount_radio,
    } = this.state;

    if (submitted_date && selected_submitted_date === '') {
      selected_submitted_date = submitted_date;
    }

    if (receiver && selected_receiver === '') {
      selected_receiver = receiver;
    }

    if (received_date && selected_received_date === '') {
      selected_received_date = received_date;
    }

    if (issued_date && selected_issued_date === '') {
      selected_issued_date = issued_date;
    }

    if (invoice_id && selected_invoice_id === '') {
      selected_invoice_id = invoice_id;
    }
    let allow_flag = true;

    if (
      AssociatedReceivers &&
      AssociatedReceivers.length !== 0 &&
      affiliate_receivers_ratio_type === 'percentage'
    ) {
      let total_percentage = AssociatedReceivers.reduce((a, b) => +a + +b.percentage, 0);
      if (total_percentage > 100) {
        // total must not exceed hundred
        allow_flag = false;
        this.setState({
          show_percentage_error: true,
        });
      }
    }

    if (allow_flag) {
      startLoader('#61cc40')
        .then((response) => {
          if (response) {
            if (AssociatedReceivers) {
              return updateInvoiceDates(docId, {
                receiver: selected_receiver,
                issued_date: selected_issued_date,
                received_date: selected_received_date,
                submitted_date: selected_submitted_date,
                invoice_id: selected_invoice_id,
                affiliate_receiver: AssociatedReceivers ? AssociatedReceivers : '',
                discount_type: discount_radio,
              });
            } else {
              return updateInvoiceDates(docId, {
                receiver: selected_receiver,
                issued_date: selected_issued_date,
                received_date: selected_received_date,
                submitted_date: selected_submitted_date,
                invoice_id: selected_invoice_id,
                discount_type: discount_radio,
              });
            }
          }
        })
        .then((response) => {
          if (response) {
            enqueueSnackbar('Invoice Updated!', { variant: 'success' });
            resetShowInvoice();
            refreshInvoices();
          } else {
            enqueueSnackbar('Invoice Update Failed!', { variant: 'error' });
            stopLoader();
          }
        });
    }
  };

  handleGenerateAffiliateInvoices = () => {
    let {
      showInvoiceData,
      saveInvoiceAffiliates,
      startLoader,
      stopLoader,
      discount,
      total_invoice,
      resetShowInvoice,
      refreshInvoices,
      docId,
      updateInvoiceDates,
      submitted_date,
      receiver,
      received_date,
      issued_date,
      invoice_id,
    } = this.props;
    let {
      selected_receiver,
      selected_invoice_id,
      selected_issued_date,
      selected_received_date,
      selected_submitted_date,
      AssociatedReceivers,
      total_expenses,
      extra_invoice,
      affiliate_receivers_ratio_type,
    } = this.state;
    const discount_type = showInvoiceData.discount_type;
    if (submitted_date && selected_submitted_date === '') {
      selected_submitted_date = submitted_date;
    }

    if (receiver && selected_receiver === '') {
      selected_receiver = receiver;
    }

    if (received_date && selected_received_date === '') {
      selected_received_date = received_date;
    }

    if (issued_date && selected_issued_date === '') {
      selected_issued_date = issued_date;
    }

    if (invoice_id && selected_invoice_id === '') {
      selected_invoice_id = invoice_id;
    }

    formatFloat(total_invoice + total_expenses - total_invoice * (discount / 100));

    if (showInvoiceData && (AssociatedReceivers || showInvoiceData.affiliate_receiver)) {
      total_invoice = getTotalFromInvoiceData(JSON.parse(showInvoiceData.project_invoice_data));
      let total_amount = total_invoice;
      if (discount && discount > 0) {
        total_amount -=
          discount_type === 'Percentage' || discount_type === 'percentage'
            ? total_invoice * (discount / 100)
            : discount;
      }
      if (total_expenses && total_expenses > 0) {
        total_amount += total_expenses;
      }
      if (extra_invoice > 0) {
        // subtract reconciliation amount too
        total_amount -= extra_invoice;
      }
      let allow_flag = true;

      if (
        AssociatedReceivers &&
        AssociatedReceivers.length !== 0 &&
        affiliate_receivers_ratio_type === 'percentage'
      ) {
        let total_percentage = AssociatedReceivers.reduce((a, b) => +a + +b.percentage, 0);
        if (total_percentage > 100) {
          // total must not exceed hundred
          allow_flag = false;
          this.setState({
            show_percentage_error: true,
          });
        }
      }
      if (allow_flag) {
        let invoice_data_for_affliliate = prepareInvoiceDataForAffiliate(
          showInvoiceData,
          AssociatedReceivers ? AssociatedReceivers : showInvoiceData.affiliate_receiver,
          total_amount,
        );

        let payload = [];
        for (let i in invoice_data_for_affliliate) {
          let data = {
            project_name: showInvoiceData.project_name,
            project_manager: showInvoiceData.project_manager,
            status: 'DRAFT',
            receiver: AssociatedReceivers[i].name,
            invoice_data: showInvoiceData.project_invoice_data,
            start_date: showInvoiceData.start_date,
            end_date: showInvoiceData.end_date,
            type: showInvoiceData.type,
            is_affiliate: true,
            invoice_data_for_affliliate: invoice_data_for_affliliate[i],
          };
          payload.push(data);
        }

        startLoader('#61cc40')
          .then((response) => {
            if (response) {
              return updateInvoiceDates(docId, {
                receiver: selected_receiver,
                issued_date: selected_issued_date,
                received_date: selected_received_date,
                submitted_date: selected_submitted_date,
                invoice_id: selected_invoice_id,
                affiliate_exists: true,
                affiliate_receiver: AssociatedReceivers ? AssociatedReceivers : '',
              });
            }
          })
          .then((response) => {
            if (response) {
              return saveInvoiceAffiliates(payload);
            } else {
              this.props.enqueueSnackbar('Invoice Update Failed!', { variant: 'error' });
              stopLoader();
            }
          })
          .then((data_rec) => {
            if (data_rec.saved) {
              this.props.enqueueSnackbar(data_rec.message, { variant: 'success' });
              this.setState({
                show_affiliate_invoice_create_view: false,
              });
              resetShowInvoice();
              refreshInvoices();
            } else {
              this.props.enqueueSnackbar(data_rec.message, { variant: 'error' });
            }
            stopLoader();
          });
      }
    }
  };

  removeInvoice = () => {
    let {
      docId,
      stopLoader,
      startLoader,
      enqueueSnackbar,
      refreshInvoices,
      resetShowInvoice,
      deleteInvoice,
      showInvoiceData,
      invoices,
      updateInvoiceDates,
      submitted_date,
      receiver,
      received_date,
      issued_date,
      invoice_id,
    } = this.props;
    let {
      selected_receiver,
      selected_invoice_id,
      selected_issued_date,
      selected_received_date,
      selected_submitted_date,
    } = this.state;
    if (submitted_date && selected_submitted_date === '') {
      selected_submitted_date = submitted_date;
    }

    if (receiver && selected_receiver === '') {
      selected_receiver = receiver;
    }

    if (received_date && selected_received_date === '') {
      selected_received_date = received_date;
    }

    if (issued_date && selected_issued_date === '') {
      selected_issued_date = issued_date;
    }

    if (invoice_id && selected_invoice_id === '') {
      selected_invoice_id = invoice_id;
    }
    let base_invoice = {};
    let affiliate_receiver = [];
    let affiliate_exists = true;
    if (showInvoiceData.is_affiliate) {
      base_invoice = invoices.filter(
        (inv) => inv.docId === showInvoiceData.invoice_data_for_affliliate.base_docID,
      )[0];
      affiliate_receiver = base_invoice ? base_invoice.affiliate_receiver : [];
      for (var i in affiliate_receiver) {
        if (affiliate_receiver[i].name === showInvoiceData.receiver) {
          affiliate_receiver.splice(i, 1);
        }
      }
    }
    if (base_invoice && affiliate_receiver.length === 0) {
      affiliate_exists = false;
    }

    if (showInvoiceData.is_affiliate && base_invoice) {
      startLoader('#61cc40')
        .then((response) => {
          if (response) {
            this.toggleDeletePopup();
            return updateInvoiceDates(base_invoice.docId, {
              receiver: selected_receiver,
              issued_date: selected_issued_date,
              received_date: selected_received_date,
              submitted_date: selected_submitted_date,
              invoice_id: selected_invoice_id,
              affiliate_exists: affiliate_exists,
              affiliate_receiver: affiliate_receiver,
            });
          }
        })
        .then((response) => {
          if (response) {
            return deleteInvoice(docId);
          } else {
            this.props.enqueueSnackbar('Base Invoice Update Failed!', { variant: 'error' });
            stopLoader();
          }
        })
        .then((response) => {
          if (response) {
            enqueueSnackbar('Invoice Deleted!', { variant: 'success' });
            resetShowInvoice();
            refreshInvoices();
          } else {
            enqueueSnackbar('Invoice Deletion Failed!', { variant: 'error' });
            stopLoader();
          }
        });
    } else {
      startLoader('#61cc40')
        .then((response) => {
          if (response) {
            this.toggleDeletePopup();
            return updateInvoiceDates(base_invoice.docId, {
              receiver: base_invoice.receiver,
              affiliate_exists: affiliate_exists,
              affiliate_receiver: affiliate_receiver,
            });
          }
        })
        .then((response) => {
          if (response) {
            return deleteInvoice(docId);
          }
        })
        .then((response) => {
          if (response) {
            enqueueSnackbar('Invoice Deleted!', { variant: 'success' });
            resetShowInvoice();
            refreshInvoices();
          } else {
            enqueueSnackbar('Invoice Deletion Failed!', { variant: 'error' });
            stopLoader();
          }
        });
    }
  };

  handleChangeSelectedTemplate = (e) => {
    this.setState({
      selected_template: e.target.value,
    });
  };

  handleCloseChangeTemplateView = () => {
    this.setState({
      selected_template: '',
      show_invoice_template_select_view: false,
    });
  };

  handleShowChangeTemplateView = () => {
    this.setState({
      show_invoice_template_select_view: true,
    });
  };

  handleDiscountChange = (event) => {
    let discount = event.target.value;
    let discount_display_string = event.target.value;
    let min = 0;
    let max = 100;
    if (discount === '') {
      discount = 0;
    } else if (this.state.discount_radio === 'Percentage') {
      discount = Math.max(Number(min), Math.min(Number(max), Number(discount)));
      discount_display_string = String(discount);
    } else {
      discount = Math.max(Number(min), Number(discount));
      discount_display_string = String(discount);
    }
    this.setState({
      selected_discount: discount,
      selected_discount_display_string: discount_display_string,
    });
  };

  getActualHour = (resource_detail, total_hours) => {
    let actual_total_hours = formatFloat(total_hours);
    this.state.employee_actual_total_hours.forEach((resource) => {
      if (resource.employee === resource_detail.resource)
        actual_total_hours = resource.completed_hours;
      //actual_total_hours = formatFloat(resource_detail.total_hours - resource.missed_hours); // this statement is subtracting new invoice total hours from last invoice total hours
    });
    return actual_total_hours;
  };
  getMissedHour = (resource_detail) => {
    let missed_total_hours = '';
    this.state.employee_actual_total_hours.forEach((resource) => {
      if (resource.employee === resource_detail.resource) {
        missed_total_hours =
          'missed_hours_display_string' in resource
            ? resource.missed_hours_display_string
            : resource.missed_hours; // this statement is subtracting new invoice total hours from last invoice total hours
      }
    });
    return missed_total_hours;
  };

  render() {
    let {
      receivers,
      roles,
      status,
      project,
      editable,
      receiver,
      end_date,
      resources,
      start_date,
      invoice_id,
      issued_date,
      total_invoice,
      received_date,
      submitted_date,
      project_manager,
      parsed_csv_data,
      showInvoiceData,
      resource_details,
      showDetailHourDistribution,
      discount,
      from_postpaid_invoice_creation,
    } = this.props;
    let {
      extra_hours_resource_details,
      edit,
      selected_status,
      selected_receiver,
      selected_resource,
      selected_template,
      selected_invoice_id,
      selected_issued_date,
      show_add_resource_view,
      selected_received_date,
      selected_submitted_date,
      show_edit_invoice_dates_view,
      show_invoice_status_change_view,
      show_invoice_template_select_view,
      selected_discount,
      selected_discount_display_string,
      discount_radio,
      show_add_discount_view,
      show_discount_data,
      selected_expense,
      selected_expense_display_string,
      selected_expense_label,
      show_add_expense_view,
      show_expense_data,
      total_expenses,
      expenses,
      show_reconciliation_data,
      extra_hours,
      extra_invoice,
      show_delete_popup,
      show_activity_log,
      show_add_client,
      checkedProjectWithNoClientInfo,
      client_name,
      client_address,
      client_project_pdf_name,
      allClientsInfo,
      show_clients_confirmation_dialog,
      AssociatedReceivers,
      show_add_associate_receiver,
      affiliate_receivers_ratio_type,
      show_percentage_error,
      show_create_affiliate_invoice_button,
      show_affiliate_invoice_create_view,
      is_current_pm,
      delete_discount,
      show_edit_warning_popup,
    } = this.state;
    const discount_type = showInvoiceData.discount_type;
    let disabled_create_button =
      client_name.length === 0 ||
      client_address.length === 0 ||
      client_project_pdf_name.length === 0;

    let total_hours = 0;
    let newInvoiceData;
    let statuses = this.getStatuses();
    let invoices_ability = Ability[roles[0]].invoices;

    if (submitted_date && selected_submitted_date === '') {
      selected_submitted_date = submitted_date;
    }

    if (receiver && selected_receiver === '') {
      selected_receiver = receiver;
    }

    if (received_date && selected_received_date === '') {
      selected_received_date = received_date;
    }

    if (issued_date && selected_issued_date === '') {
      selected_issued_date = issued_date;
    }

    if (showInvoiceData) {
      newInvoiceData = JSON.parse(showInvoiceData.project_invoice_data);
      total_invoice = getTotalFromInvoiceData(newInvoiceData);
      total_hours = getTotalHoursFromInvoiceData(newInvoiceData);
      resource_details = newInvoiceData;
    }

    if (editable) {
      status = InvoiceState[showInvoiceData.status];
    }

    let edit_invoice_enteries =
      status.name === InvoiceState.DRAFT.name &&
      hasPermission(roles, 'invoices', 'can_edit') &&
      roles.filter(
        (role) =>
          Ability[role].invoices.type === 'all' || Ability[role].invoices.type === 'project',
      ).length;
    let edit_invoice_status =
      status.name !== InvoiceState.DRAFT.name &&
      hasPermission(roles, 'invoices', 'can_edit') &&
      roles.filter(
        (role) => Ability[role].invoices.type === 'all' || Ability[role].invoices.type === 'status',
      ).length;
    let show_invoice_dates_button =
      (hasPermission(roles, 'invoices', 'can_edit_dates') ||
        hasPermission(roles, 'invoices', 'can_edit_invoice_id')) &&
      status.name !== InvoiceState.DRAFT.name;
    let disabled_update_button =
      !Moment(selected_issued_date).isValid() ||
      !Moment(selected_received_date).isValid() ||
      !Moment(selected_submitted_date).isValid() ||
      selected_receiver === '';
    //selected_invoice_id === '';
    return (
      <div style={style.ProjectDetailInvoiceContainer}>
        <FixedProjectHeader
          edit={edit}
          editable={editable}
          handleOpenEditView={this.handleOpenEditView}
          edit_invoice_status={edit_invoice_status}
          status={status}
          project={project}
          receiver={receiver}
          end_date={end_date}
          invoice_id={invoice_id}
          invoice_type={showInvoiceData && showInvoiceData.type ? showInvoiceData.type : ''}
          start_date={start_date}
          project_manager={project_manager}
          is_current_pm={is_current_pm}
          toggleDeletePopup={this.toggleDeletePopup.bind(this)}
          handleSaveInvoiceChanges={this.handleSaveInvoiceChanges}
          can_delete={hasPermission(roles, 'invoices', 'can_delete_invoice_id')}
          handleCancelEditView={this.handleCancelEditView}
          handleClearEdit={this.handleClearEdit}
          handleSaveDraftInvoiceChanges={this.handleSaveDraftInvoiceChanges}
          handleToggleAddDiscountView={this.handleToggleAddDiscountView}
          handleToggleAddExpenseView={this.handleToggleAddExpenseView}
          handleReconciliation={this.handleReconciliation}
          show_reconciliation_data={show_reconciliation_data}
          handleShowAddResourceView={this.handleShowAddResourceView}
          handleShowChangeTemplateView={this.handleShowChangeTemplateView}
          handleDownloadInvoicePDF={this.handleDownloadInvoicePDF}
          handleOpenChangeStatusView={this.handleOpenChangeStatusView}
          handleDownloadInvoiceCSV={this.handleDownloadInvoiceCSV}
          show_invoice_dates_button={show_invoice_dates_button}
          edit_invoice_enteries={edit_invoice_enteries}
          handleShowEditInvoiceDatesView={this.handleShowEditInvoiceDatesView}
          handleDownloadCSV={this.handleDownloadCSV}
          show_create_affiliate_invoice_button={show_create_affiliate_invoice_button}
          handleOpenCreateAffiliateInvoiceView={this.handleOpenCreateAffiliateInvoiceView}
          is_affiliate_invoice={showInvoiceData.is_affiliate ? true : false}
          affiliate_exists={showInvoiceData.affiliate_exists ? true : false}
        />
        {/*<ButtonRow
          edit={edit}
          editable={editable}
          invoice_id={selected_invoice_id}
          handleClearEdit={this.handleClearEdit}
          edit_invoice_status={edit_invoice_status}
          handleOpenEditView={this.handleOpenEditView}
          edit_invoice_enteries={edit_invoice_enteries}
          handleCancelEditView={this.handleCancelEditView}
          handleChangeInvoiceId={this.handleChangeInvoiceId}
          show_invoice_dates_button={show_invoice_dates_button}
          handleDownloadInvoicePDF={this.handleDownloadInvoicePDF}
          handleDownloadInvoiceCSV={this.handleDownloadInvoiceCSV}
          handleSaveInvoiceChanges={this.handleSaveInvoiceChanges}
          handleShowAddResourceView={this.handleShowAddResourceView}
          edit_invoice_status_disabled={edit_invoice_status_disabled}
          handleOpenChangeStatusView={this.handleOpenChangeStatusView}
          handleShowChangeTemplateView={this.handleShowChangeTemplateView}
          handleSaveDraftInvoiceChanges={this.handleSaveDraftInvoiceChanges}
          handleShowEditInvoiceDatesView={this.handleShowEditInvoiceDatesView}
          handleToggleAddDiscountView={this.handleToggleAddDiscountView}
          handleToggleAddExpenseView={this.handleToggleAddExpenseView}
          handleReconciliation={this.handleReconciliation}
          show_reconciliation_data={show_reconciliation_data}
          toggleDeletePopup={this.toggleDeletePopup.bind(this)}
          status={status}
          can_delete={invoices_ability.can_delete_invoice_id}
          update_action_performed={update_action_performed}
        />*/}
        <InvoiceDetailsTableView
          edit={edit}
          editable={editable}
          newInvoiceData={newInvoiceData}
          handleChange={this.handleChange}
          handleActualHoursChange={this.handleActualHoursChange}
          handleMissedHoursChange={this.handleMissedHoursChange}
          showInvoiceData={showInvoiceData}
          parsed_csv_data={parsed_csv_data}
          resource_details={resource_details}
          handleDeleteRow={this.handleDeleteRow}
          showDetailHourDistribution={showDetailHourDistribution}
          show_discount_data={show_discount_data}
          selected_discount={selected_discount}
          discount_radio={discount_radio}
          show_reconciliation_data={show_reconciliation_data}
          extra_hours_resource_details={extra_hours_resource_details}
          getActualHour={this.getActualHour}
          getMissedHour={this.getMissedHour}
          totalWeeks={resource_details ? resource_details[0].weeks : 0}
          handleToggleActivityLogView={this.handleToggleActivityLogView}
          show_activity_log={show_activity_log}
          total_invoice={total_invoice}
          total_hours={total_hours}
          handleDeleteReconciliation={this.handleDeleteReconciliation}
        />
        {/*(show_expense_data) || (expenses!== null && expenses.length > 0) ?
          <ExpensesTable
          expenses={expenses}
          edit={edit}
          editable={editable}
          newInvoiceData={newInvoiceData}
          handleChange={this.handleChange}
          showInvoiceData={showInvoiceData}
          parsed_csv_data={parsed_csv_data}
          resource_details={resource_details}
          handleDeleteRowExpense={this.handleDeleteRowExpense}
          showDetailHourDistribution={showDetailHourDistribution}
          show_discount_data={show_discount_data}
          selected_discount={selected_discount}
          discount_radio={discount_radio}
          show_reconciliation_data={show_reconciliation_data}
          extra_hours_resource_details={extra_hours_resource_details}
          getActualHour={this.getActualHour}
          />
          : null*/}
        {/*<div style={style.TabColumns}>

        { !edit && showInvoiceData && showInvoiceData.events != null ? (
        <div>
            <button
            className="basic-invoice-button hours-management-header-edit-invoicing"
            onClick={this.handleToggleActivityLogView}
          >
          Activity Log { show_activity_log ?
            <> - </>:
            <> + </>}
          </button>
        </div>
        ) : null }

        <div style={style.Row} style={{ marginLeft: 'auto'}}>

          { showInvoiceData ?
            <div style={style.TotalHourContainer}>
              <label style={style.TotalLabel}>TOTAL HOURS</label>
              <p style={style.TotalLabel}>{formatFloat(total_hours)}</p>
            </div> : null
          }
          <div style={style.TotalContainer}>
            <label style={style.TotalLabel}>TOTAL COST</label>
            <p style={style.TotalLabel}>${formatFloat(total_invoice)}</p>
          </div>

        </div>
        </div>*/}

        <div style={style.TabColumns}>
          {!edit && show_activity_log && showInvoiceData && showInvoiceData.events != null ? (
            <div style={{ display: 'flex' }}>
              <ActivityLog events={showInvoiceData.events} />
            </div>
          ) : null}
          {!from_postpaid_invoice_creation && (show_discount_data || show_expense_data) ? (
            <div style={{ display: 'flex', marginLeft: 'auto', paddingRight: '7vh' }}>
              <Table style={style.Table}>
                <TableBody style={style.Body}>
                  {show_discount_data ? (
                    <DiscountTable
                      discount={selected_discount}
                      discount_display_string={selected_discount_display_string}
                      total_invoice={total_invoice}
                      selected_discount={selected_discount}
                      discount_type={discount_radio}
                      edit={edit}
                      editable={editable}
                      newInvoiceData={newInvoiceData}
                      handleChange={this.handleChange}
                      handleDeleteDiscount={this.handleDeleteDiscount}
                      showInvoiceData={showInvoiceData}
                      delete_discount={delete_discount}
                    />
                  ) : null}
                  {show_expense_data ? (
                    <ExpensesTable
                      expenses={expenses}
                      edit={edit}
                      editable={editable}
                      newInvoiceData={newInvoiceData}
                      handleChange={this.handleChange}
                      showInvoiceData={showInvoiceData}
                      parsed_csv_data={parsed_csv_data}
                      resource_details={resource_details}
                      handleDeleteRowExpense={this.handleDeleteRowExpense}
                      showDetailHourDistribution={showDetailHourDistribution}
                      show_discount_data={show_discount_data}
                      selected_discount={selected_discount}
                      discount_radio={discount_radio}
                      show_reconciliation_data={show_reconciliation_data}
                      extra_hours_resource_details={extra_hours_resource_details}
                      getActualHour={this.getActualHour}
                    />
                  ) : null}
                </TableBody>
              </Table>
            </div>
          ) : null}
          {!from_postpaid_invoice_creation &&
          (show_discount_data === false ||
            (show_expense_data === false && expenses !== null && expenses.length > 0)) ? (
            <div style={{ display: 'flex', marginLeft: 'auto', paddingRight: '7vh' }}>
              <Table style={style.Table}>
                <TableBody style={style.Body}>
                  {show_discount_data === false && (discount > 0 || discount === '') ? (
                    <DiscountTable
                      discount={selected_discount}
                      discount_display_string={selected_discount_display_string}
                      total_invoice={total_invoice}
                      discount_type={discount_type}
                      selected_discount={selected_discount}
                      edit={edit}
                      editable={editable}
                      newInvoiceData={newInvoiceData}
                      handleChange={this.handleChange}
                      handleDeleteDiscount={this.handleDeleteDiscount}
                      showInvoiceData={showInvoiceData}
                      delete_discount={delete_discount}
                    />
                  ) : null}
                  {show_expense_data === false && expenses !== null && expenses.length > 0 ? (
                    <ExpensesTable
                      expenses={expenses}
                      edit={edit}
                      editable={editable}
                      newInvoiceData={newInvoiceData}
                      handleChange={this.handleChange}
                      showInvoiceData={showInvoiceData}
                      parsed_csv_data={parsed_csv_data}
                      resource_details={resource_details}
                      handleDeleteRowExpense={this.handleDeleteRowExpense}
                      showDetailHourDistribution={showDetailHourDistribution}
                      show_discount_data={show_discount_data}
                      selected_discount={selected_discount}
                      discount_radio={discount_radio}
                      show_reconciliation_data={show_reconciliation_data}
                      extra_hours_resource_details={extra_hours_resource_details}
                      getActualHour={this.getActualHour}
                    />
                  ) : null}
                </TableBody>
              </Table>
            </div>
          ) : null}

          {/*show_discount_data ?
          <div style={style.Row}>
            <div style={style.TotalHourContainer}>
              <label style={style.TotalLabel}>TOTAL DISCOUNT</label>
              { discount_radio === 'Percentage' ?
                <p style={style.TotalLabel}>{selected_discount}%</p> :
                <p style={style.TotalLabel}>${selected_discount}</p> }
            </div>
            { discount_radio === 'Percentage' ?
              <div style={style.TotalContainer}>
                <label style={style.TotalLabel}>ABSOLUTE DISCOUNT</label>
                <p style={style.TotalLabel}>${formatFloat(total_invoice * (selected_discount/100))}</p>
              </div> : null }
          </div> : null */}
          {/* show_discount_data === false && discount > 0 ?
          <div style={style.Row}>
            <div style={style.TotalHourContainer}>
              <label style={style.TotalLabel}>TOTAL DISCOUNT</label>
              { discount_type === 'Percentage' ?
                <p style={style.TotalLabel}>{discount}%</p> :
                <p style={style.TotalLabel}>${discount}</p> }
            </div>
            { discount_type === 'Percentage' ?
              <div style={style.TotalContainer}>
                <label style={style.TotalLabel}>ABSOLUTE DISCOUNT</label>
                <p style={style.TotalLabel}>${formatFloat(total_invoice * (discount/100))}</p>
              </div> : null }
          </div> : null */}
        </div>

        {!from_postpaid_invoice_creation &&
        ((expenses !== null && expenses.length > 0) || selected_discount !== 0) ? (
          <div style={style.Row}>
            <div style={style.TotalContainerNew}>
              <label style={style.TotalLabel}>TOTAL AFTER DISCOUNTS/EXPENSES</label>
              {discount_radio === 'Percentage' ? (
                <p style={style.TotalLabel}>
                  $
                  {formatFloat(
                    total_invoice + total_expenses - total_invoice * (selected_discount / 100),
                  )}
                </p>
              ) : (
                <p style={style.TotalLabel}>
                  ${formatFloat(total_invoice + total_expenses - selected_discount)}
                </p>
              )}
            </div>
          </div>
        ) : null}
        {showInvoiceData &&
        (show_reconciliation_data === true ||
          Math.abs(showInvoiceData.reconciliated_price) > 0.0) ? (
          <div style={style.Row}>
            <div style={style.TotalHourContainer}>
              <label style={style.TotalLabel}>RECONCILED HOURS</label>
              <p style={style.TotalLabel}>{formatFloat(extra_hours)}</p>
            </div>
            <div style={style.TotalContainerNew}>
              <label style={style.TotalLabel}>RECONCILED COST</label>
              <p style={style.TotalLabel}>${formatFloat(extra_invoice)}</p>
            </div>
          </div>
        ) : null}
        {(show_discount_data === false && (discount > 0 || discount === '')) ||
        (show_discount_data === false && (discount > 0 || discount === '') && show_expense_data) ||
        (showInvoiceData &&
          (show_reconciliation_data === true ||
            Math.abs(showInvoiceData.reconciliated_price) > 0.0)) ? (
          <div style={style.Row}>
            <div style={style.TotalContainerNew}>
              <label style={style.TotalLabelFinal}>FINAL COST</label>
              {discount_type === 'Percentage' ? (
                selected_discount > 0 || selected_discount === '' ? (
                  <p style={style.TotalLabelFinal}>
                    $
                    {formatFloat(
                      total_invoice +
                        total_expenses -
                        extra_invoice -
                        total_invoice * (selected_discount / 100),
                    )}
                  </p>
                ) : (
                  <p style={style.TotalLabelFinal}>
                    $
                    {formatFloat(
                      total_invoice +
                        total_expenses -
                        extra_invoice -
                        total_invoice * (discount / 100),
                    )}
                  </p>
                )
              ) : selected_discount > 0 || selected_discount === '' ? (
                <p style={style.TotalLabelFinal}>
                  ${formatFloat(total_invoice + total_expenses - extra_invoice - selected_discount)}
                </p>
              ) : (
                <p style={style.TotalLabelFinal}>
                  ${formatFloat(total_invoice + total_expenses - extra_invoice - discount)}
                </p>
              )}
            </div>
          </div>
        ) : null}
        <DialogBoxes
          receivers={receivers}
          status={status}
          statuses={statuses}
          resources={resources}
          receiver={selected_receiver}
          invoice_id={selected_invoice_id}
          selected_status={selected_status}
          issued_date={selected_issued_date}
          // invoices_ability={invoices_ability}
          roles={roles}
          selected_resource={selected_resource}
          selected_template={selected_template}
          received_date={selected_received_date}
          submitted_date={selected_submitted_date}
          handleChangeStatus={this.handleChangeStatus}
          disabled_update_button={disabled_update_button}
          show_add_resource_view={show_add_resource_view}
          handleChangeReciever={this.handleChangeReciever}
          handleChangeInvoiceId={this.handleChangeInvoiceId}
          handleChangeIssuedDate={this.handleChangeIssuedDate}
          handleChangeReceivedDate={this.handleChangeReceivedDate}
          handleDownloadInvoicePDF={this.handleDownloadInvoicePDF}
          handleChangeSubmittedDate={this.handleChangeSubmittedDate}
          handleHideAddResourceView={this.handleHideAddResourceView}
          show_edit_invoice_dates_view={show_edit_invoice_dates_view}
          handleUpdateInvoiceDetails={this.handleUpdateInvoiceDetails}
          handleChangeSelectedStatus={this.handleChangeSelectedStatus}
          handleCloseChangeStatusView={this.handleCloseChangeStatusView}
          handleChangeSelectedResource={this.handleChangeSelectedResource}
          handleChangeSelectedTemplate={this.handleChangeSelectedTemplate}
          handleShowChangeTemplateView={this.handleShowChangeTemplateView}
          show_invoice_status_change_view={show_invoice_status_change_view}
          handleCloseChangeTemplateView={this.handleCloseChangeTemplateView}
          handleAddResourceToInvoiceData={this.handleAddResourceToInvoiceData}
          handleHideEditInvoiceDatesView={this.handleHideEditInvoiceDatesView}
          show_invoice_template_select_view={show_invoice_template_select_view}
          handleToggleAddDiscountView={this.handleToggleAddDiscountView}
          handleToggleAddExpenseView={this.handleToggleAddExpenseView}
          handleDiscountRadioChange={this.handleDiscountRadioChange}
          selected_discount_display_string={selected_discount_display_string}
          discount_radio={discount_radio}
          show_add_discount_view={show_add_discount_view}
          handleDiscountChange={this.handleDiscountChange}
          handleAddDiscountToInvoiceData={this.handleAddDiscountToInvoiceData}
          selected_expense_display_string={selected_expense_display_string}
          selected_expense_label={selected_expense_label}
          show_add_expense_view={show_add_expense_view}
          handleExpenseChange={this.handleExpenseChange}
          handleExpenseLabelChange={this.handleExpenseLabelChange}
          handleAddExpenseToInvoiceData={this.handleAddExpenseToInvoiceData}
          toggleDeletePopup={this.toggleDeletePopup.bind(this)}
          deleteInvoice={this.removeInvoice}
          show_delete_popup={show_delete_popup}
          AssociatedReceivers={AssociatedReceivers}
          show_add_associate_receiver={show_add_associate_receiver}
          handleAssociateReceiver={this.handleAssociateReceiver}
          handleRemoveAssociateReceiver={this.handleRemoveAssociateReceiver}
          handleAddSubReceiverRow={this.handleAddSubReceiverRow}
          handleChangeRatioType={this.handleChangeRatioType}
          affiliate_receivers_ratio_type={affiliate_receivers_ratio_type}
          show_percentage_error={show_percentage_error}
          show_affiliate_invoice_create_view={show_affiliate_invoice_create_view}
          handleHideCreateAffiliateInvoiceView={this.handleHideCreateAffiliateInvoiceView}
          handleGenerateAffiliateInvoices={this.handleGenerateAffiliateInvoices}
          show_edit_warning_popup={show_edit_warning_popup}
          confirmOpenEditView={this.confirmOpenEditView}
        />
        {show_add_client || show_clients_confirmation_dialog ? (
          <CreateDialogBox
            project_id={''}
            projects={checkedProjectWithNoClientInfo}
            client_name={client_name}
            client_address={client_address}
            show_add_client={show_add_client}
            handleAddClient={this.handleAddClient}
            handleHideAddClient={this.handleHideAddClient}
            handleHideAddClientWithX={this.handleHideAddClientWithX}
            handleShowAddClient={this.handleShowAddClient}
            disabled_create_button={disabled_create_button}
            client_project_pdf_name={client_project_pdf_name}
            handleAddClientChanges={this.handleAddClientChanges}
            allClientsInfo={allClientsInfo}
            show_clients_confirmation_dialog={show_clients_confirmation_dialog}
            handleShowClientConfirmation={this.handleShowClientConfirmation}
            handleHideClientConfirmation={this.handleHideClientConfirmation}
            saveClientsAndGenerateReport={this.saveClientsAndGenerateReport}
            handleClientChangesFinal={this.handleClientChangesFinal}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    receivers: state.ReceiverReducer.receivers,
    roles: state.LoginReducer.roles,
    docId: state.InvoiceReducer.docId,
    status: state.InvoiceReducer.status,
    project: state.InvoiceReducer.project,
    end_date: state.InvoiceReducer.end_date,
    receiver: state.InvoiceReducer.receiver,
    invoices: state.InvoiceReducer.invoices,
    editable: state.InvoiceReducer.editable,
    resources: state.ResourceReducer.resources,
    start_date: state.InvoiceReducer.start_date,
    invoice_id: state.InvoiceReducer.invoice_id,
    issued_date: state.InvoiceReducer.issued_date,
    received_date: state.InvoiceReducer.received_date,
    total_invoice: state.InvoiceReducer.total_invoice,
    submitted_date: state.InvoiceReducer.submitted_date,
    project_manager: state.InvoiceReducer.project_manager,
    showInvoiceData: state.InvoiceReducer.showInvoiceData,
    parsed_csv_data: state.InvoiceReducer.parsed_csv_data,
    parsed_csv_data_between_dates: state.InvoiceReducer.parsed_csv_data_between_dates,
    resource_details: state.InvoiceReducer.resource_details,
    discount: state.InvoiceReducer.discount,
    all_expenses: state.InvoiceReducer.all_expenses,
    prepared_invoice: state.InvoiceReducer.prepared_invoice,
    rates: state.ResourceReducer.rates,
    projects: state.ProjectReducer.projects,
    clients: state.ClientReducer.clients,
    no_clients_data: state.ClientReducer.no_clients_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRates: () => dispatch(ActionsCreator.getRates()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  deleteInvoice: (id) => dispatch(ActionsCreator.deleteInvoice(id)),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getInvoices: () => dispatch(ActionsCreator.getInvoices()),
  getResources: () => dispatch(ActionsCreator.getResources()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  resetShowInvoice: () => dispatch(ActionsCreator.resetShowInvoice()),
  getParsedData: () => dispatch(ActionsCreator.getParsedData()),
  getParsedDataBetweenDates: (start, end) =>
    dispatch(ActionsCreator.getParsedDataBetweenDates(start, end)),
  getHoursBetweenDates: (start, end) => dispatch(ActionsCreator.getHoursBetweenDates(start, end)),
  changeInvoiceData: (data) => dispatch(ActionsCreator.changeInvoiceData(data)),
  setInvoiceDetailData: (data) => dispatch(ActionsCreator.setInvoiceDetailData(data)),
  resetShowInvoiceData: (data) => dispatch(ActionsCreator.resetShowInvoiceData(data)),
  updateInvoice: (data, data1) => dispatch(ActionsCreator.updateInvoice(data, data1)),
  downloadInvoiceCSV: (data, data1) => dispatch(ActionsCreator.downloadInvoiceCSV(data, data1)),
  updateInvoiceDates: (data, data1) => dispatch(ActionsCreator.updateInvoiceDates(data, data1)),
  downloadInvoicePDF: (data, data1, data2) =>
    dispatch(ActionsCreator.downloadInvoicePDF(data, data1, data2)),
  prepareInvoice: (data, data1, data2, data3) =>
    dispatch(ActionsCreator.prepareInvoice(data, data1, data2, data3)),
  getAllClients: () => dispatch(ActionsCreator.getAllClients()),
  saveClientMultiple: (data) => dispatch(ActionsCreator.saveClientMultiple(data)),
  saveInvoice: (data) => dispatch(ActionsCreator.saveInvoice(data)),
  saveInvoiceAffiliates: (data) => dispatch(ActionsCreator.saveInvoiceAffiliates(data)),
  checkLoggedIn: () => dispatch(ActionsCreator.checkLoggedIn()),
  autoFillInvoice: (data) => dispatch(ActionsCreator.autoFillInvoice(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(ProjectDetailInvoice));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Moment from 'moment';
import { withSnackbar } from 'notistack';
import { Bars } from 'react-loader-spinner';

import './Invoice.css';
import { style } from './style';
import { DialogBoxes } from './DialogBoxes';
import { CreateDialogBox } from './CreateDialogBox';
import { AllInvoices } from './AllInvoices';
import { Ability, hasPermission } from '../../constants/ability';
import { EmptyContainer } from '../Utility/EmptyContainer';
import { ActionsCreator } from '../../Redux/Actions/index';
import { InvoiceState } from '../../constants/invoicestate';
import { InvoiceSectionHeader } from './InvoiceSectionHeader';
import ProjectDetailInvoice from '../Dashboard/ProjectDetailInvoice';
import * as ReactGA from 'react-ga';
import {
  dateSort,
  stringSort,
  integerSort,
  getProjectOfResource,
  endDateFromPrepareInvoice,
  preparedDataToInvoiceData,
  startDateFromPrepareInvoice,
  getTotalFromInvoiceData,
  calculateAutoFillHourData,
  getAllClientsInfo,
} from '../../Selectors/index';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_create_invoice: false,
      show_draft_invoice: false,
      selected_receiver: '',
      project: '',
      start_date: '',
      end_date: '',
      show_filters: false,
      header: {
        Status: 'ascending',
        'Start Date': 'ascending',
        'End Date': 'ascending',
        'Invoice ID': 'ascending',
        Project: 'ascending',
        Receiver: 'ascending',
        Amount: 'ascending',
      },
      selected_resource_name: '',
      selected_status: '',
      selected_to_date: '',
      selected_project: this.props.selected_project ? this.props.selected_project : '',
      selected_from_date: '',
      selected_invoice_id: '',
      selected_issued_to_date: '',
      selected_issued_from_date: '',
      filtered_invoices: [],
      invoice_radio: 'Prepaid',
      show_invoice_option: false,
      show_add_resource: false,
      resource_names: [],
      temp_PM: '',
      show_add_project_manager: false,
      selected_project_manager: '',
      auto_fill_resource_details: [],
      old_resource_details: [],
      auto_fill: false,
      show_checkboxes: false,
      show_download_csv: false,
      checkedValues: [],
      checkedProjectName: [],
      checkedInvoice: [],
      checkedProjectWithNoClientInfo: [],
      show_add_client: false,
      client_name: [],
      client_address: [],
      client_project_pdf_name: [],
      project_id: [],
      client_per_project: [],
      allClientsInfo: [],
      show_clients_confirmation_dialog: false,
      previous_resource_details: [],
      changeResourceDetails: false,
    };
  }

  static defaultProps = {
    loadingInvoices: true,
    showInvoice: 'all',
  };

  componentDidMount = () => {
    const { location, getAllReceivers, getResources } = this.props;
    getAllReceivers();

    this.loadInvoicePageData();
    let props_show_create_invoice = location.state
      ? location.state.props_show_create_invoice
      : false;

    if (props_show_create_invoice) {
      this.handleLocationState();
    }

    getResources();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selected_project !== this.props.selected_project) {
      this.setState({
        selected_project: this.props.selected_project,
      });
    }
  }

  toggleAddPopup = () => {
    this.setState({
      selected_resource_name: '',
      show_add_resource: !this.state.show_add_resource,
    });
  };
  toggleAddPopupProjectManager = () => {
    this.setState({
      show_add_project_manager: !this.state.show_add_project_manager,
    });
  };

  handleChange = (e, value, resource, index) => {
    const new_value = Math.max(Number(0), Number(e.target.value));
    const new_value_display_string = e.target.value;

    let { setInvoiceDetailData, resource_details } = this.props;

    if (value === 'rate') {
      resource_details[index].rate = new_value;
      resource_details[index].rate_display_string = new_value_display_string;
    } else if (value === 'hours') {
      resource_details.forEach((data) => {
        if (data.resource === resource) {
          data.weeks[index].total_week_hours = new_value;
          data.weeks[index].total_week_hours_display_string = new_value_display_string;
          data.total_hours = data.weeks.reduce(
            (a, b) => a + (parseFloat(b.total_week_hours) ? b.total_week_hours : 0),
            0,
          );
        }
      });
    }

    setInvoiceDetailData({
      resource_details: resource_details,
    });
    this.setState({
      changeResourceDetails: true,
    });
  };

  handleDeleteRow = (index) => {
    let { setInvoiceDetailData, resource_details, enqueueSnackbar } = this.props;
    let { resource_names } = this.state;

    if (resource_details.length === 1) {
      enqueueSnackbar("Can't remove all resources!", { variant: 'error' });
    } else {
      resource_details = this.removeIndexFromInvoiceData(resource_details, index);
      let temp = this.removeIndexFromResourceState(resource_names, index);
      this.setState({
        resource_names: temp,
      });

      setInvoiceDetailData({ resource_details: resource_details }).then((res) => {
        if (res) {
          enqueueSnackbar('Resource Removed!', { variant: 'success' });
        } else {
          enqueueSnackbar("Resource wasn't removed!", { variant: 'error' });
        }
      });
    }
  };

  removeIndexFromResourceState = (data, remove_index) => {
    let newData = [];

    for (let i = 0; i < data.length; i++) {
      if (i !== remove_index) {
        newData.push(data[i]);
      }
    }

    return newData;
  };

  removeIndexFromInvoiceData = (data, remove_index) => {
    let newData = [];

    for (let key in data) {
      if (parseInt(key) !== remove_index) {
        newData.push(data[key]);
      }
    }

    return newData;
  };

  handleInvoiceRadioChange = async (e) => {
    await this.setState({
      invoice_radio: e.target.value,
    });
  };

  handleShowInvoiceOptions = () => {
    this.setState({
      show_invoice_option: true,
    });
  };

  handleHideInvoiceOptions = () => {
    this.setState({
      show_invoice_option: false,
    });
  };

  handleResourceSelection = (e) => {
    this.setState({
      selected_resource_name: e.target.value,
    });
  };

  handleProjectManagerSelection = (e) => {
    this.setState({
      selected_project_manager: e.target.value,
    });
  };

  async loadInvoicePageData() {
    const {
      getInvoices,
      stopLoader,
      getProjects,
      getRates,
      rates,
      resources,
      projects,
      unbillable,
      getHoursRange,
      getUnbillableProjects,
      getResources,
      getAllClients,
      clients,
    } = this.props;

    !rates && (await getRates());
    !resources && (await getResources());
    !unbillable && (await getUnbillableProjects());
    !projects && (await getProjects());
    !clients && (await getAllClients());
    await getHoursRange();

    getInvoices().then((response) => {
      if (response) {
        this.setState({
          filtered_invoices: response,
        });
      }
      stopLoader();
    });
  }

  refreshInvoices = () => {
    const { stopLoader, getInvoices } = this.props;

    getInvoices().then(async (response) => {
      if (response) {
        this.setState({
          filtered_invoices: response,
        });
        stopLoader();
      }
    });
  };

  handleGetInvoiceData = () => {
    let { project, end_date, start_date, invoice_radio } = this.state;
    const { prepareInvoice, startLoader, stopLoader, enqueueSnackbar } = this.props;

    if (project !== '' && Moment(end_date).isValid() && !this.checkDateOverlap()) {
      startLoader('#61cc40')
        .then((response) => {
          if (response) {
            return prepareInvoice(project, end_date, start_date, invoice_radio);
          }
        })
        .then((response) => {
          if (response && Moment(end_date).isSameOrAfter(Moment(start_date))) {
            this.setState({
              show_create_invoice: false,
            });
            this.handleShowDraftInvoice(invoice_radio);
          } else if (this.props.invoice_error_message) {
            //console.log(this.props.invoice_error_message);
            enqueueSnackbar(this.props.invoice_error_message, { variant: 'error' });
          } else {
            enqueueSnackbar('Unable to Prepare Invoice!', { variant: 'error' });
          }
          stopLoader();
        });
    } else if (this.checkDateOverlap()) {
      enqueueSnackbar('Invoice with that date already exists!', { variant: 'error' });
    } else {
      enqueueSnackbar('Enter valid Project And Date!', { variant: 'error' });
    }
  };

  checkDateOverlap = () => {
    const { invoices } = this.props;
    const { project, end_date, start_date } = this.state;
    let overlap = false;

    let project_prepaid_invoices = invoices.filter(
      (invoice) => invoice.project_name === project && invoice.type === 'Prepaid',
    );
    let project_postpaid_invoices = invoices.filter(
      (invoice) => invoice.project_name === project && invoice.type === 'Postpaid',
    );

    project_postpaid_invoices = project_postpaid_invoices.sort(
      (a, b) => Moment(a.end_date) > Moment(b.end_date),
    );

    if (project_postpaid_invoices.length > 0) {
      overlap = Moment(project_postpaid_invoices[0].end_date) >= Moment(start_date);
    }

    project_prepaid_invoices.forEach((invoice) => {
      if (
        (Moment(start_date) >= Moment(invoice.start_date) &&
          Moment(start_date) <= Moment(invoice.end_date)) ||
        (Moment(end_date) >= Moment(invoice.start_date) &&
          Moment(end_date) <= Moment(invoice.end_date))
      )
        overlap = true;
    });

    return overlap;
  };

  handleShowAllInvoices = () => {
    this.props.resetShowInvoice();
  };

  handleShowProjectInvoice = async (docId) => {
    let { setShowInvoice, invoices, setInvoiceDetailData } = this.props;
    let invoice = invoices.filter((inv) => inv.docId === docId)[0];

    const data = await setInvoiceDetailData({
      docId: docId,
      end_date: invoice.end_date,
      receiver: invoice.receiver,
      project: invoice.project_name,
      invoice_id: invoice.invoice_id,
      start_date: invoice.start_date,
      issued_date: invoice.issued_date,
      status: InvoiceState[invoice.status],
      received_date: invoice.received_date,
      submitted_date: invoice.submitted_date,
      project_manager: invoice.project_manager,
      resource_details: JSON.parse(invoice.project_invoice_data),
      editable: true,
      discount: invoice.discount,
      discount_type: invoice.discount_type,
      expenses: invoice.expenses,
    });
    if (data) {
      await setShowInvoice(invoice.project_name, invoice);
    }
  };

  handleShowCreateInvoice = () => {
    this.setState({
      show_invoice_option: false,
      show_create_invoice: true,
    });
  };

  handleHideCreateInvoice = () => {
    this.setState({
      show_create_invoice: false,
    });
  };

  handleProjectSelection = (e) => {
    this.setState({
      project: e.target.value,
    });
  };

  handleChangeStartDate = (e) => {
    this.setState({
      start_date: Moment(e.target.value).format('YYYY-MM-DD'),
    });
  };

  handleChangeEndDate = (e) => {
    this.setState({
      end_date: Moment(e.target.value).format('YYYY-MM-DD'),
    });
  };

  handleHideDraftInvoice = () => {
    let { resetPrepareInvoice, setInvoiceDetailData, previous_resource_details } = this.props;
    const { changeResourceDetails } = this.state;
    if (changeResourceDetails == true) {
      setInvoiceDetailData({ resource_details: previous_resource_details }); //reset the resource details to as it was before
    }
    resetPrepareInvoice().then((response) => {
      if (response) {
        this.setState({
          resource_names: [],
          show_draft_invoice: false,
          changeResourceDetails: false,
        });
      }
    });
  };

  handleShowDraftInvoice = async (invoice_type) => {
    let {
      setInvoiceDetailData,
      prepared_invoice,
      rates,
      projects,
      resource_details,
      setPreviousResourceDetails,
    } = this.props;
    let {
      project,
      selected_receiver,
      start_date,
      end_date,
      selected_resource_name,
      resource_names,
      selected_project_manager,
      changeResourceDetails,
    } = this.state;

    if (prepared_invoice === undefined || prepared_invoice === null) prepared_invoice = [];

    let resources = [...new Set(prepared_invoice.map((item) => item.resource))].concat(
      resource_names,
    );

    if (selected_resource_name !== '' && invoice_type === 'Prepaid') {
      if (resources.includes(selected_resource_name)) {
        this.props.enqueueSnackbar('The resource is already added!', { variant: 'error' });
        return;
      }
      if (resource_names && resource_names.length === 1 && resource_names[0] === 'dummy_resource') {
        resource_names[0] = selected_resource_name;
      } else {
        resource_names.push(selected_resource_name);
      }
    }

    let details = preparedDataToInvoiceData(
      prepared_invoice,
      project,
      rates,
      projects,
      start_date,
      end_date,
      invoice_type,
      resource_names,
      resource_details,
      selected_resource_name,
      changeResourceDetails,
    );

    if (details && details.project_manager === '' && selected_project_manager === '')
      this.toggleAddPopupProjectManager();

    if ((details && details.project_manager !== '') || selected_project_manager !== '') {
      /* THIS SEGMENT OF LEGACY CODE WAS CALCULATING WRONG VALUES OF 'total_hours' 
      
      details.resource_details.forEach(resource => {
        let hour = resource_details ? resource_details.filter(record => record.resource === resource.resource): null;
        if(typeof hour != 'undefined' && hour instanceof Array && hour.length!==0) {
          console.log(hour[0]);
          for(let i=0; i<resource.weeks.length; i++){
            if(resource.weeks !== undefined && hour.weeks !== undefined)
              resource.weeks[i].total_week_hours = hour[0].weeks[i].total_week_hours;
          }
          resource.total_hours = hour[0].total_hours;
        }
      });*/

      let temp = [];
      for (let i = 0; i < details.resource_details.length; i++) {
        temp.push(details.resource_details[i].resource);
      }
      this.setState({
        resource_names: temp,
        previous_resource_details: resource_details,
      });

      let editable = false;
      setInvoiceDetailData({
        receiver: selected_receiver,
        project: details.show_project,
        project_manager:
          details.project_manager !== '' ? details.project_manager : selected_project_manager,
        status: InvoiceState.DRAFT,
        resource_details: details.resource_details,
        total_invoice: details.total_invoice,
        total_hours: parseInt(details.total_hours),
        editable: editable,
      }).then((data) => {
        if (data) {
          this.setState({
            show_draft_invoice: true,
          });
        }
        if (changeResourceDetails == false) {
          setPreviousResourceDetails(details.resource_details);
        }
      });
    }
  };

  handleSaveDraftInvoice = () => {
    let {
      saveInvoice,
      status,
      project,
      project_manager,
      prepared_invoice,
      resource_details,
      startLoader,
      stopLoader,
      getInvoices,
    } = this.props;
    let { selected_project_manager } = this.state;
    if (
      (project_manager === null || project_manager === undefined || project_manager === '') &&
      selected_project_manager === ''
    ) {
      startLoader('#61cc40').then((response) => {
        if (response) {
          this.props.enqueueSnackbar('Project manager not assigned', { variant: 'error' });
          stopLoader();
        }
      });
    }
    let { selected_receiver, invoice_radio, start_date, end_date } = this.state;

    let type = 'Prepaid',
      date_start = start_date,
      date_end = end_date;
    if (invoice_radio === 'Postpaid') {
      date_start = startDateFromPrepareInvoice(prepared_invoice);
      date_end = endDateFromPrepareInvoice(prepared_invoice);
      type = 'Postpaid';
    }

    if (project_manager !== '' || selected_project_manager !== '') {
      let data = {
        project_name: project,
        project_manager: project_manager !== '' ? project_manager : selected_project_manager,
        status: status.name,
        receiver: selected_receiver,
        invoice_data: JSON.stringify(resource_details),
        start_date: date_start,
        end_date: date_end,
        type: type,
        // expenses : "",
      };

      startLoader('#61cc40')
        .then((response) => {
          if (response) {
            return saveInvoice(data);
          }
        })
        .then((data_rec) => {
          if (data_rec.saved) {
            ReactGA.event({
              category: 'Invoices',
              action: 'Created a ' + type + ' Invoice',
            });
            this.props.enqueueSnackbar(data_rec.message, { variant: 'success' });
            getInvoices().then((response) => {
              if (response) {
                this.setState({
                  filtered_invoices: response,
                  selected_project_manager: '',
                  changeResourceDetails: false,
                });
              }
            });
          } else {
            this.props.enqueueSnackbar(data_rec.message, { variant: 'error' });
          }
          this.handleShowAllInvoices();
          this.handleHideDraftInvoice();
          stopLoader();
        });
    }
  };

  addResource = async () => {
    await this.handleShowDraftInvoice('Prepaid');
    this.toggleAddPopup();
  };

  addProjectManger = async () => {
    await this.handleShowDraftInvoice(this.state.invoice_radio);
    this.toggleAddPopupProjectManager();
  };

  handleAutoFillHours = (start_date, end_date, resource_details) => {
    this.setState({
      auto_fill_resource_details: calculateAutoFillHourData(start_date, end_date, resource_details),
      auto_fill: true,
      old_resource_details: resource_details,
    });
    var temp = calculateAutoFillHourData(start_date, end_date, resource_details);
    this.props.autoFillInvoice(temp);
  };

  handleClearAutoFillHours = (resource_details) => {
    this.setState({
      auto_fill: false,
    });
    this.props.autoFillInvoice(this.state.old_resource_details);
  };

  handleLocationState = () => {
    const { history } = this.props;
    let { props_project, props_show_create_invoice } = this.props.location.state;

    this.setState(
      {
        show_create_invoice: props_show_create_invoice,
        project: props_project,
        invoice_radio: 'Postpaid',
      },
      () => {
        history.replace('invoice', {});
      },
    );
  };

  handleChangeReciever = (e) => {
    this.setState({
      selected_receiver: e.target.value,
    });
  };

  handleSort = (item, myFunction, value, objectName) => {
    let sort_statuses = this.state.header;
    let sort_type = sort_statuses[item];
    let new_sort_statuses = sort_statuses;

    switch (sort_type) {
      case 'ascending':
        new_sort_statuses[item] = 'descending';
        this.setState({
          filtered_invoices: myFunction('ascending', value, objectName),
          header: new_sort_statuses,
        });
        break;
      case 'descending':
        new_sort_statuses[item] = 'ascending';
        this.setState({
          filtered_invoices: myFunction('descending', value, objectName),
          header: new_sort_statuses,
        });
        break;
      default:
        break;
    }
  };

  handleInvoiceSort = (item) => {
    const { filtered_invoices } = this.state;

    if (item === 'Status') {
      this.handleSort(item, stringSort, filtered_invoices, 'status');
    } else if (item.includes('Date')) {
      if (item === 'Start Date') {
        this.handleSort(item, dateSort, filtered_invoices, 'start_date');
      } else if (item === 'End Date') {
        this.handleSort(item, dateSort, filtered_invoices, 'end_date');
      }
    } else if (item === 'Invoice ID') {
      this.handleSort(item, stringSort, filtered_invoices, 'invoice_id');
    } else if (item === 'Project') {
      this.handleSort(item, stringSort, filtered_invoices, 'project_name');
    } else if (item === 'Receiver') {
      this.handleSort(item, stringSort, filtered_invoices, 'receiver');
    } else if (item === 'Amount') {
      this.handleSort(item, integerSort, filtered_invoices, 'project_invoice_data');
    }
  };

  handleChangeToDate = (e) => {
    let { enqueueSnackbar } = this.props;
    let { selected_from_date } = this.state;
    let new_date = Moment(e.target.value).format('YYYY-MM-DD');

    if (
      Moment(selected_from_date).isValid() &&
      Moment(selected_from_date).isAfter(Moment(new_date))
    ) {
      enqueueSnackbar("To Date shouldn't be less than From!", { variant: 'error' });
    } else {
      this.setState({
        selected_to_date: new_date,
      });
    }
  };

  handleChangeFromDate = (e) => {
    let { enqueueSnackbar } = this.props;
    let { selected_to_date } = this.state;
    let new_date = Moment(e.target.value).format('YYYY-MM-DD');

    if (Moment(selected_to_date).isValid() && Moment(new_date).isAfter(Moment(selected_to_date))) {
      enqueueSnackbar('From Date should be less than To!', { variant: 'error' });
    } else {
      this.setState({
        selected_from_date: new_date,
      });
    }
  };

  handleChangeIssuedToDate = (e) => {
    let { enqueueSnackbar } = this.props;
    let { selected_issued_from_date } = this.state;
    let new_date = Moment(e.target.value).format('YYYY-MM-DD');

    if (
      Moment(selected_issued_from_date).isValid() &&
      Moment(selected_issued_from_date).isAfter(Moment(new_date))
    ) {
      enqueueSnackbar("To Date shouldn't be less than Issued From!", { variant: 'error' });
    } else {
      this.setState({
        selected_issued_to_date: new_date,
      });
    }
  };

  handleChangeIssuedFromDate = (e) => {
    let { enqueueSnackbar } = this.props;
    let { selected_issued_to_date } = this.state;
    let new_date = Moment(e.target.value).format('YYYY-MM-DD');

    if (
      Moment(selected_issued_to_date).isValid() &&
      Moment(new_date).isAfter(Moment(selected_issued_to_date))
    ) {
      enqueueSnackbar('From Date should be less than Issued To!', { variant: 'error' });
    } else {
      this.setState({
        selected_issued_from_date: new_date,
      });
    }
  };

  handleChangeSelectedStatus = (e) => {
    this.setState({
      selected_status: e.target.value,
    });
  };

  handleClearFilters = () => {
    this.setState({
      selected_status: '',
      selected_project: '',
      selected_to_date: '',
      selected_from_date: '',
      selected_invoice_id: '',
      selected_issued_to_date: '',
      selected_issued_from_date: '',
    });
  };

  handleChangeSearchInvoiceID = (e) => {
    this.setState({
      selected_invoice_id: e.target.value,
    });
  };

  handleChangeSelectedProject = (e) => {
    this.setState({
      selected_project: e.target.value,
    });
  };

  handleChangeShowFilterStatus = (status) => {
    this.setState({
      show_filters: status,
    });
  };

  handleChangeShowCheckboxes = (status) => {
    this.setState({
      show_checkboxes: status,
    });
  };

  handleCheckbox = (e, invoice) => {
    this.setState((state) => ({
      checkedInvoice: [...state.checkedInvoice, invoice],
      checkedProjectName: state.checkedProjectName.includes(invoice.project_name)
        ? state.checkedProjectName.filter((c) => c !== invoice.project_name)
        : [...state.checkedProjectName, invoice.project_name],
      checkedValues: state.checkedValues.includes(invoice.docId)
        ? state.checkedValues.filter((c) => c !== invoice.docId)
        : [...state.checkedValues, invoice.docId],
    }));
  };

  handleDownloadCSV = () => {
    let { checkedValues, checkedInvoice } = this.state;
    let {
      downloadMultipleInvoicesCSV,
      enqueueSnackbar,
      startLoader,
      stopLoader,
      clients,
      no_clients_data,
    } = this.props;
    let downloadReport = true;

    let distinct_checkInvoice = checkedInvoice.filter(
      (e, i) => checkedInvoice.findIndex((a) => a.projectId === e.projectId) === i,
    );
    if (no_clients_data === false) {
      /*let projects_with_no_client = clients.filter(f => !checkedProjectName.includes(f.project_name));
      console.log(projects_with_no_client);*/
      let tempFilter = clients.map((item) => {
        return item.project_name;
      });
      let projects_with_no_clientInfo = distinct_checkInvoice.filter(
        (f) => !tempFilter.includes(f.project_name),
      );
      downloadReport = projects_with_no_clientInfo.length !== 0 ? false : true;
      this.setState({
        checkedProjectWithNoClientInfo: projects_with_no_clientInfo,
      });
      if (!downloadReport) {
        this.handleShowAddClient();
      }
    }
    if (downloadReport) {
      startLoader('#61cc40')
        .then((response) => {
          if (response) {
            return downloadMultipleInvoicesCSV(checkedValues);
          }
        })
        .then((response) => {
          this.setState({
            checkedValues: [],
            show_checkboxes: false,
            checkedInvoice: [],
          });
          if (response) {
            enqueueSnackbar('Invoices CSV Downloading!', { variant: 'success' });
          } else {
            enqueueSnackbar('Invoices CSV download Failed!', { variant: 'error' });
          }
          stopLoader();
        });
    }
  };

  handleShowAddClient = () => {
    this.setState({
      show_add_client: true,
    });
  };

  handleHideAddClient = () => {
    this.setState({
      show_add_client: false,
    });
  };

  handleHideAddClientWithX = () => {
    this.setState({
      show_add_client: false,
      checkedValues: [],
      show_checkboxes: false,
      checkedInvoice: [],
    });
  };

  handleClientChangesFinal = (e, type, index) => {
    let temp_array = [];
    let { allClientsInfo } = this.state;

    if (type === 'name') {
      temp_array = allClientsInfo;
      temp_array[index].client_name = e.target.value;
      this.setState({
        allClientsInfo: temp_array,
      });
    } else if (type === 'address') {
      temp_array = allClientsInfo;
      temp_array[index].client_address = e.target.value;
      this.setState({
        allClientsInfo: temp_array,
      });
    } else if (type === 'project_name') {
      temp_array = allClientsInfo;
      temp_array[index].client_project_pdf_name = e.target.value;
      this.setState({
        allClientsInfo: temp_array,
      });
    }
  };

  handleAddClientChanges = (e, type, id = '', index) => {
    let temp_array = [];
    let { client_name, client_address, project_id, client_project_pdf_name } = this.state;

    if (type === 'name') {
      temp_array = client_name;
      temp_array[index] = e.target.value;
      let temp2 = project_id;
      temp2[index] = id;
      this.setState({
        client_name: temp_array,
        project_id: temp2,
      });
    } else if (type === 'address') {
      temp_array = client_address;
      temp_array[index] = JSON.stringify(e.target.value.split('\n'));
      this.setState({
        client_address: temp_array,
      });
    } else if (type === 'project_id') {
      this.setState({
        project_id: [...this.state.project_id, e.target.value],
      });
    } else if (type === 'project_name') {
      temp_array = client_project_pdf_name;
      temp_array[index] = e.target.value;
      this.setState({
        client_project_pdf_name: temp_array,
      });
    }
  };

  handleAddClient = () => {
    let { client_name, client_address, client_project_pdf_name, checkedProjectWithNoClientInfo } =
      this.state;
    let allClientsInfo = getAllClientsInfo(
      checkedProjectWithNoClientInfo,
      client_name,
      client_address,
      client_project_pdf_name,
    );

    this.setState({
      allClientsInfo: allClientsInfo,
      show_add_client: false,
      show_clients_confirmation_dialog: true,
      client_name: [],
      client_address: [],
      client_project_pdf_name: [],
      project_id: [],
      checkedProjectWithNoClientInfo: [],
    });
  };

  handleShowClientConfirmation = () => {
    this.setState({
      show_clients_confirmation_dialog: true,
    });
  };

  handleHideClientConfirmation = () => {
    this.setState({
      show_clients_confirmation_dialog: false,
      checkedValues: [],
      show_checkboxes: false,
      checkedInvoice: [],
    });
  };

  saveClientsAndGenerateReport = () => {
    let { allClientsInfo, checkedValues } = this.state;
    let {
      saveClientMultiple,
      downloadMultipleInvoicesCSV,
      enqueueSnackbar,
      startLoader,
      stopLoader,
    } = this.props;

    startLoader('#61cc40')
      .then((res) => {
        if (res) {
          return saveClientMultiple(allClientsInfo);
        }
      })
      .then((res) => {
        if (res) {
          enqueueSnackbar('Clients Info Saved! You can modify them from clients tab', {
            variant: 'success',
          });
          return downloadMultipleInvoicesCSV(checkedValues);
        } else {
          enqueueSnackbar("Clients Info wasn't saved!", { variant: 'error' });
          this.setState({
            show_clients_confirmation_dialog: false,
            checkedValues: [],
            show_checkboxes: false,
            checkedInvoice: [],
          });
          stopLoader();
        }
      })
      .then((response) => {
        if (response) {
          enqueueSnackbar('Clients Info Saved! You can modify them from clients tab', {
            variant: 'success',
          });
          enqueueSnackbar('Invoices CSV Downloading!', { variant: 'success' });
        } else {
          enqueueSnackbar("Clients Info wasn't saved!", { variant: 'error' });
          enqueueSnackbar('Invoices CSV download Failed!', { variant: 'error' });
        }
        this.setState({
          show_clients_confirmation_dialog: false,
          checkedValues: [],
          show_checkboxes: false,
          checkedInvoice: [],
        });
        stopLoader();
      });
  };

  render() {
    let {
      email,
      roles,
      range,
      invoices,
      projects,
      showInvoice,
      loadingInvoices,
      no_active_period,
      resource_details,
      status,
      project_manager,
      receivers,
      resources,
    } = this.props;
    let {
      header,
      project,
      end_date,
      start_date,
      show_filters,
      show_checkboxes,
      show_download_csv,
      selected_status,
      selected_project,
      selected_to_date,
      selected_receiver,
      filtered_invoices,
      show_draft_invoice,
      selected_from_date,
      selected_invoice_id,
      show_create_invoice,
      selected_issued_to_date,
      selected_issued_from_date,
      invoice_radio,
      show_invoice_option,
      show_add_resource,
      selected_resource_name,
      show_add_project_manager,
      selected_project_manager,
      auto_fill_resource_details,
      auto_fill,
      checkedValues,
      show_add_client,
      checkedProjectWithNoClientInfo,
      client_name,
      client_address,
      client_project_pdf_name,
      allClientsInfo,
      show_clients_confirmation_dialog,
    } = this.state;
    let disabled_create_button =
      client_name.length === 0 ||
      client_address.length === 0 ||
      client_project_pdf_name.length === 0;

    let filtered_projects = [];
    let project_names = [];
    let disable_prepare_button = false;
    let disable_add_button = selected_resource_name === '';
    let disable_add_pm_button = selected_project_manager === '';
    let show_receiver = hasPermission(roles, 'invoices', 'can_enter_receiver');
    if (projects) {
      filtered_projects = projects.filter((projectf) => projectf.billable);
    }

    if (
      filtered_invoices &&
      roles.filter((role) => Ability[role].invoices.type === 'project').length &&
      hasPermission(roles, 'invoices', 'can_edit') &&
      !roles.includes('Super Admin')
    ) {
      if (projects) {
        let resource_projects = getProjectOfResource(email, projects);
        filtered_projects = filtered_projects.filter((projectf) =>
          resource_projects.includes(projectf.name),
        );
      }
    }

    if (selected_issued_from_date !== '') {
      filtered_invoices = filtered_invoices.filter(
        (invoice) => Moment(invoice.issued_date) >= Moment(selected_issued_from_date),
      );
    }

    if (selected_issued_to_date !== '') {
      filtered_invoices = filtered_invoices.filter(
        (invoice) => Moment(invoice.issued_date) <= Moment(selected_issued_to_date),
      );
    }

    if (selected_from_date !== '') {
      filtered_invoices = filtered_invoices.filter(
        (invoice) => Moment(invoice.start_date) >= Moment(selected_from_date),
      );
    }

    if (selected_to_date !== '') {
      filtered_invoices = filtered_invoices.filter(
        (invoice) => Moment(invoice.start_date) <= Moment(selected_to_date),
      );
    }

    if (selected_status !== '') {
      filtered_invoices = filtered_invoices.filter((invoice) => invoice.status === selected_status);
    }

    if (selected_invoice_id !== '') {
      filtered_invoices = filtered_invoices.filter((invoice) =>
        invoice.invoice_id.includes(selected_invoice_id),
      );
    }

    if (selected_project) {
      filtered_invoices = filtered_invoices.filter(
        (invoice) => invoice.project_name === selected_project,
      );
    }

    if (invoices) {
      project_names = [...new Set(invoices.map((item) => item.project_name))];
    }

    /*if (filtered_invoices && filtered_invoices.length > 0 && selected_issued_from_date === '') {
      selected_issued_from_date = filtered_invoices.sort(
        (a, b) => Moment(a.issued_date) - Moment(b.issued_date),
      )[0].issued_date;
    }

    if (filtered_invoices && filtered_invoices.length > 0 && selected_issued_to_date === '') {
      selected_issued_to_date = filtered_invoices.sort(
        (a, b) => Moment(b.issued_date) - Moment(a.issued_date),
      )[0].issued_date;
    }

    if (filtered_invoices && filtered_invoices.length > 0 && selected_from_date === '') {
      selected_from_date = filtered_invoices.sort(
        (a, b) => Moment(a.start_date) - Moment(b.start_date),
      )[0].start_date;
    }

    if (filtered_invoices && filtered_invoices.length > 0 && selected_to_date === '') {
      selected_to_date = filtered_invoices.sort(
        (a, b) => Moment(b.end_date) - Moment(a.end_date),
      )[0].end_date;
    }*/

    if (show_receiver) {
      disable_prepare_button =
        selected_receiver === '' ||
        project === '' ||
        !Moment(end_date).isValid() ||
        !Moment(start_date).isValid();
    } else {
      disable_prepare_button =
        project === '' || !Moment(end_date).isValid() || !Moment(start_date).isValid();
    }

    let prepaid = false;
    if (invoice_radio === 'Prepaid') prepaid = true;

    let total_invoice = 0;
    if (resource_details !== undefined) total_invoice = getTotalFromInvoiceData(resource_details);

    if (resources === undefined) resources = [];

    return (
      <div style={style.ComponentMain}>
        <InvoiceSectionHeader
          range={range}
          showInvoice={showInvoice}
          invoices={filtered_invoices}
          no_active_period={no_active_period}
          handleShowAllInvoices={this.handleShowAllInvoices}
          handleShowInvoiceOptions={this.handleShowInvoiceOptions}
          handleShowProjectInvoice={this.handleShowProjectInvoice}
          can_create_invocie={hasPermission(roles, 'invoices', 'can_create')}
        />
        <div
          style={
            loadingInvoices || !filtered_invoices ? style.LoadingContainer : style.MainContainer
          }>
          {loadingInvoices === true ? (
            <Bars color="#61cc40" height={50} width={50} />
          ) : filtered_invoices ? (
            showInvoice === 'all' ? (
              <AllInvoices
                header={header}
                projects={project_names}
                show_filters={show_filters}
                show_checkboxes={show_checkboxes}
                show_download_csv={show_download_csv}
                invoices={filtered_invoices}
                selected_status={selected_status}
                selected_project={selected_project}
                selected_to_date={selected_to_date}
                selected_from_date={selected_from_date}
                selected_invoice_id={selected_invoice_id}
                handleInvoiceSort={this.handleInvoiceSort}
                showInvoice={this.handleShowProjectInvoice}
                handleChangeToDate={this.handleChangeToDate}
                handleClearFilters={this.handleClearFilters}
                handleChangeFromDate={this.handleChangeFromDate}
                selected_issued_to_date={selected_issued_to_date}
                selected_issued_from_date={selected_issued_from_date}
                handleChangeIssuedToDate={this.handleChangeIssuedToDate}
                handleChangeIssuedFromDate={this.handleChangeIssuedFromDate}
                handleChangeSelectedStatus={this.handleChangeSelectedStatus}
                handleChangeSelectedProject={this.handleChangeSelectedProject}
                handleChangeSearchInvoiceID={this.handleChangeSearchInvoiceID}
                handleChangeShowFilterStatus={this.handleChangeShowFilterStatus}
                handleChangeShowCheckboxes={this.handleChangeShowCheckboxes}
                checkedValues={checkedValues}
                handleCheckbox={this.handleCheckbox}
                handleDownloadCSV={this.handleDownloadCSV}
              />
            ) : (
              <div style={style.DetailPageContainer}>
                <ProjectDetailInvoice
                  refreshInvoices={this.refreshInvoices}
                  filtered_projects={filtered_projects}
                />
              </div>
            )
          ) : (
            <EmptyContainer />
          )}
          {projects ? (
            <DialogBoxes
              receivers={receivers}
              project={project}
              end_date={end_date}
              start_date={start_date}
              projects={filtered_projects}
              show_receiver={show_receiver}
              selected_receiver={selected_receiver}
              show_draft_invoice={show_draft_invoice}
              show_create_invoice={show_create_invoice}
              invoice_radio={invoice_radio}
              show_invoice_option={show_invoice_option}
              prepaid={prepaid}
              resource_details={resource_details}
              disable_prepare_button={disable_prepare_button}
              disable_add_button={disable_add_button}
              disable_add_pm_button={disable_add_pm_button}
              status={status}
              project_manager={project_manager}
              total_invoice={total_invoice}
              show_add_resource={show_add_resource}
              show_add_project_manager={show_add_project_manager}
              selected_resource_name={selected_resource_name}
              selected_project_manager={selected_project_manager}
              resource_names={resources
                .filter((resource) => resource.active === true)
                .map((resource) => resource.name)
                .sort()}
              handleChangeEndDate={this.handleChangeEndDate}
              handleGetInvoiceData={this.handleGetInvoiceData}
              handleChangeReciever={this.handleChangeReciever}
              handleChangeStartDate={this.handleChangeStartDate}
              handleCloseProjectMenu={this.handleCloseProjectMenu}
              handleProjectSelection={this.handleProjectSelection}
              handleHideDraftInvoice={this.handleHideDraftInvoice}
              handleSaveDraftInvoice={this.handleSaveDraftInvoice}
              handleHideCreateInvoice={this.handleHideCreateInvoice}
              handleInvoiceRadioChange={this.handleInvoiceRadioChange}
              handleHideInvoiceOptions={this.handleHideInvoiceOptions}
              handleShowCreateInvoice={this.handleShowCreateInvoice}
              handleResourceSelection={this.handleResourceSelection}
              handleProjectManagerSelection={this.handleProjectManagerSelection}
              handleChange={this.handleChange}
              handleDeleteRow={this.handleDeleteRow}
              toggleAddPopup={this.toggleAddPopup}
              toggleAddPopupProjectManager={this.toggleAddPopupProjectManager}
              addResource={this.addResource}
              addProjectManger={this.addProjectManger}
              handleAutoFillHours={this.handleAutoFillHours}
              handleClearAutoFillHours={this.handleClearAutoFillHours}
              auto_fill_resource_details={auto_fill_resource_details}
              auto_fill={auto_fill}
            />
          ) : null}
          {show_add_client || show_clients_confirmation_dialog ? (
            <CreateDialogBox
              project_id={''}
              projects={checkedProjectWithNoClientInfo}
              client_name={client_name}
              client_address={client_address}
              show_add_client={show_add_client}
              handleAddClient={this.handleAddClient}
              handleHideAddClient={this.handleHideAddClient}
              handleHideAddClientWithX={this.handleHideAddClientWithX}
              handleShowAddClient={this.handleShowAddClient}
              disabled_create_button={disabled_create_button}
              client_project_pdf_name={client_project_pdf_name}
              handleAddClientChanges={this.handleAddClientChanges}
              allClientsInfo={allClientsInfo}
              show_clients_confirmation_dialog={show_clients_confirmation_dialog}
              handleShowClientConfirmation={this.handleShowClientConfirmation}
              handleHideClientConfirmation={this.handleHideClientConfirmation}
              saveClientsAndGenerateReport={this.saveClientsAndGenerateReport}
              handleClientChangesFinal={this.handleClientChangesFinal}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    receivers: state.ReceiverReducer.receivers,

    roles: state.LoginReducer.roles,
    name: state.LoginReducer.name,
    email: state.LoginReducer.email,

    rates: state.ResourceReducer.rates,
    invoices: state.InvoiceReducer.invoices,
    projects: state.ProjectReducer.projects,
    resources: state.ResourceReducer.resources,
    unbillable: state.ProjectReducer.unbillable,
    showInvoice: state.InvoiceReducer.showInvoice,
    invoice_error_message: state.InvoiceReducer.invoice_error_message,
    showInvoiceData: state.InvoiceReducer.showInvoiceData,
    loadingInvoices: state.InvoiceReducer.loadingInvoices,
    prepared_invoice: state.InvoiceReducer.prepared_invoice,

    range: state.HoursManagementReducer.range,
    no_active_period: state.HoursManagementReducer.no_active_period,

    status: state.InvoiceReducer.status,
    project: state.InvoiceReducer.project,
    project_manager: state.InvoiceReducer.project_manager,
    resource_details: state.InvoiceReducer.resource_details,
    clients: state.ClientReducer.clients,
    no_clients_data: state.ClientReducer.no_clients_data,
    previous_resource_details: state.InvoiceReducer.previous_resource_details,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAllReceivers: () => dispatch(ActionsCreator.getAllReceivers()),
  getRates: () => dispatch(ActionsCreator.getRates()),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getInvoices: () => dispatch(ActionsCreator.getInvoices()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  getResources: () => dispatch(ActionsCreator.getResources()),
  getHoursRange: () => dispatch(ActionsCreator.getHoursRange()),
  startLoader: (data) => dispatch(ActionsCreator.startLoader(data)),
  saveInvoice: (data) => dispatch(ActionsCreator.saveInvoice(data)),
  resetShowInvoice: () => dispatch(ActionsCreator.resetShowInvoice()),
  resetPrepareInvoice: () => dispatch(ActionsCreator.resetPrepareInvoice()),
  getUnbillableProjects: () => dispatch(ActionsCreator.getUnbillableProjects()),
  setInvoiceDetailData: (data) => dispatch(ActionsCreator.setInvoiceDetailData(data)),
  setShowInvoice: (data, data1) => dispatch(ActionsCreator.setShowInvoice(data, data1)),
  autoFillInvoice: (data) => dispatch(ActionsCreator.autoFillInvoice(data)),
  downloadMultipleInvoicesCSV: (ids) => dispatch(ActionsCreator.downloadMultipleInvoicesCSV(ids)),
  prepareInvoice: (data, data1, data2, data3) =>
    dispatch(ActionsCreator.prepareInvoice(data, data1, data2, data3)),
  getAllClients: () => dispatch(ActionsCreator.getAllClients()),
  saveClientMultiple: (data) => dispatch(ActionsCreator.saveClientMultiple(data)),
  setPreviousResourceDetails: (data) => dispatch(ActionsCreator.setPreviousResourceDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(Invoice)));

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withSnackbar } from 'notistack';
import { Bars } from 'react-loader-spinner';
import { DialogBoxes } from './DialogBoxes';
import { DialogCharts } from './DashboardCharts';
import { DashboardCards } from './DashboardCards';
import { InvoiceState } from '../../../constants/invoicestate';
import { ActionsCreator } from '../../../Redux/Actions/index';

import {
  getEmployeeData,
  getEmployeeRecords,
  generateInvoicesData,
  getPieChartHoursData,
  getResourceBillStatus,
  getProjectInvoiceDetails,
} from '../../../Selectors';
import { style } from './style';
import { EmptyContainer } from '../../Utility/EmptyContainer';
class StreamlineView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours_chart_data: [],
      projects_chart_data: [],
      missed_hours_defaulters: [],
      total_invoice: 0,
      loading: true,
      missing_data: false,
      show_billable_invoices: false,
      show_single_invoice: false,
      show_missing_hours_defaulters: false,
      show_missing_rates_defaulters: false,
      show_billable_projects: false,
      show_invoiced_projects: false,
      show_unbillable_projects: false,
      show_billable_resources: false,
      show_unbillable_resources: false,
      show_hours_distribution: false,
      show_resource_hours_distribution: false,
      billable_resources: [],
      unbillable_resources: [],
    };
  }

  static defaultProps = {
    loading: true,
  };

  componentDidMount() {
    this.loadDashboardData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected_month !== this.props.selected_month) this.loadDashboardData();
  }

  async loadDashboardData() {
    const {
      getRates,
      stopLoader,
      getProjects,
      getParsedData,
      setBillableProjectInvoices,
      selected_month,
    } = this.props;

    let projects = await getProjects();
    let unbillable = [];
    let billable = [];

    if (projects) {
      projects.forEach((project) => {
        if (!project.billable) {
          unbillable.push(project.name);
        } else {
          billable.push(project.name);
        }
      });
    }

    let rates = await getRates();
    let parsed_csv_data = await getParsedData(selected_month);
    if (!parsed_csv_data.start_date && !parsed_csv_data) parsed_csv_data = undefined;
    if (
      parsed_csv_data &&
      parsed_csv_data.records.length > 0 &&
      rates &&
      unbillable &&
      projects &&
      projects.length > 0
    ) {
      let invoicesData = await generateInvoicesData(billable, parsed_csv_data, rates, projects);
      await setBillableProjectInvoices(invoicesData);
      let data = await getPieChartHoursData(
        parsed_csv_data,
        rates,
        parseInt(parsed_csv_data.upload.total_hours),
        billable,
        projects,
      );
      this.setState(
        {
          total_invoice: data.total_invoice,
          rate_defaulters: data.rate_defaulters,
          hours_chart_data: data.hours_chart_data,
          hours_distribution: data.hours_distribution,
          projects_chart_data: data.projects_chart_data,
          missed_hours_defaulters: data.missed_hours_defaulters,
          billable_projects_count: data.billable_projects_count,
          billable_projects_state: billable ? billable.length : data.billable_projects_count,
          invoiced_projects_list: data.invoiced_projects_list,
          loading: false,
          missing_data: false,
        },
        () => {
          stopLoader();
        },
      );
    } else {
      this.setState(
        {
          loading: false,
          missing_data: true,
        },
        () => {
          stopLoader();
        },
      );
    }
  }

  handleShowProjectInvoice = (project) => {
    let { rates, parsed_csv_data, setInvoiceDetailData, projects } = this.props;
    let details = getProjectInvoiceDetails(rates, parsed_csv_data, project, projects);

    setInvoiceDetailData({
      project: details.show_project,
      project_manager: details.project_manager,
      status: InvoiceState.DRAFT,
      resource_details: details.resource_details,
      total_invoice: details.total_invoice,
      parsed_csv_data: parsed_csv_data,
      total_hours: parseInt(parsed_csv_data.upload.total_hours),
      editable: false,
    }).then((data) => {
      if (data) {
        this.setState({
          show_single_invoice: true,
        });
      }
    });
  };

  handleGenerateEmailReminder = (data) => {
    this.props.generateEmailForResources(data).then((data_rec) => {
      if (data_rec.sent) {
        this.props.enqueueSnackbar(data_rec.message, { variant: 'success' });
      } else {
        this.props.enqueueSnackbar(data_rec.message, { variant: 'error' });
      }
    });
  };

  handleHideSingleInvoice = () => {
    this.setState({
      show_single_invoice: false,
    });
  };

  handleShowBillableInvoices = () => {
    this.setState({
      show_billable_invoices: true,
    });
  };

  handleHideBillableInvoices = () => {
    this.setState({
      show_billable_invoices: false,
    });
  };

  handleShowMissingHoursDefaulters = () => {
    this.setState({
      show_missing_hours_defaulters: true,
    });
  };

  handleShowMissingRatesDefaulters = () => {
    this.setState({
      show_missing_rates_defaulters: true,
    });
  };

  handleHideMissingHoursDefaulters = () => {
    this.setState({
      show_missing_hours_defaulters: false,
    });
  };

  handleHideMissingRatesDefaulters = () => {
    this.setState({
      show_missing_rates_defaulters: false,
    });
  };

  handleShowBillableProjects = () => {
    this.setState({
      show_billable_projects: true,
    });
  };

  handleShowInvoicedProjects = () => {
    this.setState({
      show_invoiced_projects: true,
    });
  };

  handleHideBillableProjects = () => {
    this.setState({
      show_billable_projects: false,
    });
  };

  handleShowUnBillableProjects = () => {
    this.setState({
      show_unbillable_projects: true,
    });
  };

  handleHideUnBillableProjects = () => {
    this.setState({
      show_unbillable_projects: false,
    });
  };

  handleShowBillableResources = () => {
    this.setState({
      show_billable_resources: true,
    });
  };

  handleHideInvoicedProjects = () => {
    this.setState({
      show_invoiced_projects: false,
    });
  };

  handleHideBillableResources = () => {
    this.setState({
      show_billable_resources: false,
    });
  };

  handleShowUnBillableResources = () => {
    this.setState({
      show_unbillable_resources: true,
    });
  };

  handleHideUnBillableResources = () => {
    this.setState({
      show_unbillable_resources: false,
    });
  };

  handleShowHoursDistribution = () => {
    this.setState({
      show_hours_distribution: true,
    });
  };

  handleHideHoursDistribution = () => {
    this.setState({
      show_hours_distribution: false,
    });
  };

  handleShowResourceHoursDistribution = (resource) => {
    this.setState({
      show_resource: resource,
      show_resource_hours_distribution: true,
    });
  };

  handleHideResourceHoursDistribution = () => {
    this.setState({
      show_resource: '',
      show_resource_hours_distribution: false,
    });
  };

  render() {
    let {
      hours_chart_data,
      projects_chart_data,
      missed_hours_defaulters,
      total_invoice,
      loading,
      missing_data,
      rate_defaulters,
      show_billable_invoices,
      show_single_invoice,
      show_missing_hours_defaulters,
      show_missing_rates_defaulters,
      show_billable_projects,
      show_invoiced_projects,
      show_unbillable_projects,
      hours_distribution,
      show_hours_distribution,
      show_resource_hours_distribution,
      billable_projects_count,
      billable_projects_state,
      show_resource,
      show_billable_resources,
      show_unbillable_resources,
      billable_resources,
      unbillable_resources,
      invoiced_projects_list,
    } = this.state;
    let { parsed_csv_data, invoice_data, no_parsed_data, no_rates_data, projects } = this.props;
    let resource_data = [];
    let unbillable = [];
    let billable = [];
    let resource_records = [];
    if (show_resource_hours_distribution) {
      resource_data = getEmployeeData(
        show_resource,
        parsed_csv_data,
        parseInt(parsed_csv_data.upload.total_hours),
      );
      resource_records = getEmployeeRecords(show_resource, parsed_csv_data.records);
    }

    if (parsed_csv_data) {
      let resources_status = getResourceBillStatus(parsed_csv_data);
      billable_resources = resources_status.billable_resources;
      unbillable_resources = resources_status.unbillable_resources;
    }

    if (projects) {
      projects.forEach((project) => {
        if (!project.billable) {
          unbillable.push(project.name);
        } else {
          billable.push(project.name);
        }
      });
    }

    return (
      <div
        style={
          loading || no_parsed_data || no_rates_data || missing_data
            ? style.LoadingContainer
            : style.MainContainer
        }>
        {loading ? (
          <Bars color="#01b9fe" height={50} width={50} />
        ) : no_parsed_data || no_rates_data || missing_data ? (
          <EmptyContainer />
        ) : (
          <div style={style.FlexCol}>
            <DashboardCards
              total_invoice={total_invoice}
              billable_resources={billable_resources.length}
              unbillable_resources={unbillable_resources.length}
              unbillable_projects={unbillable.length}
              total_rate_defaulters={rate_defaulters.length}
              handleShowBillableInvoices={this.handleShowBillableInvoices}
              handleShowBillableProjects={this.handleShowBillableProjects}
              handleShowInvoicedProjects={this.handleShowInvoicedProjects}
              missed_hours={missed_hours_defaulters.reduce((a, b) => a + b[1], 0)}
              handleShowBillableResources={this.handleShowBillableResources}
              handleShowUnBillableProjects={this.handleShowUnBillableProjects}
              handleShowUnBillableResources={this.handleShowUnBillableResources}
              invoiced_projects={billable_projects_count}
              billable_projects={billable_projects_state}
              handleShowMissingHoursDefaulters={this.handleShowMissingHoursDefaulters}
              handleShowMissingRatesDefaulters={this.handleShowMissingRatesDefaulters}
            />
            <DialogCharts
              hours_chart_data={hours_chart_data}
              projects_chart_data={projects_chart_data}
              handleShowHoursDistribution={this.handleShowHoursDistribution}
              handleShowBillableInvoices={this.handleShowBillableInvoices}
            />
          </div>
        )}
        <DialogBoxes
          show_billable_invoices={show_billable_invoices}
          handleHideBillableInvoices={this.handleHideBillableInvoices}
          invoice_data={invoice_data}
          projects={projects}
          handleShowProjectInvoice={this.handleShowProjectInvoice}
          show_single_invoice={show_single_invoice}
          handleHideSingleInvoice={this.handleHideSingleInvoice}
          handleShowResourceHoursDistribution={this.handleShowResourceHoursDistribution}
          show_missing_hours_defaulters={show_missing_hours_defaulters}
          handleHideMissingHoursDefaulters={this.handleHideMissingHoursDefaulters}
          missed_hours_defaulters={missed_hours_defaulters}
          show_missing_rates_defaulters={show_missing_rates_defaulters}
          handleHideMissingRatesDefaulters={this.handleHideMissingRatesDefaulters}
          rate_defaulters={rate_defaulters}
          show_billable_projects={show_billable_projects}
          show_invoiced_projects={show_invoiced_projects}
          handleHideBillableProjects={this.handleHideBillableProjects}
          show_unbillable_projects={show_unbillable_projects}
          handleHideUnBillableProjects={this.handleHideUnBillableProjects}
          billable_resources={billable_resources}
          show_billable_resources={show_billable_resources}
          handleHideBillableResources={this.handleHideBillableResources}
          handleHideInvoicedProjects={this.handleHideInvoicedProjects}
          unbillable_resources={unbillable_resources}
          show_unbillable_resources={show_unbillable_resources}
          handleHideUnBillableResources={this.handleHideUnBillableResources}
          show_hours_distribution={show_hours_distribution}
          handleHideHoursDistribution={this.handleHideHoursDistribution}
          total_hours={parsed_csv_data ? parseInt(parsed_csv_data.upload.total_hours) : 0}
          hours_distribution={hours_distribution}
          handleGenerateEmailReminder={this.handleGenerateEmailReminder}
          hours_chart_data={hours_chart_data}
          show_resource_hours_distribution={show_resource_hours_distribution}
          handleHideResourceHoursDistribution={this.handleHideResourceHoursDistribution}
          show_resource={show_resource}
          resource_data={resource_data}
          resource_records={resource_records}
          invoiced_projects_list={invoiced_projects_list}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parsed_csv_data: state.HoursManagementReducer.parsed_csv_data,
    rates: state.ResourceReducer.rates,
    invoice_data: state.InvoiceReducer.invoice_data,
    no_parsed_data: state.HoursManagementReducer.no_parsed_data,
    no_rates_data: state.ResourceReducer.no_rates_data,
    projects: state.ProjectReducer.projects,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getRates: () => dispatch(ActionsCreator.getRates()),
  stopLoader: () => dispatch(ActionsCreator.stopLoader()),
  getProjects: () => dispatch(ActionsCreator.getProjects()),
  getParsedData: (data) => dispatch(ActionsCreator.getParsedData(data)),
  setInvoiceDetailData: (data) => dispatch(ActionsCreator.setInvoiceDetailData(data)),
  generateEmailForResources: (data) => dispatch(ActionsCreator.generateEmailForResources(data)),
  setBillableProjectInvoices: (data) => dispatch(ActionsCreator.setBillableProjectInvoices(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(StreamlineView));

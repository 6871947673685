import Moment from 'moment';

/**
 * Check if start(date) belongs to same week as end(date)
 * @param {String} start
 * @param {String} end
 */
const belongToSameWeek = (start, end) => {
  return (
    Moment(start).startOf('isoWeek').format('D MMM') ===
    Moment(end).startOf('isoWeek').format('D MMM')
  );
};

/**
 * Returns week name of date in format like (02 Feb - 09 Feb)
 */
const weeksName = (date) => {
  return (
    Moment(date).startOf('isoWeek').format('D MMM') +
    ' - ' +
    Moment(date).endOf('isoWeek').format('D MMM')
  );
};

/**
 * checks if value exists in the array
 */
export const containsValue = (val, list) => {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === val) {
      return true;
    }
  }
  return false;
};

const weeksNameStart = (date) => {
  return Moment(date).format('D MMM') + ' - ' + Moment(date).endOf('isoWeek').format('D MMM');
};

const weeksNameEnd = (date) => {
  return Moment(date).startOf('isoWeek').format('D MMM') + ' - ' + Moment(date).format('D MMM');
};

const convertDatesToRange = (date_array) => {
  date_array = date_array.sort((a, b) => Moment(a) - Moment(b));
  return (
    Moment(date_array[0]).format('D MMM') +
    ' - ' +
    Moment(date_array[date_array.length - 1]).format('D MMM')
  );
};

/**
 * Return integer with commas
 * @param {String} x any number string
 */
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const prepareInvoiceDataForAffiliate = (
  showInvoiceData,
  AssociatedReceivers,
  total_amount,
) => {
  let all_affiliates_data = [];
  for (var i in AssociatedReceivers) {
    let data = {
      base_receiver: showInvoiceData.receiver,
      total_base_amount: total_amount,
      my_ratio: AssociatedReceivers[i].percentage,
      ratio_type: AssociatedReceivers[i].amount_type,
      base_docID: showInvoiceData.docId,
    };
    all_affiliates_data.push(data);
  }
  return all_affiliates_data;
};

export const getAllClientsInfo = (
  checkedProjectWithNoClientInfo,
  client_name,
  client_address,
  client_project_pdf_name,
) => {
  let data = [];
  checkedProjectWithNoClientInfo.forEach((project, index) => {
    let temp_obj = {
      project_name: project.project_name,
      project_id: project.projectId.toString(),
      client_name: client_name[index],
      client_address: client_address[index],
      client_project_pdf_name: client_project_pdf_name[index],
    };
    data.push(temp_obj);
  });
  return data;
};

/**
 * Retruns invoice details of project name passed to function
 * @param {Array} rates Array of rates of resources on different projects
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 * @param {String} project Name of project whose invoice we are generating
 */
export const getProjectInvoiceDetails = (rates, parsed_csv_data, project, projects) => {
  let resource_details = [];
  let project_manager = projects.filter((item) => item.name === project);
  let records = parsed_csv_data.records.filter((record) => record.project === project);

  let resources = [...new Set(records.map((item) => item.resource))];

  resources.forEach((resource) => {
    let resource_records = records.filter((record) => record.resource === resource);
    let rate_index = rates.findIndex(
      (rate) => rate.resource === resource && rate.project === project,
    );

    resource_records = resource_records.sort((a, b) => Moment(a.day) - Moment(b.day));

    resource_details[resource] = {
      project: project,
      resource: resource,
      original_rate: rate_index >= 0 ? rates[rate_index].rate : 0,
      rate: rate_index >= 0 ? rates[rate_index].rate : 0,
      weeks: [],
      total_hours: 0,
    };

    let record_chunks = [];
    let temp = [];

    resource_records.forEach((record, index) => {
      if (index !== 0 && !belongToSameWeek(record.day, resource_records[index - 1].day)) {
        record_chunks.push(temp);
        temp = [];
      } else if (resource_records.length === 1 || index === resource_records.length - 1) {
        temp.push(record);
        record_chunks.push(temp);
        temp = [];
      }
      temp.push(record);
    });

    record_chunks.forEach((record, index) => {
      resource_details[resource].weeks.push({
        week_duration:
          resource_details[resource].weeks.length === 0
            ? weeksNameStart(record[0].day)
            : index !== record_chunks.length - 1
            ? weeksName(record[0].day)
            : weeksNameEnd(record[record.length - 1].day),
        original_total_week_hours: record.reduce((a, b) => a + b.time, 0),
        total_week_hours: record.reduce((a, b) => a + b.time, 0),
      });
    });
  });

  var resource_details_array = [];
  for (let key in resource_details) {
    resource_details[key].total_hours = resource_details[key].weeks.reduce(
      (a, b) => a + b.total_week_hours,
      0,
    );
    resource_details_array.push(resource_details[key]);
  }

  resource_details_array = resource_details_array.sort(function (a, b) {
    if (a.resource < b.resource) {
      return -1;
    }
    if (a.resource > b.resource) {
      return 1;
    }
    return 0;
  });

  return {
    show_project: project,
    resource_details: resource_details_array,
    project_manager:
      project_manager.length > 0 ? project_manager[0].project_manager : 'NOT ASSIGNED',
    total_invoice: resource_details_array
      .map((resource_detail) => resource_detail.rate * resource_detail.total_hours)
      .reduce((a, b) => a + b, 0),
  };
};

export const calculateReconciledPrice = (hours_data) => {
  let total_price_hours = {
    price: '',
    hours: '',
  };
  let total_price = 0;
  let total_hours = 0;
  hours_data.forEach((data) => {
    total_price += data.missed_hours * data.rate;
    total_hours += data.missed_hours;
  });
  total_price_hours.price = total_price;
  total_price_hours.hours = total_hours;
  return total_price_hours;
};
/*
Returns start and end date for last invoice (one before the latest)
Assumption: Invoice are created in chronological order
*/
export const calculatePreviousInvoiceDates = (invoices, project, end_date) => {
  let last_invoice_date = {
    start_date: '',
    end_date: '',
    project: '',
  };

  let project_prepaid_invoices = [];
  let past_prepaid_invoice = undefined;

  project_prepaid_invoices = invoices.filter(
    (invoice) => invoice.project_name === project && invoice.type === 'Prepaid',
  );
  project_prepaid_invoices = project_prepaid_invoices.sort(
    (a, b) => Moment(a.end_date) > Moment(b.end_date),
  );
  for (let i = 0; i < project_prepaid_invoices.length; i++) {
    if (Moment(project_prepaid_invoices[i].end_date) < Moment(end_date)) {
      past_prepaid_invoice = project_prepaid_invoices[i];
      last_invoice_date.start_date = past_prepaid_invoice.start_date;
      last_invoice_date.end_date = past_prepaid_invoice.end_date;
      last_invoice_date.project = past_prepaid_invoice.project_name;
      break;
    }
  }

  return last_invoice_date;
};

export const calculatePreviousInvoice = (invoices, project, end_date) => {
  let project_prepaid_invoices = [];
  let past_prepaid_invoice = undefined;

  project_prepaid_invoices = invoices.filter(
    (invoice) => invoice.project_name === project && invoice.type === 'Prepaid',
  );
  project_prepaid_invoices = project_prepaid_invoices.sort(
    (a, b) => Moment(a.end_date) > Moment(b.end_date),
  );
  for (let i = 0; i < project_prepaid_invoices.length; i++) {
    if (Moment(project_prepaid_invoices[i].end_date) < Moment(end_date)) {
      past_prepaid_invoice = project_prepaid_invoices[i];
      break;
    }
  }

  return past_prepaid_invoice;
};

export const updateEmployeeActualHours = (
  updated_hours,
  employee_actual_total_hours,
  updated_resource,
  parsed_csv_data,
) => {
  employee_actual_total_hours.forEach((resource) => {
    if (resource.employee === updated_resource) {
      resource.completed_hours = updated_hours;
      let remaining_hours = resource.previous_total_hours - resource.completed_hours;
      if (remaining_hours >= 0) resource.missed_hours = remaining_hours;
    }
  });
  return employee_actual_total_hours;
};

export const getEmployeeHoursPerProjectInvoice = (resources, hours_data, previous_invoice_data) => {
  let new_hours_data = [];
  let total_hours = 0;

  if (hours_data) {
    resources.forEach((resource) => {
      let rdata = {
        employee: resource.resource,
        completed_hours: 0,
        leave_hours: 0,
        overtime_hours: 0,
        missed_hours: 0,
        rate: resource.rate,
        is_in_csv: false,
        is_new_resource: false,
        previous_total_hours: 0,
      };

      hours_data.forEach((hour) => {
        if (hour.resource === resource.resource && hour.project === resource.project) {
          rdata.completed_hours += hour.time;
        } else if (hour.resource === resource.resource && hour.project === 'Leave') {
          rdata.leave_hours += hour.vacation_time;
        }
        rdata.is_in_csv = true;
        rdata.is_new_resource = false;
      });

      previous_invoice_data.forEach((prev_resource) => {
        if (rdata.employee === prev_resource.resource) {
          let remaining_hours = prev_resource.total_hours - rdata.completed_hours;
          rdata.previous_total_hours = total_hours;
          rdata.missed_hours = remaining_hours;
        }
      });
      new_hours_data.push(rdata);
    });
  }

  return new_hours_data;
};

export const getEmployeesHoursPerProject = (resources, parsed_csv_data, previous_invoice_data) => {
  let hours_data = [];
  let total_hours = 0;
  if (parsed_csv_data) {
    //total_hours = parseInt(parsed_csv_data.upload.total_hours);
    let temp_name = ''; // used to calculate total hours  -- assumption: total hours for all resources are same

    resources.forEach((resource) => {
      let data = {
        employee: resource.resource,
        completed_hours: 0,
        leave_hours: 0,
        overtime_hours: 0,
        missed_hours: 0,
        rate: resource.rate,
        is_in_csv: false,
        is_new_resource: true,
        previous_total_hours: 0,
      };
      let records = JSON.parse(JSON.stringify(parsed_csv_data.records));
      records.forEach((record) => {
        if (record.resource === resource.resource && record.project === resource.project) {
          if (temp_name === '') {
            temp_name = resource.resource;
          }
          if (temp_name === record.resource) {
            var dt = Moment(record.day, 'YYYY-MM-DD');
            if (
              dt.format('d') === '1' ||
              dt.format('d') === '2' ||
              dt.format('d') === '3' ||
              dt.format('d') === '4' ||
              dt.format('d') === '5'
            ) {
              total_hours += 8;
            }
          }
          data.completed_hours += record.time;
          data.is_in_csv = true;
          data.is_new_resource = false;
        }
      });

      if (data.is_in_csv === true) {
        previous_invoice_data.forEach((prev_resource) => {
          if (data.employee === prev_resource.resource) {
            data.leave_hours = parsed_csv_data.vacations[prev_resource.resource];
            //let remaining_hours = total_hours - (data.completed_hours + data.leave_hours); //
            //let remaining_hours = total_hours - (data.completed_hours); // old logic
            let remaining_hours = prev_resource.total_hours - data.completed_hours;
            data.previous_total_hours = total_hours;
            data.missed_hours = remaining_hours;
            /*remaining_hours > 0
            ? (data.missed_hours = remaining_hours)
            : (data.overtime_hours = Math.abs(remaining_hours));*/ //old logic
          }
        });

        /*data.leave_hours = parsed_csv_data.vacations[resource.resource];
      //let remaining_hours = total_hours - (data.completed_hours + data.leave_hours); // 
      let remaining_hours = total_hours - (data.completed_hours);
      data.previous_total_hours = total_hours;
      remaining_hours > 0
        ? (data.missed_hours = remaining_hours)
        : (data.overtime_hours = Math.abs(remaining_hours));*/
      }

      hours_data.push(data);
    });
    hours_data.forEach((data) => {
      // for special resources who are not in team deck and also newly added resources

      if (data.is_in_csv === false) {
        //console.log(data.employee);
        previous_invoice_data.forEach((resource) => {
          if (data.employee === resource.resource) {
            data.completed_hours = resource.total_hours;
            if (total_hours === 0) {
              total_hours = resource.total_hours;
            }
            data.is_new_resource = false;
            //console.log(data.completed_hours);
          }
        });

        if (data.is_new_resource === false) {
          let remaining_hours = total_hours - data.completed_hours;
          data.previous_total_hours = total_hours;
          data.missed_hours = remaining_hours;
          /*remaining_hours > 0
            ? (data.missed_hours = remaining_hours)
            : (data.overtime_hours = Math.abs(remaining_hours));*/ //old logic
        }
      }
    });
  }

  return hours_data;
};

/**
 * Returns completed hours, leave hours, missed hours data, overtime hours for every resource
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 */
export const getEmployeesData = (parsed_csv_data) => {
  let hours_data = [];
  let total_hours = parseInt(parsed_csv_data.upload.total_hours);

  parsed_csv_data.resources.forEach((resource) => {
    let data = {
      employee: resource,
      completed_hours: 0,
      leave_hours: 0,
      overtime_hours: 0,
      missed_hours: 0,
    };
    let records = JSON.parse(JSON.stringify(parsed_csv_data.records));
    records.forEach((record) => {
      if (record.resource === resource) {
        data.completed_hours += record.time;
      }
    });
    data.leave_hours = parsed_csv_data.vacations[resource];
    let remaining_hours = total_hours - (data.completed_hours + data.leave_hours);
    remaining_hours > 0
      ? (data.missed_hours = remaining_hours)
      : (data.overtime_hours = Math.abs(remaining_hours));

    hours_data.push(data);
  });

  return hours_data;
};

/**
 * Returns completed hours, leave hours, missed hours data for resource name passed to function
 * @param {String} name Name of the resource
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 * @param {Number} total_hours Total hours entered with CSV file
 */
export const getEmployeeData = (name, parsed_csv_data, total_hours) => {
  let employee = {
    completed_hours: 0,
    leave_hours: 0,
    missed_hours: 0,
  };
  let records = JSON.parse(JSON.stringify(parsed_csv_data.records));

  records.forEach((record) => {
    if (record.resource === name) {
      employee.completed_hours += record.time;
    }
  });

  employee.leave_hours = parsed_csv_data.vacations[name];
  employee.missed_hours = total_hours - (employee.completed_hours + employee.leave_hours);

  return employee;
};

/**
 * Retruns records of the resource name passed as employee from parsed_csv_data records
 * @param {*} employee Name of resource
 * @param {*} all_records All records object from parsed_csv_data
 */
export const getEmployeeRecords = (employee, all_records) => {
  let records = JSON.parse(JSON.stringify(all_records));

  let employeeRecords = [];
  records.forEach((record) => {
    if (record.resource === employee) {
      if (employeeRecords.length > 0) {
        let flag = true;
        employeeRecords.forEach((record_item) => {
          if (record.project === record_item.project) {
            flag = false;
            record_item.time = record_item.time + record.time;
          }
        });
        if (flag) {
          employeeRecords.push(record);
        }
      } else {
        employeeRecords.push(record);
      }
    }
  });

  return employeeRecords;
};

/**
 * Returns Total Invoice value from invoice data generated in getProjectInvoiceDetails function
 * @param {Array} invoice_data Invoice data of all resources in project
 */
export const getTotalFromInvoiceData = (invoice_data) => {
  let total = 0;

  invoice_data.forEach((data) => {
    total += data.rate * data.total_hours;
  });

  return parseFloat(total.toFixed(2).replace(/\.00$/, ''));
};

/**
 * Extended version of getTotalFromInvoiceData(), it takes discount, expenses and
 * reconciliation into consideration too
 * @param {Array} invoice - complete invoice details
 */
export const getTotalFromInvoiceDataUpdated = (invoice) => {
  let total = 0;

  if (invoice.is_affiliate) {
    let total_base = invoice.invoice_data_for_affliliate
      ? invoice.invoice_data_for_affliliate.total_base_amount
      : 0;
    let my_ratio = invoice.invoice_data_for_affliliate
      ? invoice.invoice_data_for_affliliate.my_ratio
      : 0;
    let ratio_type = invoice.invoice_data_for_affliliate
      ? invoice.invoice_data_for_affliliate.ratio_type
      : 'percentage';

    if (ratio_type === 'percentage') {
      total = parseInt(total_base) * (parseInt(my_ratio) / 100);
    } else {
      total = parseInt(my_ratio);
    }
  } else {
    let invoice_data = JSON.parse(invoice.project_invoice_data);
    let final_discount = 0;
    let total_expense = invoice.expenses
      ? invoice.expenses.reduce((acc, expense) => {
          return acc + expense.expense_amount;
        }, 0)
      : 0;

    invoice_data.forEach((data) => {
      total += data.rate * data.total_hours;
    });

    if (invoice.discount_type === 'Percentage') {
      final_discount = total * (invoice.discount / 100);
    } else {
      final_discount = invoice.discount;
    }
    total = total - invoice.reconciliated_price + total_expense - final_discount;
  }

  return parseFloat(total.toFixed(2).replace(/\.00$/, ''));
};

/**
 * Returns Total Invoice Hours from invoice data generated in getProjectInvoiceDetails function
 * @param {Array} invoice_data Invoice data of all resources in project
 */
export const getTotalHoursFromInvoiceData = (invoice_data) => {
  let total = 0;

  invoice_data.forEach((data) => {
    total += data.total_hours;
  });

  return parseFloat(total.toFixed(2).replace(/\.00$/, ''));
};

/**
 * Returns Total Month weeks for Billing Data
 * @param {Object} billing_data Invoice data of all resources in project
 */
export const getMonthsFromBillingData = (billing_data) => {
  let months = [];

  for (let resource_name of Object.keys(billing_data)) {
    months = months.concat(Object.keys(billing_data[resource_name]));
  }

  months = [...new Set(months)];

  months = months.sort(function (a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  return months;
};

/**
 * Returns float point value fixed to 2 decimal values if present else strip them
 */
export const formatFloat = (number) => {
  return numberWithCommas(
    parseInt(number) ? parseFloat(number.toFixed(2).replace(/\.00$/, '')) : 0,
  );
};

/**
 * Returns data for Charts, Cards and Invoices details in Super Admin dashboard
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 * @param {Array} rates Array of rates of resources on different projects
 * @param {Number} total_hours Total hours entered with CSV file
 * @param {Array} billable List of names of billable projects
 * @param {Array} projects List of projects
 */
export const getPieChartHoursData = (parsed_csv_data, rates, total_hours, billable, projects) => {
  return new Promise((resolve) => {
    let billable_hours = 0;
    let unbillable_hours = 0;
    let missing_hours = 0;
    let leave_hours = 0;
    let overtime_hours = 0;
    let total_invoice = 0;
    let rate_defaulters = [];
    let projects_chart_data = [];
    let missed_hours_defaulters = [];
    let hours_distribution = [];
    let billable_projects_count = 0;
    let invoiced_projects_list = [];

    parsed_csv_data.records.forEach((record) => {
      if (record.billable) {
        billable_hours += record.time;
      } else {
        unbillable_hours += record.time;
      }
    });

    parsed_csv_data.resources.forEach((resource) => {
      let employee_data = getEmployeeData(resource, parsed_csv_data, total_hours);
      leave_hours += employee_data.leave_hours;
      if (employee_data.missed_hours > 0) {
        missing_hours += employee_data.missed_hours;
        missed_hours_defaulters.push([resource, employee_data.missed_hours]);
      } else {
        overtime_hours += Math.abs(employee_data.missed_hours);
      }
      hours_distribution.push({
        resource: resource,
        missed_hours: employee_data.missed_hours,
        leave_hours: employee_data.leave_hours,
        completed_hours: employee_data.completed_hours,
      });
    });

    billable.forEach((project) => {
      let flag = false;
      let project_data = getProjectInvoiceDetails(rates, parsed_csv_data, project, projects);
      total_invoice += project_data.total_invoice;

      project_data.resource_details.forEach((resource_detail) => {
        if (parseInt(resource_detail.rate) === 0) {
          rate_defaulters.push({
            resource: resource_detail.resource,
            project: project,
          });
        }
      });

      project_data.resource_details.forEach((resource_detail) => {
        if (parseInt(resource_detail.total_hours) > 0) {
          flag = true;
        }
      });

      if (flag) {
        billable_projects_count++;
        invoiced_projects_list.push(project);
      }

      if (parseInt(project_data.total_invoice) > 0) {
        projects_chart_data.push([project, project_data.total_invoice]);
      }
    });

    projects_chart_data = projects_chart_data.sort((a, b) => b[1] - a[1]);

    let data = {
      hours_chart_data: [
        ['Billable Hours', parseInt(billable_hours)],
        ['Unbillable Hours', parseInt(unbillable_hours)],
        ['Missing Hours', parseInt(missing_hours)],
        ['Leave Hours', parseInt(leave_hours)],
        ['Overtime Hours', parseInt(overtime_hours)],
      ],
      total_invoice: parseInt(total_invoice),
      rate_defaulters: rate_defaulters,
      projects_chart_data: projects_chart_data,
      missed_hours_defaulters: missed_hours_defaulters,
      hours_distribution: hours_distribution,
      billable_projects_count: billable_projects_count,
      invoiced_projects_list: invoiced_projects_list,
    };
    resolve(data);
  });
};

/**
 * Check if the project can be finalized or some resource in invoice have missing hours
 * @param {Array} invoice_details Invoice data of all resources in project
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 * @param {Number} total_hours Total hours entered with CSV file
 */
export const checkProjectFinilized = (invoice_details, parsed_csv_data, total_hours) => {
  let flag = true;
  invoice_details.resource_details.forEach((resource_detail) => {
    let employee_data = getEmployeeData(resource_detail.resource, parsed_csv_data, total_hours);
    if (parseInt(resource_detail.rate) === 0 || employee_data.missed_hours > 0) {
      flag = false;
    }
  });

  return flag;
};

/**
 * Returns invoices for every project present in parsed_csv_data
 * @param {Object} billable List of billable projects
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 * @param {Array} rates Array of rates of resources on different projects
 * @param {Array} rates Array of projects
 */
export const generateInvoicesData = (billable, parsed_csv_data, rates, projects) => {
  return new Promise((resolve) => {
    let allProjectInvoices = [];

    billable.forEach((project) => {
      let project_rates = rates.filter((rate) => rate.project === project);
      let isBillable = parsed_csv_data.records.findIndex((record) => record.project === project);
      let invoice_details = getProjectInvoiceDetails(rates, parsed_csv_data, project, projects);
      let total_amount = invoice_details.total_invoice;
      let can_be_finilized = checkProjectFinilized(
        invoice_details,
        parsed_csv_data,
        parseInt(parsed_csv_data.upload.total_hours),
      );

      allProjectInvoices.push({
        project: project,
        isBillable: isBillable,
        can_be_finilized: can_be_finilized,
        total_amount: project_rates.length > 0 ? total_amount.toFixed(2).replace(/\.00$/, '') : 0,
      });
    });
    allProjectInvoices = allProjectInvoices.sort((a, b) => b.total_amount - a.total_amount);

    resolve(allProjectInvoices);
  });
};

/**
 * Returns invoice data similar to getProjectInvoiceDetails function using prepared_invoice
 * @param {Object} prepared_invoice Data sent from server for preparing invoice
 * @param {String} project Name of project
 * @param {Array} rates Array of rates of resources on different projects
 * @param {Array} projects Array of projects
 * @param {String} start_date Start date of invoice.
 * @param {String} end_date End date of invoice.
 */
export const preparedDataToInvoiceData = (
  prepared_invoice,
  project,
  rates,
  projects,
  start_date,
  end_date,
  invoice_type,
  resource_names,
  resource_detail,
  selected_resource_name,
  changeResourceDetails,
) => {
  let resource_details = [];
  let project_manager = projects.filter((item) => item.name === project);
  if (prepared_invoice === undefined || prepared_invoice === null) {
    prepared_invoice = [];
  }

  if (resource_names === undefined) resource_names = [];

  if (invoice_type === undefined) invoice_type = 'Postpaid';

  if (prepared_invoice.length === 0 && invoice_type === 'Prepaid' && resource_names.length === 0) {
    rates.forEach((resource) => {
      if (resource.project === project) resource_names.push(resource.resource);
    });
  }

  let resources = [...new Set(prepared_invoice.map((item) => item.resource))];
  if (resource_names.length !== 0 && invoice_type === 'Prepaid')
    resources = resources.concat(resource_names);

  if (resources.length === 0 && invoice_type === 'Prepaid') resources = ['dummy_resource'];

  let week_days = [];
  let weeks_chunk = [];
  let temp_week = [];
  let curr_date = Moment(start_date).format('YYYY-MM-DD');

  while (Moment(end_date).isSameOrAfter(Moment(curr_date))) {
    week_days.push(curr_date);
    curr_date = Moment(curr_date).add(1, 'days').format('YYYY-MM-DD');
  }

  week_days.forEach((day, index) => {
    if (
      index !== 0 &&
      !belongToSameWeek(day, week_days[index - 1]) &&
      index !== week_days.length - 1
    ) {
      weeks_chunk.push(temp_week);
      temp_week = [];
    } else if (
      (week_days.length === 1 || index === week_days.length - 1) &&
      belongToSameWeek(day, week_days[index - 1])
    ) {
      temp_week.push(day);
      weeks_chunk.push(temp_week);
      temp_week = [];
    } else if (index === week_days.length - 1 && !belongToSameWeek(day, week_days[index - 1])) {
      weeks_chunk.push(temp_week);
      temp_week = [];
      temp_week.push(day);
      weeks_chunk.push(temp_week);
      temp_week = [];
    }
    temp_week.push(day);
  });

  resources.forEach((resource) => {
    let resource_records = prepared_invoice.filter((record) => record.resource === resource);
    let rate_index = rates.findIndex(
      (rate) => rate.resource === resource && rate.project === project,
    );
    resource_records = resource_records.sort((a, b) => Moment(a.day) - Moment(b.day));

    if (changeResourceDetails) {
      const temp_resource = resource_detail.find((obj) => {
        return obj.resource == resource;
      });
      if (temp_resource) {
        resource_details[resource] = {
          project: project,
          resource: resource,
          original_rate: temp_resource.original_rate,
          rate: temp_resource.rate,
          weeks: temp_resource.weeks,
          total_hours: temp_resource.total_hours,
        };
      } else {
        resource_details[resource] = {
          project: project,
          resource: resource,
          original_rate: rate_index >= 0 ? rates[rate_index].rate : 0,
          rate: rate_index >= 0 ? rates[rate_index].rate : 0,
          weeks: [],
          total_hours: 0,
        };
      }
    } else {
      resource_details[resource] = {
        project: project,
        resource: resource,
        original_rate: rate_index >= 0 ? rates[rate_index].rate : 0,
        rate: rate_index >= 0 ? rates[rate_index].rate : 0,
        weeks: [],
        total_hours: 0,
      };
    }

    let record_chunks = [];
    let temp = [];

    for (let week of weeks_chunk) {
      for (let day of week) {
        let index = resource_records.findIndex((record) =>
          Moment(record.day, 'YYYY-MM-DD').isSame(Moment(day, 'YYYY-MM-DD')),
        );

        if (index !== -1) {
          temp.push(resource_records[index]);
        }
      }
      record_chunks.push(temp);
      temp = [];
    }

    if (changeResourceDetails) {
      record_chunks.forEach((record, index) => {
        if (resource_details[selected_resource_name]) {
          resource_details[resource].weeks.push({
            week_duration: convertDatesToRange(weeks_chunk[index]),
            original_total_week_hours: record.reduce((a, b) => a + b.time, 0),
            total_week_hours: record.reduce((a, b) => a + b.time, 0),
          });
        }
      });
    } else {
      record_chunks.forEach((record, index) => {
        resource_details[resource].weeks.push({
          week_duration: convertDatesToRange(weeks_chunk[index]),
          original_total_week_hours: record.reduce((a, b) => a + b.time, 0),
          total_week_hours: record.reduce((a, b) => a + b.time, 0),
        });
      });
    }
  });

  let resource_details_array = [];
  for (let key in resource_details) {
    /*resource_details[key].total_hours = resource_details[key].weeks.reduce(
      (a, b) => a + b.total_week_hours,
      0,
    );
    resource_details_array.push(resource_details[key]);*/
    if (resource_details[key].weeks.length > 0) {
      for (let i in resource_details[key].weeks) {
        resource_details[key].total_hours += resource_details[key].weeks[i].total_week_hours;
      }
      resource_details_array.push(resource_details[key]);
    }
  }

  resource_details_array = resource_details_array.sort(function (a, b) {
    if (a.resource < b.resource) {
      return -1;
    }
    if (a.resource > b.resource) {
      return 1;
    }
    return 0;
  });

  let project_managers = project_manager[0].project_managers;
  if (project_managers && project_managers.length > 0) {
    project_manager = project_managers[0].name;
  } else {
    project_manager = 'NOT ASSIGNED';
  }

  return {
    show_project: project,
    resource_details: resource_details_array,
    project_manager: project_manager,
    total_invoice: resource_details_array
      .map((resource_detail) => resource_detail.rate * resource_detail.total_hours)
      .reduce((a, b) => a + b, 0),
  };
};
// Add explanation here

export const calculateAutoFillHourData = (start_date, end_date, resource_details) => {
  let week_days = [];
  let weeks_chunk = [];
  let temp_week = [];
  let curr_date = Moment(start_date).format('YYYY-MM-DD');

  while (Moment(end_date).isSameOrAfter(Moment(curr_date))) {
    week_days.push(curr_date);
    curr_date = Moment(curr_date).add(1, 'days').format('YYYY-MM-DD');
  }

  week_days.forEach((day, index) => {
    if (
      index !== 0 &&
      !belongToSameWeek(day, week_days[index - 1]) &&
      index !== week_days.length - 1
    ) {
      weeks_chunk.push(temp_week);
      temp_week = [];
    } else if (
      (week_days.length === 1 || index === week_days.length - 1) &&
      belongToSameWeek(day, week_days[index - 1])
    ) {
      temp_week.push(day);
      weeks_chunk.push(temp_week);
      temp_week = [];
    } else if (index === week_days.length - 1 && !belongToSameWeek(day, week_days[index - 1])) {
      weeks_chunk.push(temp_week);
      temp_week = [];
      temp_week.push(day);
      weeks_chunk.push(temp_week);
      temp_week = [];
    }
    temp_week.push(day);
  });
  var new_week = [];
  var auto_hours = [];
  for (let week of weeks_chunk) {
    let this_week_hours = 0;
    for (let day of week) {
      var dt = Moment(day, 'YYYY-MM-DD');
      if (
        dt.format('d') === '1' ||
        dt.format('d') === '2' ||
        dt.format('d') === '3' ||
        dt.format('d') === '4' ||
        dt.format('d') === '5'
      ) {
        // excluding saturday -> 6 and sunday -> 0, in hours calculation
        this_week_hours += 8;
      }
    }
    auto_hours.push(this_week_hours);
  }
  for (let resource of resource_details) {
    var total_hours = 0;
    var i = 0;
    var modified_week_array = [];
    for (let obj of resource.weeks) {
      modified_week_array.push({
        week_duration: obj.week_duration,
        original_total_week_hours: obj.original_total_week_hours,
        total_week_hours: auto_hours[i],
      });
      total_hours += auto_hours[i];
      i++;
    }
    new_week.push(modified_week_array);
    resource.weeks = modified_week_array;
    resource.total_hours = total_hours;
  }
  return resource_details;
};

/**
 * Returns list of project names on which resource is Project Manager
 * @param {*} resource Email of resource
 * @param {*} projects List of all projects
 * @param {*} rates Array of rates of resources on different projects
 */
export const getProjectOfResource = (resource, projects) => {
  let projects_list = [];

  projects.forEach((project) => {
    if (project.project_managers) {
      for (let key in project.project_managers) {
        if (project.project_managers[key].email === resource) {
          projects_list.push(project.name);
        }
      }
    }
  });

  return projects_list;
};

/**
 * Returns start date of record in prepare_invoice_data
 * @param {Array} prepare_invoice_data All the records sent from server to prepare invoice
 */
export const startDateFromPrepareInvoice = (prepare_invoice_data) => {
  prepare_invoice_data.sort((a, b) => Moment(a.day) - Moment(b.day));

  return prepare_invoice_data.length > 0 ? prepare_invoice_data[0].day : '0001-01-01';
};

/**
 * Returns end date of record in prepare_invoice_data
 * @param {Array} prepare_invoice_data All the records sent from server to prepare invoice
 */
export const endDateFromPrepareInvoice = (prepare_invoice_data) => {
  prepare_invoice_data.sort((a, b) => Moment(b.day) - Moment(a.day));

  return prepare_invoice_data.length > 0 ? prepare_invoice_data[0].day : '0001-01-01';
};

/**
 * Retruns data to generate Hours Distribution chart
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 * @param {Number} total_hours Total hours entered with CSV file
 */
export const getHoursPieChartData = (parsed_csv_data, total_hours) => {
  return new Promise((resolve) => {
    let billable_hours = 0;
    let unbillable_hours = 0;
    let missing_hours = 0;
    let leave_hours = 0;
    let overtime_hours = 0;

    parsed_csv_data.records.forEach((record) => {
      if (record.billable) {
        billable_hours += record.time;
      } else {
        unbillable_hours += record.time;
      }
    });

    parsed_csv_data.resources.forEach((resource) => {
      let employee_data = getEmployeeData(resource, parsed_csv_data, total_hours);
      leave_hours += employee_data.leave_hours;
      if (employee_data.missed_hours > 0) {
        missing_hours += employee_data.missed_hours;
      } else {
        overtime_hours += Math.abs(employee_data.missed_hours);
      }
    });

    let data = {
      hours_chart_data: [
        ['Billable Hours', parseInt(billable_hours)],
        ['Unbillable Hours', parseInt(unbillable_hours)],
        ['Missing Hours', parseInt(missing_hours)],
        ['Leave Hours', parseInt(leave_hours)],
        ['Overtime Hours', parseInt(overtime_hours)],
      ],
    };
    resolve(data);
  });
};

/**
 * Returns the Area Chart data for invoices of a project
 * @param {Array} invoices List of all invoices of a project
 */
export const generateDataforPMInvoiceHistoryChart = (invoices) => {
  let chart_data = {};
  invoices.forEach((invoice) => {
    chart_data[invoice.end_date] = getTotalFromInvoiceData(
      JSON.parse(invoice.project_invoice_data),
    );
  });

  return chart_data;
};

/**
 * Returns the Column Chart data for invoices and hourly data invoices of a project
 * @param {Array} invoices List of all invoices of a project
 * @param {Array} records List of all records of a project
 * @param {Array} rates List of all rates of resources on projects
 */
export const generateDataforInvoiceHistoryChart = (invoices, records, rates, projects) => {
  let invoices_chart_data = {};
  let hourly_chart_data = {};

  invoices.forEach((invoice) => {
    invoices_chart_data[invoice.end_date] = getTotalFromInvoiceData(
      JSON.parse(invoice.project_invoice_data),
    );
    let temp_records = records.filter(
      (record) =>
        Moment(record.day, 'YYYY-MM-DD') >= Moment(invoice.start_date, 'YYYY-MM-DD') &&
        Moment(record.day, 'YYYY-MM-DD') <= Moment(invoice.end_date, 'YYYY-MM-DD'),
    );
    let temp_invoice = preparedDataToInvoiceData(
      temp_records,
      invoice.project_name,
      rates,
      projects,
      Moment(invoice.start_date, 'YYYY-MM-DD'),
      Moment(invoice.end_date, 'YYYY-MM-DD'),
    );
    let temp_invoice_details = temp_invoice.resource_details;
    hourly_chart_data[invoice.end_date] = getTotalFromInvoiceData(temp_invoice_details);
  });

  return [
    {
      name: 'Hourly Data',
      data: hourly_chart_data,
      dataset: {
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 5,
        pointBorderWidth: 1,
        pointBorderColor: '#797979',
      },
    },
    {
      name: 'Invoices',
      data: invoices_chart_data,
      dataset: {
        borderWidth: 1,
      },
    },
  ];
};

/**
 * Return Resource Hours Distribution chart data for resources contribution on a project
 * @param {String} project Name of project
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 */
export const getResourceHoursDistribution = (project, parsed_csv_data) => {
  let chart_data = [];
  let records = parsed_csv_data.records.filter((record) => record.project === project);
  let resources = [...new Set(records.map((item) => item.resource))];

  resources.forEach((resource) => {
    let resource_records = records.filter((record) => record.resource === resource);
    let resource_total_time = resource_records.reduce((a, b) => a + b.time, 0);
    chart_data.push([resource, parseInt(resource_total_time)]);
  });

  return chart_data;
};

/**
 * Returns invoices data for Project Managers projects to display as Card on Dashboard
 * @param {Array} projects List of projects
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 * @param {Array} rates Array of rates of resources on different projects
 */
export const generateProjectManagerInvoicesData = (projects, parsed_csv_data, rates) => {
  let allProjectInvoices = [];

  projects.forEach((project) => {
    let project_rates = rates.filter((rate) => rate.project === project);
    let invoice_details = getProjectInvoiceDetails(rates, parsed_csv_data, project, projects);
    let total_amount = invoice_details.total_invoice;
    let can_be_finilized = checkProjectFinilized(
      invoice_details,
      parsed_csv_data,
      parseInt(parsed_csv_data.upload.total_hours),
    );

    allProjectInvoices.push({
      project: project,
      can_be_finilized: can_be_finilized,
      total_amount: project_rates.length > 0 ? total_amount.toFixed(2).replace(/\.00$/, '') : 0,
    });
  });
  allProjectInvoices = allProjectInvoices.sort((a, b) => b.total_amount - a.total_amount);

  return allProjectInvoices;
};

/**
 * Returns (formated format of) start and end date from parsed_csv_data
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 */
export const getActiveParsedDataDates = (parsed_csv_data) => {
  let start_date = Moment(parsed_csv_data.start_date).format('D MMM YYYY');
  let end_date = Moment(parsed_csv_data.end_date).format('D MMM YYYY');

  return {
    start_date: start_date,
    end_date: end_date,
  };
};

/**
 * Returns whether email format is valid or not
 * @param {String} email email entered by user
 */
export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(String(email).toLowerCase());
};

/**
 * Returns the rates of resource on different projects
 * @param {String} resource Name of resource
 * @param {Array} rates Array of rates of resources on different projects
 */
export const getResourceRates = (resource, rates) => {
  return rates.filter((rate) => rate.resource_id === resource.id);
};

/**
 * Returns the rates of resource on different projects
 * @param {String} project object of project
 * @param {Array} rates Array of rates of resources on different projects
 */
export const getResourceRatesForProjects = (project, rates) => {
  return rates.filter((rate) => rate.project_name === project.name);
};

/**
 * Returns whether the resource was billable in CSV data uploaded
 * @param {Object} parsed_csv_data {end_date, start_Date, resources,
 *                 records, vacations, projects, upload} data
 *                 after parsing CSV
 */
export const getResourceBillStatus = (parsed_csv_data) => {
  let resources = parsed_csv_data.resources;
  let billable_resources = [];
  let unbillable_resources = [];

  if (resources) {
    resources.forEach((resource) => {
      let index = parsed_csv_data.records.findIndex(
        (record) => record.resource === resource && record.billable === true,
      );

      if (index === -1) {
        unbillable_resources.push(resource);
      } else {
        billable_resources.push(resource);
      }
    });
  }

  return {
    billable_resources: billable_resources,
    unbillable_resources: unbillable_resources,
  };
};

/**
 * Returns alphabetical sorted array
 * @param {String} type ascending or descending
 * @param {Array} value value to be sorted
 * @param {Object} objectName Object of value on which we will sort
 */
export const stringSort = (type, value, objectName) => {
  //value = [{project_name: "asia"},{project_name: "zoo"},{project_name: "india"}];
  if (type === 'ascending') {
    value.sort(function (a, b) {
      return a[objectName].localeCompare(b[objectName], undefined, { sensitivity: 'base' });
    });
  } else {
    value.sort(function (a, b) {
      return b[objectName].localeCompare(a[objectName], undefined, { sensitivity: 'base' });
    });
  }
  return value;
};

/**
 * Returns luminance based on hex value.
 * @param {String} color hex value
 */
export const getLuminance = (color) => {
  let hexValue = color.substring(1); // strip #
  let rgb = parseInt(hexValue, 16); // convert rrggbb to decimal
  let r = (rgb >> 16) & 0xff;
  let g = (rgb >> 8) & 0xff;
  let b = (rgb >> 0) & 0xff;

  let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

  return luminance;
};

/**
 * Returns numerical sorted array after calculating total of invoice
 * @param {String} type ascending or descending
 * @param {Array} value value to be sorted
 * @param {Object} objectName Object of value on which we will sort
 */
export const integerSort = (type, value, objectName) => {
  if (type === 'ascending') {
    value.sort(
      (a, b) =>
        getTotalFromInvoiceData(JSON.parse(a[objectName])) -
        getTotalFromInvoiceData(JSON.parse(b[objectName])),
    );
  } else {
    value.sort(
      (a, b) =>
        getTotalFromInvoiceData(JSON.parse(b[objectName])) -
        getTotalFromInvoiceData(JSON.parse(a[objectName])),
    );
  }

  return value;
};

/**
 * Returns numerical sorted array after calculating total of invoice
 * @param {String} type ascending or descending
 * @param {Array} value value to be sorted
 * @param {Object} objectName Object of value on which we will sort
 */
export const integerHoursSort = (type, value, objectName) => {
  if (type === 'ascending') {
    value.sort((a, b) => a[objectName] - b[objectName]);
  } else {
    value.sort((a, b) => b[objectName] - a[objectName]);
  }

  return value;
};

/**
 * Returns date based sort of invoices fields
 * @param {String} type ascending or descending
 * @param {Array} value value to be sorted
 * @param {Object} objectName Object of value on which we will sort
 */
export const dateSort = (type, value, objectName) => {
  if (type === 'ascending') {
    value.sort((a, b) => Moment(a[objectName]) - Moment(b[objectName]));
  } else {
    value.sort((a, b) => Moment(b[objectName]) - Moment(a[objectName]));
  }
  return value;
};

/**
 * Returns the sum of total amount of each invoice
 * @param {Array} invoices list of invoices
 */
export const calculateTotalOfInvoices = (invoices) => {
  let total =
    invoices &&
    invoices
      .filter((invoice) => invoice.is_affiliate === false)
      .reduce((a, b) => a + getTotalFromInvoiceData(JSON.parse(b.project_invoice_data)), 0);
  return formatFloat(total);
};

/**
 * Return sum of all unique project invoices pie chart data
 * @param {Array} invoices List of invoices in a specific date range
 */
export const generateInvoicesPieChartDataForReporting = (invoices) => {
  let invoices_chart_data = {};
  let invoices_chart_data_array = [];

  invoices.forEach((invoice) => {
    let invoice_total = getTotalFromInvoiceData(JSON.parse(invoice.project_invoice_data));

    if (invoices_chart_data.hasOwnProperty(invoice.project_name)) {
      invoices_chart_data[invoice.project_name] += invoice_total;
    } else {
      invoices_chart_data[invoice.project_name] = invoice_total;
    }
  });

  for (let key in invoices_chart_data) {
    invoices_chart_data_array.push([key, invoices_chart_data[key]]);
  }
  invoices_chart_data_array.sort((a, b) => parseInt(b[1]) - parseInt(a[1]));

  return invoices_chart_data_array;
};

/**
 * Return timeline chart data for invoice value & period
 * @param {Array} invoices List of invoices of a single project
 */
export const generateDataforInvoiceTimeline = (invoices) => {
  let timeline_data = [];

  invoices.forEach((invoice) => {
    let invoice_total = getTotalFromInvoiceData(JSON.parse(invoice.project_invoice_data));

    timeline_data.push(['$' + invoice_total, invoice.start_date, invoice.end_date]);
  });

  return timeline_data;
};
